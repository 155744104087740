import React from 'react';
import Error from '../Errors';

import {
    Routes,
    Route,
    useParams, Navigate
} from 'react-router-dom';
import { IncidentProvider } from '../../context/incidentProvider';
import IncidentForm from './Form';
import CompanyBreadcrumbs from '../Company/Breadcrumbs';


function IncidentPage() {
    let { incidentID } = useParams();


    return (
        <>
            <IncidentProvider id={incidentID as string}>
                <Routes>
                    <Route errorElement={<Error fullscreen={false} />}  >
                        <Route index element={<Navigate to={'edit'} replace />} />
                        <Route path='edit' element={<IncidentForm MenuElement={<CompanyBreadcrumbs />} />} />

                        <Route path={"*"} element={<Error status={404} title={'Page not found?'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />

                    </Route>
                </Routes>
            </IncidentProvider >
        </>
    );
}

export default IncidentPage;