import { useState, createContext, useContext } from 'react'
import { PropsPeople } from '../Pages/People/types';
import { PropsCategory } from '../Pages/Categories/types';
import { PropsLocation } from '../Pages/Locations/types';
import { PropsCompliance, PropsComplianceOption } from '../Pages/Compliance/types';
import { PropsIncident } from '../Pages/Incidents/types';

interface PropsGlobal {
    menuOpen?: boolean
    person?: PropsPeople
    location?: PropsLocation
    compliance?: PropsCompliance
    complianceOption?: PropsComplianceOption
    incident?: PropsIncident
    category?: PropsCategory
    lastDocumentFolder?: string

}

interface globalContext {
    global: PropsGlobal;
    setGlobal: React.Dispatch<React.SetStateAction<PropsGlobal>>;
}

const GlobalContext = createContext<globalContext>({} as globalContext);

export const GlobalProvider = ({ children }: { children: any }) => {
    const [global, setGlobal] = useState<PropsGlobal>(
        {
            menuOpen: true
        }
    );

    return (
        <GlobalContext.Provider value={{ global, setGlobal }}>
            {children}
        </GlobalContext.Provider >
    );
}

export const useGlobal = () => useContext(GlobalContext);