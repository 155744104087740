import { addDays, addMonths, endOfDay, format, fromUnixTime, getUnixTime, startOfDay, startOfMonth, subDays, subMonths } from "date-fns";

export const currentYear = (): number => new Date().getFullYear();


export const daysofweek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const formatDate = ({ time, formatStr = 'do MMMM yyyy', empty = 'n/a' }: { time: number, formatStr?: string, empty?: string }) => {
    if (!time || time === 0) { return empty; }
    return format(fromUnixTime(time), formatStr) //https://date-fns.org/v1.28.5/docs/format
}

export const _now = (): number => getUnixTime(new Date);
export const _DaysAgo = (sub: number = 1): number => { return getUnixTime(startOfDay(subDays(new Date, sub))) }
export const _DaysAway = (add: number = 1): number => { return getUnixTime(endOfDay(addDays(new Date, add))) }
export const _MonthsAgo = (sub: number = 1): number => { return getUnixTime(startOfMonth(subMonths(new Date, sub))) }
export const _MonthsAway = (add: number = 1): number => { return getUnixTime(startOfMonth(addMonths(new Date, add))) }

export const _DaysAgoD = (sub: number = 1): Date => { return startOfDay(subDays(new Date, sub)) }
export const _DaysAwayD = (add: number = 1): Date => { return endOfDay(addDays(new Date, add)) }
export const _MonthsAgoD = (sub: number = 1): Date => { return startOfMonth(subMonths(new Date, sub)) }
export const _MonthsAwayD = (add: number = 1): Date => { return startOfMonth(addMonths(new Date, add)) }

export const _nowF = (formatStr = 'yyyy-MM-dd') => { return format(new Date, formatStr) }
export const _DaysAgoF = (sub: number = 1, formatStr = 'yyyy-MM-dd') => { return format(endOfDay(addDays(new Date, sub)), formatStr) }
export const _DaysAwayF = (add: number = 1, formatStr = 'yyyy-MM-dd') => { return format(endOfDay(addDays(new Date, add)), formatStr) }
export const _MonthsAgoF = (sub: number = 1, formatStr: string = 'yyyy-MM-dd') => { return format(startOfMonth(subMonths(new Date, sub)), formatStr) }
export const _MonthsAwayF = (add: number = 1, formatStr: string = 'yyyy-MM-dd') => { return format(startOfMonth(addMonths(new Date, add)), formatStr) }