import axios from "axios";
import { myToast, urls } from "../../common";
import { faBiohazard, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";

export const handleUpdateCompanyCourses = (ids: string[], refetch?: Function) => {
    axios.patch(`${urls.remoteURL}company/courses`, ids)
        .then(() => {
            if (typeof refetch === 'function') { refetch(); }
            myToast({ title: 'Company Courses Updated', colour: 'green', icon: faThumbsUp, timing: 3 })
        })
        .catch((error) => {
            myToast({ title: 'Error', 'message': error.response.data.message || 'Error updating courses', colour: 'red', icon: faBiohazard })
        });

}


