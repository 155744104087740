import { createContext, useContext, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { CSSTransition, SwitchTransition } from "react-transition-group";

interface PropsTabs {
  activeTab: number,
  setActiveTab: React.Dispatch<React.SetStateAction<number>>,
}
const TabsContext = createContext<PropsTabs>({} as PropsTabs);

const Tabs = (props: { activeIndex: number, onActiveIndexChange?: React.Dispatch<React.SetStateAction<number>>, className?: string, children?: any }) => {
  const { activeIndex, onActiveIndexChange, className, children } = props;

  const [activeTab, setActiveTab] = useState<number>(activeIndex);

  useEffect(() => {
    setActiveTab(activeIndex as number);
  }, [activeIndex]);

  useEffect(() => {
    if (onActiveIndexChange) {
      onActiveIndexChange(activeTab);
    }
  }, [activeTab]);

  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      <div className={classNames("tabs", className)}>{children}</div>
    </TabsContext.Provider>
  );
};


const TabsNavigation = (props: any) => {
  const { children } = props;

  return <nav className="tab-nav">{children}</nav>;
};

const TabsNavigationItem = (props: { index: number, className?: string, children?: any }) => {
  const { index, className, children } = props;

  const { activeTab, setActiveTab } = useContext(TabsContext);

  const isActive = () => {
    return activeTab === index;
  };

  return (
    <button
      className={classNames("nav-link", "h5", className, {
        active: isActive(),
      })}
      onClick={() => setActiveTab(index as number)}
    >
      {children}
    </button>
  );
};


const TabsContent = (props: { className?: string, children?: any }) => {
  const { className, children } = props;

  const { activeTab } = useContext(TabsContext);

  const tabContentRef = useRef<HTMLDivElement>(null);

  const onEnter = () => {
    if (tabContentRef.current) {
      tabContentRef.current.style.height = '0';
      tabContentRef.current.style.opacity = '0';
    }
  };

  const onEntering = () => {
    if (tabContentRef.current) {
      tabContentRef.current.style.height = tabContentRef.current.scrollHeight + "px";
      tabContentRef.current.style.opacity = '100';
    }
  };


  const onEntered = () => {
    if (tabContentRef.current) {
      tabContentRef.current.style.removeProperty("height");
      tabContentRef.current.style.removeProperty("opacity");
    }
  };

  const onExit = () => {
    if (tabContentRef.current) {
      tabContentRef.current.style.height = tabContentRef.current.scrollHeight + "px";
      tabContentRef.current.style.opacity = '100';
    }
  };

  const onExiting = () => {
    if (tabContentRef.current) {
      tabContentRef.current.style.height = '0';
      tabContentRef.current.style.opacity = '0';
    }
  };

  const onExited = () => {
    if (tabContentRef.current) {
      tabContentRef.current.style.removeProperty("height");
      tabContentRef.current.style.removeProperty("opacity");
    }
  };

  const renderItems = () => {
    return children.map((item: { props: { index: number } }) => {
      return item.props.index === activeTab ? item : null;
    });
  };

  return (
    <SwitchTransition>
      <CSSTransition
        key={activeTab}
        nodeRef={tabContentRef}
        timeout={200}
        onEnter={onEnter}
        onEntering={onEntering}
        onEntered={onEntered}
        onExit={onExit}
        onExiting={onExiting}
        onExited={onExited}
      >
        <div
          ref={tabContentRef}
          className={classNames("collapsible", "open", "mt-5", className)}
        >
          {renderItems()}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};


const TabsContentItem = (props: { index: number, className?: string, children?: any }) => {
  const { className, children } = props;

  return <div className={className}>{children}</div>;
};

export default Tabs;

export { TabsNavigation, TabsNavigationItem, TabsContent, TabsContentItem };
