import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  DoughnutController,
  ChartType,
} from "chart.js";

import ConfigChartJS from "./config";


// Chart register
ChartJS.register(ArcElement, Tooltip, Legend);

// Line with shadow element
require("./LineWithShadowElement");

// Doughnut with shadow
class DoughnutWithShadow extends DoughnutController { }

DoughnutWithShadow.id = "doughnutWithShadow";
DoughnutWithShadow.defaults = {
  datasetElementType: "lineWithShadowElement",
};

ChartJS.register(DoughnutWithShadow);

const Doughnut = (props: { data: any; withShadow?: boolean; gauge?: boolean, hideLegend?: boolean }) => {
  const { data, withShadow, gauge, hideLegend } = props;

  const doughnutOptions = gauge ? ConfigChartJS().gauge as any : ConfigChartJS().doughnut as any;
  if (hideLegend){ doughnutOptions.plugins.legend = null; }

  return (
    <Chart
      type={withShadow ? "doughnutWithShadow" as ChartType : "doughnut"}
      options={doughnutOptions}
      data={data}
    />
  );
};

export default Doughnut;
