import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  ChartType,
} from "chart.js";


import ConfigChartJS from "./config";


// Chart register
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Line with shadow element
require("./LineWithShadowElement");

// Line with shadow
class LineWithShadow extends LineController { }

LineWithShadow.id = "lineWithShadow";
LineWithShadow.defaults = {
  datasetElementType: "lineWithShadowElement",
};

ChartJS.register(LineWithShadow);

const Line = (props: { data: any, withShadow?: boolean, displayLegend?: boolean }) => {
  const { data, withShadow, displayLegend = true } = props;

  const lineOptions = ConfigChartJS(data).line;
  lineOptions.plugins.legend.display = displayLegend;

  return (
    <Chart
      type={withShadow ? "lineWithShadow" as ChartType : "line"}
      options={lineOptions as any}
      data={data}
    />
  );
};

export default Line;
