import React, { ButtonHTMLAttributes, useEffect } from "react";
import classNames from "classnames";
import { CSVLink } from "react-csv";
import { PropsColor } from "../common/colours";
import Tooltip from "./Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import Button from "./Button";

interface PropsButton {
  downloadData: any[] | undefined,
  headers?: string[],
  downloadFilename?: string,
  color?: PropsColor,
  icon?: boolean,
  className?: string,
  children?: any,
}

const DownloadDataButton = (props: PropsButton) => {
  const { color, icon, className, downloadData, headers, downloadFilename } = props;

  if (!downloadData || downloadData.length === 0) {
    return (
      <Button disabled className={classNames('flex gap-2 btn', className, `btn-${color}`)}
      >
        <span className={classNames(
          'hidden md:inline',
          { 'sr-only': icon }
        )}>Download</span> <FontAwesomeIcon icon={faDownload} fixedWidth />
      </Button>
    )
  }

  return (
    <Tooltip content="Download">
      <div className="flex">
        <CSVLink
          className={classNames('flex gap-2 btn', className, `btn-${color}`)}
          filename={`${downloadFilename || `export`}.csv`}
          headers={headers}
          data={downloadData}
        >
          <span className={classNames(
            'hidden md:inline',
            { 'sr-only': icon }
          )}>Download</span> <FontAwesomeIcon icon={faDownload} fixedWidth />
        </CSVLink>
      </div>
    </Tooltip >
  )
}

export default DownloadDataButton;
