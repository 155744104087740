import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { urls } from "../../common";
import { createColumnHelper } from "@tanstack/react-table";
import LoadingDots from "../../components/LoadingDots";
import Table from "../../components/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { formatDate } from "../../common/dates";
import { TruncateString } from "../../common/text";

const DocumentsSelectionList = (
    { type, selectedRows, setSelectedRows, disabled }:
        { type: string, selectedRows: string[], setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>, disabled?: string[] }) => {
    type TableProps = {
        id: string,
        company_id: string,
        filename: string,
        name: string,
        mimetype: string,
        size: number,
        created: number,
        updated: number,
    }

    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState<string>('');
    const [data, setData] = useState<TableProps[]>();

    const fetchURL = type === 'company' ? `${urls.remoteURL}get-documents` :
        `${urls.remoteURL}get-documents`;
    const params = { active: true };

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params: { ...params } }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    const tableRows = useMemo(() => {
        return isLoading === false && data ? data : [];
    }, [data, selectedRows]);

    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => <>
                {info.getValue()}
                {/* <span className="block md:hidden text-sm font-normal">Users: {info.row.original.users}</span> */}
            </>,
            header: 'Document',
            footer: 'Document',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('filename', {
            cell: info => <TruncateString text={info.getValue()} maxLength={30} />,
            header: 'Filename',
            footer: 'Filename',
            sortingFn: 'alphanumeric',
        }),

        // columnHelper.accessor('mimetype', {
        //     header: 'Type',
        //     footer: 'Type',
        //     sortingFn: 'alphanumeric',
        // }),


        columnHelper.accessor('updated', {
            cell: info => formatDate({ time: info.getValue() > 0 ? info.getValue() : info.row.original.created }),
            header: 'Updated',
            footer: 'Updated',
            sortingFn: 'alphanumeric',
        }),
    ];


    return (
        <div className="overflow-x-auto">
            <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">

                {/* Search */}
                <div className="flex ml-auto" >
                    <label className="form-control-addon-within rounded-full items-center">
                        <input
                            className="form-control border-none expanding"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                        />
                        <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                    </label>
                </div>
            </div>
            {isLoading ? <LoadingDots /> :
                <Table
                    defaultData={tableRows}
                    columns={columns}
                    options={{
                        search, selectable: true,
                        hideSelection: true,
                        selectedRows, selectableFunction: setSelectedRows, selectDisabled: disabled,
                        compactFooter: true,
                    }}
                />
            }
        </div>
    );
}

export default DocumentsSelectionList;