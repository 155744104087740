import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Table from "../../../../components/Table";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, _now, formatDate } from "../../../../common/dates";
import DownloadDataButton from "../../../../components/DownloadDataButton";

const ComplianceOptionLogsList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { compliance } = useCompliance();

    const fetchURL = `${urls.remoteURL}get-item/options/${compliance?.id}/logs`;

    const { isLoading, isError, data } = useQuery(`company-compliance-${compliance?.id}-logs`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance logs?' };
    }



    type TableProps = {
        id: string,
        value: {
            description: string,
        },
        created: number,
        updated: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.description', {
            cell: info => info.getValue(),
            header: 'Description',
            footer: 'Description',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('created', {
            cell: info => formatDate({ time: info.getValue(), formatStr: 'HH:mm do MMMM yyyy' }),
            header: () => 'Created',
            footer: () => 'Created',
            meta: {
                className: 'w-40'
            }
        }),
    ];


    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            message: row.value.description,
            created: row.created > 0 ? formatDate({ time: row.created, formatStr: 'd/M/yyyy' }) : 'n/a',
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        <DownloadDataButton downloadFilename={`${compliance?.name}-logs`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionLogsList;