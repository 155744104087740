import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useUser } from "../context/userProvider";
import { useCompany } from "../context/companyProvider";
import { isEmptyObject } from ".";


const RequireAuthPage = ({ children, permissions, redirect }: { children?: JSX.Element, permissions?: string, redirect?: string }) => {
    const { user } = useUser();
    const { company } = useCompany();
    const location = useLocation();
    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    if (isEmptyObject(company)) {
        return <>...</>
    }

    if (permissions && !company[permissions]) {
        return <Navigate to={redirect || "/dashboard"} replace />
    }

    return children || <Outlet />;
}

export default RequireAuthPage;