import React, { useEffect, useState } from "react";
import axios from "axios";
import { myToast, urls, USERTYPES } from "../../common";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup';
import { faBiohazard, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import PersonAddForm from "./AddForm";
import PeopleBulkAddForm from "./BulkAdd";


export const handleAddPerson = (refetch: Function) => {
    const MySwal = withReactContent(Swal)
    const schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email(),
    });

    MySwal.fire({
        title: "Add Person",
        html: <PersonAddForm inputID="addPersonFormJSON" />,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        width: 800,
        customClass: {
            // popup: 'w-96',
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addPersonFormJSON") as HTMLInputElement).value);
            console.log(data);

            return schema
                .isValid({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                })
                .then((valid) => {
                    if (valid) {
                        return axios.post(`${urls.remoteURL}users`, data).then((x) => {
                            return x.data
                        }).catch((err) => {
                            MySwal.showValidationMessage(
                                err.response.data.message ?? `Problem Saving User`
                            )
                            return false;
                        });
                    } else {
                        MySwal.showValidationMessage(`Fill in all required fields!`)
                        return false;
                    }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            refetch();
        }
    })
}


export const handleBulkAddPeople = (refetch?: Function) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
        title: <p>Add People</p>,
        html: <PeopleBulkAddForm inputID="addUserFormJSON" mySwal={MySwal} />,
        showCancelButton: true,
        confirmButtonText: 'Continue',

        showLoaderOnConfirm: true,
        customClass: {
            popup: '!w-full md:!w-full lg:!w-1/3 !pb-0 ',
            htmlContainer: 'z-20 !mx-0 !mb-0',
            confirmButton: '!bg-iamBlue-500 focus:!shadow-none'

        },
        didOpen: () => {
            const button = MySwal.getConfirmButton();
            if (button) { button.disabled = true; }
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addUserFormJSON") as HTMLInputElement).value);
            if (!data.people) {
                MySwal.showValidationMessage(
                    `No users uploaded?`
                )
                return false;
            }
            const people = data.people.filter((x: any) => !x.errorMessage); //  && !x.duplicateMessage);

            return axios.patch(`${urls.remoteURL}users/bulk-upload`, { people }).then(({ data }) => {
                return data
            }).catch((err) => {
                MySwal.showValidationMessage(
                    err.response.data.message ?? `Problem Saving Users`
                )
                return false;
            });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            // toast.success('Users Saved');
            Swal.fire(
                'People Added!',
                `This process could take a few minutes if there a lot of people in your list.`,
                'success'
            );
            if (refetch) { refetch(); }
        }
    })

}



export const handleDeletePerson = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}users/${id}`)
                .then(() => {
                    myToast({ title: 'Deleted!', message: 'The Person has been deleted.', colour: 'green', icon: faThumbsUp, timing: 3 })
                    if (typeof refetch === 'function') { refetch(); }
                })
                .catch((error) => {
                    myToast({ title: 'Error!', message: error.response.data.message || ' Error deleting Person?', colour: 'red', icon: faBiohazard, timing: 3 })
                });

        }
    })
}



export const handleBulkDeletePeople = (ids: string[], refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete all selected people, this is perminant!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, delete ${ids.length} People!`
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}users`, { data: ids })
                .then(() => {
                    myToast({ title: 'Deleted!', message: 'The selected people have been deleted.', colour: 'green', icon: faThumbsUp, timing: 3 })
                    if (typeof refetch === 'function') { refetch(); }
                })
                .catch((error) => {
                    myToast({ title: 'Error!', message: error.response.data.message || ' Error deleting people?', colour: 'red', icon: faBiohazard, timing: 3 })
                });

        }
    })
}


