import React, { useEffect, useState } from "react";
import { PropsCompliance } from "../../types";
import DocumentsSelectionList from "../../../Documents/SelectionList";

const ComplianceOptionBulletinAddForm = ({ compliance, inputID }: { compliance: PropsCompliance, inputID: string }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [json, setJSON] = useState({});

    useEffect(() => {
        setJSON({
            documents: selectedRows,
        })
    }, [selectedRows])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <DocumentsSelectionList
                type="company"
                disabled={compliance?.item_options?.filter(x => x.element === 'BULLETIN').map(document => document.value.document_id) as string[]}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
            />
            <input type="hidden" id={inputID} value={JSON.stringify(json)} />
        </form>
    )
}

export default ComplianceOptionBulletinAddForm;