import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link, useParams } from 'react-router-dom';
import { faArrowLeft, faBuilding, faGauge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCompany } from '../../context/companyProvider';
import { urls } from '../../common';
import LoadingDots from '../../components/LoadingDots';
import Error from '../Errors';
import DashboardItemsListPage from './List';
import Tooltip from '../../components/Tooltip';

type categoryProps = {
    name: string,
    setup: number,
    passed: number,
    upcoming: number,
    expired: number,
    sites: any,
}
function DashboardCategoryDetails({ MenuElement }: { MenuElement?: JSX.Element }) {
    const { company } = useCompany();
    const { categoryID } = useParams();

    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState<categoryProps>()

    const fetchURL = `${urls.remoteURL}reporting/category/${categoryID}`;


    useEffect(() => {
        axios.get(fetchURL)
            .then(({ data }) => {
                setCategory(data);
            })
            .catch(error => {
                throw { status: error.response.data.code || 500, message: error.response.data.message || 'Error getting category report?' }
            })
            .finally(() => { setLoading(false) })
    }, [categoryID])

    if (loading) {
        return (<LoadingDots />);
    }

    if (!category) {
        return <Error status={404} title={'Not Found?'} />
    }


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className="flex gap-2 my-3 sm:mt-0 sm:ml-4">
                    {category?.sites.length > 0 && <Link to='locations' className='btn btn-rhinoBlue gap-1 px-6 rounded-full'><FontAwesomeIcon icon={faBuilding} fixedWidth /> Locations</Link>}
                    <Link to='..' className='btn btn-info gap-1  px-6 rounded-full'><FontAwesomeIcon icon={faGauge} fixedWidth /> Dashboard</Link>
                </div>
            </div>


            <dl className="my-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                <div key={'Passed'} className="flex flex-col bg-green-600/5 p-8">
                    <dt className="text-sm font-semibold leading-6 text-green-600">
                        <Tooltip content="Compliance Passed">
                            <Link to={`/compliance`} className='btn btn-green' state={{ status: 'passed', category: categoryID !== 'company' ? categoryID : '' }}>Compliance Passed</Link>
                        </Tooltip>
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-green-900">{category.passed}</dd>
                </div>
                <div key={'expired'} className="flex flex-col bg-red-400/5 p-8">
                    <dt className="text-sm font-semibold leading-6 text-red-600">
                        <Tooltip content="View Expired Compliance">
                            <Link to={`/compliance`} className='btn btn-red' state={{ status: 'expired', category: categoryID !== 'company' ? categoryID : '' }}>Compliance Expired</Link>
                        </Tooltip>
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-red-900">{category.expired}</dd>
                </div>
                <div key={'upcoming'} className="flex flex-col bg-blue-400/5 p-8">
                    <dt className="text-sm font-semibold leading-6 text-blue-600">
                        <Tooltip content="View Upcoming Compliance">
                            <Link to={`/compliance`} className='btn btn-blue' state={{ status: 'upcoming', category: categoryID !== 'company' ? categoryID : '' }}>Upcoming Compliance</Link>
                        </Tooltip>
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-blue-900">{category.upcoming}</dd>
                </div>
                <div key={'setup'} className="flex flex-col bg-gray-400/5 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-600">
                        <Tooltip content="View Compliance Awaiting Setup">
                            <Link to={`/compliance`} className='btn btn-gray' state={{ status: 'setup', category: categoryID !== 'company' ? categoryID : '' }}>Compliance Awaiting Setup</Link>
                        </Tooltip>
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{category.setup}</dd>
                </div>
            </dl>

            <div className="card p-5">
                <DashboardItemsListPage categoryID={categoryID} />
            </div>
        </>
    )
}

export default DashboardCategoryDetails;