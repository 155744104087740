const ConfigChartJS = ({ datasets }: { datasets?: any } = {}) => {

    let min = 0;
    let max = 5;
    if (!!datasets) {
        for (const set of datasets) {
            const maxValue = Math.max(...set.data);
            if (max < maxValue) { max = maxValue + 1; }
        }
    }

    const stepSize = max > 10 ? 2 : 1;

    // Tooltips Options
    const tooltipOptions = {
        backgroundColor: "white",
        borderColor: "#5870a0",
        borderWidth: 0.5,
        bodyColor: "#5870a0",
        bodySpacing: 8,
        cornerRadius: 4,
        padding: 16,
        boxPadding: 2,
        titleColor: "#5870a0",
    };

    const area = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: tooltipOptions,
        },
        scales: {
            y: {
                border: {
                    display: false,
                },
                grid: {
                    display: true,
                },
                min: 0,
                max: 20,
                ticks: {
                    stepSize,
                },
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {

                },
            },
        },
    };

    const bar = {
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20,
                },
            },
            tooltip: tooltipOptions,
        },
        scales: {
            y: {
                border: {
                    display: false,
                },
                grid: {
                    display: true,
                },
                min: 0,
                max: 20,
                ticks: {
                    stepSize,

                },
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 85,
                    minRotation: 85
                },
            },
        },
    };

    const line = {
        plugins: {
            legend: {
                display: true,
                position: "bottom",
            },
            tooltip: tooltipOptions,
        },
        scales: {
            y: {
                border: {
                    display: false,
                },
                grid: {
                    display: true,
                },
                min,
                max,
                ticks: {
                    stepSize,

                },
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {

                },
            },
        },
    };

    const pie = {
        aspectRatio: 2,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20,

                },
            },
            tooltip: tooltipOptions,
        },
    };

    const doughnut = {
        aspectRatio: 2,
        cutout: "75%",
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20,

                },
            },
            tooltip: tooltipOptions,
        },
    };

    const gauge = {
        circumference: 180,
        rotation: -90,
        aspectRatio: 2,
        cutout: "75%",
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20,

                },
            },
            tooltip: tooltipOptions,
        },
    };

    const radar = {
        aspectRatio: 2,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20,
                },
            },
            tooltip: tooltipOptions,
        },
        scales: {
            r: {
                max: 30,
                pointLabels: {
                },
                ticks: {
                    display: false,
                },
            },
        },
    };

    const polarArea = {
        aspectRatio: 2,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20,
                },
            },
            tooltip: tooltipOptions,
        },
        scales: {
            r: {
                ticks: {
                    display: false,
                },
            },
        },
        layout: {
            padding: 5,
        },
    };

    return {
        area,
        bar,
        line,
        pie,
        gauge,
        doughnut,
        radar,
        polarArea,
    };
};

export default ConfigChartJS;
