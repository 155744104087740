import React, { useEffect, useState } from "react";
import CourseSelectionList from "../../../Courses/SelectionList";
import { PropsCompliance } from "../../types";

type formProps = {
    courseIDs?: string[],
    required?: boolean,
    deadline?: number,
}

const ComplianceOptionCourseAddForm = ({ compliance, inputID }: { compliance: PropsCompliance, inputID: string }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [required, setRequired] = useState<boolean>(false);
    const [json, setJSON] = useState<formProps>({});



    useEffect(() => {
        setJSON({
            courseIDs: selectedRows,
            required
        })
    }, [selectedRows, required])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <CourseSelectionList
                disabled={compliance?.item_options?.filter(x => x.element === 'COURSE').map(course => course.value.course_id) as string[]}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                type={'compliance'}
            />
            <input type="hidden" id={inputID} value={JSON.stringify(json)} />
        </form>
    );
}

export default ComplianceOptionCourseAddForm;