import React from "react";
import axios from "axios";

import { myToast, urls } from "../../../../common";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import * as yup from 'yup';
import ComplianceOptionNoteAddForm from "./AddForm";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";


export const handleAddComplianceNote = (complianceID: string, refetch: Function) => {
    const MySwal = withReactContent(Swal)
    const schema = yup.object().shape({
        description: yup.string().required(),
    });

    MySwal.fire({
        title: "Add Notes",
        html: <ComplianceOptionNoteAddForm inputID="addNoteFormJSON" />,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        // width: 800,
        customClass: {
            // popup: 'w-96',
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addNoteFormJSON") as HTMLInputElement).value);

            return schema
                .isValid(data)
                .then((valid) => {
                    if (valid) {
                        return axios.post(`${urls.remoteURL}item/option/${complianceID}/note`, data).then((x) => {
                            return true;
                        }).catch((err) => {
                            MySwal.showValidationMessage(
                                err.response.data.message ?? `Problem Saving Note`
                            )
                            return false;
                        });
                    } else {
                        MySwal.showValidationMessage(`Fill in all required fields!`)
                        return false;
                    }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            refetch();
        }
    })
}

