import React, { useEffect, useState } from "react";
import axios from "axios";
import { USERTYPES, myToast, urls } from "../../common";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import * as yup from 'yup';
import { faBiohazard, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import Tabs, { TabsContent, TabsContentItem, TabsNavigation, TabsNavigationItem } from "../../components/Tabs";
import { HeadlessUIRadioGroup } from "../../components/form/RadioGroupButton";
import LocationAddForm from "./AddForm";
import LocationSelectionList from "./SelectionList";
import { PropsPeople } from "../People/types";
import { PropsLocation } from "./types";
import PersonAddForm from "../People/AddForm";
import PeopleSelectionList from "../People/SelectionList";
import { PropsCompany } from "../Company/types";


export const handleAddLocation = (siteID?: string, refetch?: Function) => {
    Swal.fire({
        title: 'Add Location',
        input: 'text',
        inputAttributes: {
            autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
            if (value === '') {
                Swal.showValidationMessage(`Name Required`);
                return;
            }

            return axios.post(`${urls.remoteURL}sites`, { name: value, siteID }).then(() => {
                return true;
            }).catch(error => {
                Swal.showValidationMessage(`${error}`)
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            if (typeof refetch === 'function') { refetch(); }
        }
    })
}


export const handleDeleteLocation = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}sites/${id}`)
                .then(() => {
                    myToast({ title: 'Deleted!', message: 'The Location has been deleted.', colour: 'green', icon: faThumbsUp, timing: 3 })
                    if (typeof refetch === 'function') { refetch(); }
                })
                .catch((error) => {
                    myToast({ title: 'Error!', message: error.response.data.message || ' Error deleting Location?', colour: 'red', icon: faBiohazard, timing: 3 })
                });

        }
    })
}






export const handlePersonAddLocation = (company: PropsCompany, person: PropsPeople | null, refetch?: Function) => {
    const MySwal = withReactContent(Swal);
    let currentTab: string = !!company.edit_sites ? 'new' : 'current';
    let selected: string[] = [];
    let selectedAdmin: boolean = false;

    const schema = yup.object().shape({
        name: yup.string().required(),
    });

    const PopupForm = () => {
        const [tab, setTab] = useState(!!company.edit_sites ? 0 : 1);
        const [selectedRows, setSelectedRows] = useState<string[]>([]);
        const [admin, setAdmin] = useState<string>(USERTYPES[0].id);

        useEffect(() => {
            selected = selectedRows || [];
            selectedAdmin = ['admin'].includes(admin);
        }, [selectedRows, admin])

        useEffect(() => {
            currentTab = tabs[tab].tab;
        }, [tab])

        const tabs = [
            { name: 'Add New', tab: 'new', },
            { name: 'Add Current', tab: 'current', },
        ];

        return (
            <div className="text-left">
                <div className="">
                    {!!company.edit_sites ?
                        <Tabs activeIndex={0} onActiveIndexChange={(i) => setTab(i)} className="mt-5">
                            <TabsNavigation>
                                {tabs.map((tab, index) =>
                                    <TabsNavigationItem key={`tab_${tab.tab}`} index={index} className="">
                                        {tab.name}
                                    </TabsNavigationItem>
                                )}
                            </TabsNavigation>
                            <TabsContent>
                                <TabsContentItem index={0}>
                                    <LocationAddForm inputID="addLocationFormJSON" />
                                </TabsContentItem>
                                <TabsContentItem index={1}>
                                    <LocationSelectionList
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        type="user"
                                        id={person?.id} />
                                </TabsContentItem>

                            </TabsContent>
                        </Tabs>
                        :

                        <LocationSelectionList
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            type="user"
                            id={person?.id} />
                    }
                    <div>
                        <HeadlessUIRadioGroup value={admin} onChange={setAdmin} options={USERTYPES.filter(x => {
                            const a = ['staff'];
                            if (person?.admin) { a.push('admin') }
                            return a.includes(x.id);
                        })} />
                    </div>

                </div>
            </div >
        )
    }

    MySwal.fire({
        title: <p>Add Locations</p>,
        html: <PopupForm />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        customClass: {
            htmlContainer: 'z-20',
            popup: '!w-full md:!w-4/5 lg:!w-3/5 xl:!w-1/2'
        },
        preConfirm: () => {
            if (currentTab === 'new') {
                const data = JSON.parse((document.getElementById("addLocationFormJSON") as HTMLInputElement).value);

                return schema
                    .isValid({
                        name: data.name,
                    })
                    .then((valid) => {
                        if (valid) {
                            return axios.post(`${urls.remoteURL}sites`, { ...data, userID: person?.id, admin: selectedAdmin }).then((x) => {
                                return x.data
                            }).catch((err) => {
                                MySwal.showValidationMessage(
                                    err.response.data.message ?? `Problem Saving site`
                                )
                                return false;
                            });
                        } else {
                            MySwal.showValidationMessage(`Fill in all required fields!`)
                            return false;
                        }
                    });

            } else {
                if (selected.length > 0) {
                    return axios.post(`${urls.remoteURL}user/${person?.id}/sites/connect`, { siteIDs: selected, admin: selectedAdmin }).then((x) => {
                        return x.data
                    }).catch((err) => {
                        MySwal.showValidationMessage(
                            err.response.data.message ?? `Problem Saving sites`
                        )
                        return false;
                    });
                } else {
                    MySwal.showValidationMessage(`Select at least one site`)
                }
            }

            MySwal.showValidationMessage(
                `Problem Saving Site`
            )

        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', message: 'Person added to location.', colour: 'green', icon: faThumbsUp, timing: 3 })
            if (typeof refetch === 'function') { refetch(); }
        }
    })

}




export const handleLocationAddPerson = (location: PropsLocation, userType: 'manager' | 'people' | 'all', refetch?: Function) => {
    const MySwal = withReactContent(Swal);
    let currentTab: string = 'new';
    let selected: string[] = [];

    let schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email(),
    });

    const PopupForm = () => {
        const [tab, setTab] = useState(0);
        const [selectedRows, setSelectedRows] = useState<string[]>([]);

        useEffect(() => {
            selected = selectedRows;
        }, [selectedRows])

        useEffect(() => {
            currentTab = tabs[tab].tab;
        }, [tab])

        const tabs = [
            { name: 'Add New', tab: 'new' },
            { name: 'Add Current', tab: 'current' },
        ];

        return (
            <div className="text-left">
                <div>
                    <Tabs activeIndex={0} onActiveIndexChange={(i) => setTab(i)} className="mt-5">
                        <TabsNavigation>
                            {tabs.map((tab, index) =>
                                <TabsNavigationItem key={`tab_${tab.tab}`} index={index} className="">
                                    {tab.name}
                                </TabsNavigationItem>
                            )}
                        </TabsNavigation>
                        <TabsContent>
                            <TabsContentItem index={0}>
                                <PersonAddForm inputID="addPersonFormJSON" userType={true} />
                            </TabsContentItem>
                            <TabsContentItem index={1}>
                                <PeopleSelectionList
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    userType={userType}
                                    id={location?.id} />
                            </TabsContentItem>

                        </TabsContent>
                    </Tabs>



                </div>
                {/* <CompanyUsersSelectionList userType={userType} type={'sites'} id={siteID} selected={selectedRows} setSelected={setSelectedRows} /> */}

            </div>
        )
    }

    MySwal.fire({
        title: <p>Add People</p>,
        html: <PopupForm />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        customClass: {
            htmlContainer: 'z-20',
            popup: '!w-full md:!w-4/5 lg:!w-3/5 xl:!w-1/2'
        },
        preConfirm: () => {
            if (currentTab === 'new') {
                const data = JSON.parse((document.getElementById("addPersonFormJSON") as HTMLInputElement).value);

                return schema
                    .isValid({
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.email,
                    })
                    .then((valid) => {
                        if (valid) {
                            return axios.post(`${urls.remoteURL}users`, { ...data, siteID: location?.id, admin: userType }).then((x) => {
                                return x.data
                            }).catch((err) => {
                                MySwal.showValidationMessage(
                                    err.response.data.message ?? `Problem Saving Person`
                                )
                                return false;
                            });
                        } else {
                            MySwal.showValidationMessage(`Fill in all required fields!`)
                            return false;
                        }
                    });

            } else {
                if (selected.length > 0) {
                    return axios.post(`${urls.remoteURL}sites/${location?.id}/connect`, { userIDs: selected, userType }).then((x) => {
                        return x.data
                    }).catch((err) => {
                        MySwal.showValidationMessage(
                            err.response.data.message ?? `Problem Saving Person`
                        )
                        return false;
                    });
                } else {
                    MySwal.showValidationMessage(`Select at least one user`)
                    return false;

                }
            }
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', message: 'Person added to location.', colour: 'green', icon: faThumbsUp, timing: 3 })
            if (typeof refetch === 'function') { refetch(); }
        }
    })

}




export const handleLocationRemoveUser = (id: string, locationID: string, refetch?: Function) => {
    Swal.fire({
        html: '<h2>Are you sure?</h2>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove them!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}site/${locationID}/users/${id}`)
                .then(() => {
                    if (typeof refetch === 'function') { refetch(); }
                    myToast({
                        title: 'Removed!',
                        message: `The user has been removed from location.`,
                        colour: 'green',
                        icon: faThumbsUp,
                        timing: 3,
                    })
                })
                .catch((error: any) => {
                    myToast({
                        title: 'Error',
                        message: error.response.data.message || 'Error removing location?',
                        colour: 'red',
                        icon: faBiohazard,
                        timing: 3,
                    })
                });

        }
    })
}

export const handleBulkLocationRemoveUser = (id: string, locationIDs: string[], refetch?: Function) => {
    Swal.fire({
        html: '<h2>Are you sure?</h2>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, remove ${locationIDs.length} locations!`
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}user/${id}/locations`, { data: { siteIDs: locationIDs } })
                .then(() => {
                    if (typeof refetch === 'function') { refetch(); }
                    myToast({
                        title: 'Removed!',
                        message: `The user has been removed from location.`,
                        colour: 'green',
                        icon: faThumbsUp,
                        timing: 3,
                    })
                })
                .catch((error: any) => {
                    myToast({
                        title: 'Error',
                        message: error.response.data.message || 'Error removing location?',
                        colour: 'red',
                        icon: faBiohazard,
                        timing: 3,
                    })
                });

        }
    })
}