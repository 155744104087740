import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import axios from "axios";
import { useParams } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { faBiohazard, faThumbsUp } from '@fortawesome/pro-solid-svg-icons'

import Input from "../../../../components/form/Input";
import { myToast, urls } from "../../../../common";
import { PropsComplianceOption } from "../../types";
import Button from "../../../../components/Button";
import LoadingDots from "../../../../components/LoadingDots";
import Label from "../../../../components/form/Label";
import TextArea from "../../../../components/form/Textarea";
import { Link } from "react-router-dom";
import CustomSelect, { CustomSelectControl } from "../../../../components/form/CustomSelect";
import { formatDate } from "../../../../common/dates";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { useCompany } from "../../../../context/companyProvider";
import TextDisplay from "../../../../common/text";

type formProps = {
    type?: string,
    description?: string,
};

const ComplianceOptionNotesForm = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { company } = useCompany();
    let { optionID } = useParams();
    const [loading, setLoading] = useState(true);

    const { compliance } = useCompliance();
    const [option, setOption] = useState<PropsComplianceOption>()
    const [note, setNote] = useState<formProps>();

    useEffect(() => {
        axios.get(`${urls.remoteURL}item/option/${optionID}`)
            .then(({ data }: { data: PropsComplianceOption }) => {
                setOption(data);
                setNote(data.value);
            }).catch(error => {

            }).finally(() => {
                setLoading(false);
            });

    }, [compliance])


    const schema = yup
        .object()
        .shape({
            description: yup.string().required(),
        });

    const { register, handleSubmit, formState: { errors }, control } = useForm<formProps>({
        resolver: yupResolver(schema),
    });


    const submit = (data: formProps) => {
        axios.put(`${urls.remoteURL}item/option/${option?.id}`, {
            value: {
                ...data
            }
        }).then(res => {
            setOption((old) => ({
                ...old,
                value: {
                    ...old?.value,
                    ...data
                }
            }));
        }).then(() => {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
        }).catch((error) => {
            myToast({ title: 'Error', message: error.response.data.message || 'Error saving note?', colour: 'red', icon: faBiohazard, timing: 3 })
        })

    }

    if (loading) {
        return <LoadingDots />;
    }

    if (!option) {
        throw { status: 404, message: 'No Note found?' }
    }

    if (!company?.edit_items) {
        return (
            <div>
                <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                    {MenuElement}

                    <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                        <div className="flex gap-x-2">
                            <Link className="btn btn-blue uppercase rounded-full px-6" to="../notes" >Back</Link>
                        </div>
                    </div>
                </div>

                <div className="space-y-4 grid md:grid-cols-2">
                    <div className="card p-5 space-y-4">
                        <div >
                            <TextDisplay text={note?.type === 'NOTE' ? 'Note' : 'Recommendation'} />
                        </div>

                        <div>
                            <TextDisplay text={note?.description as string} />
                        </div>

                        {option.user &&
                            <div>
                                <TextDisplay text={`Created by ${option.user.fullname} on ${formatDate({ time: option.created as number })}`} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        {/* Add New */}
                        <Link className="btn btn-blue uppercase rounded-full px-6" to="../notes" >Back</Link>
                        <Button color="green" className=" uppercase rounded-full px-6"
                            onClick={() => { }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
            <div className="space-y-4 grid md:grid-cols-2">
                <div className="card p-5 space-y-4">
                    <div >
                        <Label required >
                            Type
                        </Label>
                        <CustomSelectControl
                            required
                            defaultValue={note?.type}
                            name="type"
                            control={control}
                        >
                            <option value="NOTE">Note</option>
                            <option value="RECOMMENDATION">Recommendation</option>
                        </CustomSelectControl>
                    </div>

                    <div>
                        <Label required>Notes</Label>
                        <TextArea
                            required
                            defaultValue={note?.description}
                            rows={10}
                            {...register('description')}
                        />
                    </div>

                    {option.user &&
                        <div>
                            <Label>Created by</Label>
                            <Input readOnly value={option.user.fullname + ' on ' + formatDate({ time: option.created as number })} />
                        </div>
                    }
                </div>
            </div>
        </form>
    )

}

export default ComplianceOptionNotesForm;