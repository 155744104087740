import React from 'react';

import { usePerson } from '../../context/PeopleProvider';
import Label from '../../components/form/Label';
import classNames from 'classnames';
import Input from '../../components/form/Input';

function PersonView({ MenuElement }: { MenuElement?: JSX.Element }) {
    const { person } = usePerson();

    return (
        <div>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className=" my-3 sm:mt-0 sm:ml-4">

                </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4 mb-4">
                <div className="card p-5 space-y-2">
                    <div>
                        <Label className={classNames("block mb-2")} required htmlFor="firstname">
                            Name
                        </Label>
                        <p className={classNames('form-control read-only')}>{[person?.firstname, person?.lastname].join(' ')}</p>
                    </div>

                    <div>
                        <Label className={classNames("block mb-2")} required htmlFor="email">
                            Email
                        </Label>
                        <p className={classNames('form-control read-only')}>{person?.email}</p>
                    </div>

                </div>




            </div>

        </div>
    )

}

export default PersonView;