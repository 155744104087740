import { _now, _DaysAway } from "./dates";

export type PropsColor = 'white' | 'green' | 'indigo' | 'purple' | 'orange' | 'pink' | 'violet' | 'fuchsia' | 'rhinoBlue' | 'rhinoLightBlue' | 'gray' | 'blue' | 'red';

export const statusColour = (status: string) => {
    return status === 'passed' ? 'bg-green-500' :
        status === 'expired' ? 'bg-red-500' :
            status === 'upcoming' ? 'bg-orange-500' :
                status === 'setup' ? 'bg-gray-500' : 'bg-green-500';
}

export const statusDateColour = (time: number) => {
    return time <= _now() ? 'bg-red-500' :
        time <= _DaysAway(14) ? 'bg-orange-500' :
            time ? 'bg-green-500' : 'bg-gray-500';
}


export function hexToRGBA(hex: string, opacity: number) {
    const hexNumber = parseInt(hex.substring(1), 16);
    const r = (hexNumber >> 16) & 255;
    const g = (hexNumber >> 8) & 255;
    const b = hexNumber & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
