import React from "react";
import Tooltip from "../components/Tooltip";
import classNames from "classnames";

export function toTitleCase(input: string): string {
    return input.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
}


interface TruncateProps {
    text: string;
    maxLength?: number;
    tooltip?: boolean;
}

export const TruncateString: React.FC<TruncateProps> = ({ text, maxLength = 50, tooltip }) => {
    if (!tooltip) {
        return (
            <span>
                {`${text.slice(0, maxLength)}`}{text.length > maxLength && `...`}
            </span>
        );
    }
    return text?.length <= maxLength ? <>{text}</> : (
        <Tooltip content={text}>
            <span>
                {`${text.slice(0, maxLength)}...`}
            </span>
        </Tooltip>
    );
}

interface TextDisplayProps {
    text: string;
    className?: string;
}

export const TextDisplay: React.FC<TextDisplayProps> = ({ text, className }) => {
    const lines = text.split('\n');

    return (
        <div className={classNames('space-y-2', className)}>
            {lines.map((line, index) => (
                <p key={index} >{line}</p>
            ))}
        </div>
    );
};

export default TextDisplay;