import React, { ButtonHTMLAttributes, forwardRef } from "react";

import classNames from "classnames";
import { PropsColor } from "../common/colours";


interface PropsButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: PropsColor,
  outlined?: boolean,
  icon?: boolean,
  className?: string,
  link?: boolean,
  children: any,
}

const Button = forwardRef<HTMLButtonElement, PropsButton>((props, ref) => {
  const {
    color = "rhinoBlue",
    outlined,
    icon,
    className,
    children,
    link,
    ...rest
  } = props;

  return (
    <button
      ref={ref}
      className={classNames(
        '',
        {
          "btn": !link,
          [`btn-${color}`]: !link,
          "link": link,
          "btn-outlined": outlined,
          "btn-icon btn_icon_large": icon,
        },
        className
      )
      }
      {...rest}
    >
      {children}
    </button >
  );
}
);

export default Button;
