import { useEffect } from "react";

interface PropsBackdrop {
  active: boolean,
  workspaceOnly: boolean,
};
const Backdrop = (props: PropsBackdrop) => {
  const { active, workspaceOnly } = props;

  useEffect(() => {
    if (active) {
      document.body.classList.add("backdrop-show");
    }

    return () => {
      document.body.classList.remove("backdrop-show");
    };
  }, [active]);

  return active ? (
    <div
      className={`backdrop ${workspaceOnly ? "backdrop_workspace" : ""} ${active ? "active" : ""
        }`}
    ></div>
  ) : null;
};



export default Backdrop;
