import React from "react";
import axios from "axios";

import { myToast, urls } from "../../../../common";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import * as yup from 'yup';
import ComplianceOptionRenewalAddForm from "./AddForm";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { PropsPeople } from "../../../People/types";


export const handleAddCompliancePeople = (compliance: PropsPeople, refetch: Function) => {
    const MySwal = withReactContent(Swal)

    const schema = yup.object().shape({
        tab: yup.string(),
        newUser: yup.object().when(['tab'], (tab, schema) => {
            return tab[0] === 'new'
                ? schema.shape({
                    email: yup.string().email('Please check email').required('Please check email'),
                    firstname: yup.string().required('All Fields Required').min(1, 'All Fields Required'),
                    lastname: yup.string().required('All Fields Required'),
                })
                : schema.strip();
        }),
        users: yup.array().when(['tab'], (tab, schema) => {
            return tab[0] === 'current' ? schema.min(1, 'Please Choose at least one user') : schema.strip();
        }),
    });

    MySwal.fire({
        title: "Add Person",
        html: <ComplianceOptionRenewalAddForm compliance={compliance} inputID="addPersonFormJSON" />,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        width: 800,
        customClass: {
            // popup: 'w-96',
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addPersonFormJSON") as HTMLInputElement).value);

            return schema
                .validate(data, { abortEarly: true })
                .then(() => {
                    return axios.post(`${urls.remoteURL}item/option/${compliance.id}/people`, data).then((x) => {
                        return true;
                    }).catch((err) => {
                        MySwal.showValidationMessage(
                            err.response.data.message ?? `Problem Saving people`
                        )
                        return false;
                    });
                }).catch(err => {
                    MySwal.showValidationMessage(err.response.data.message || `Fill in all required fields!`)
                    return false;
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            refetch();
        }
    })
}

