import React, { useEffect, useState } from "react";
import axios from "axios";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

import { urls } from "../../common";
import LoadingDots from "../../components/LoadingDots";
import { Listbox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";

type PropsList = {
    value: string,
    label: string,
    email: string,
}


const PeopleDropdownSelectControl = (props: UseControllerProps<FieldValues, string>) => {
    const {
        field: { value, onChange }
    } = useController(props);

    const [current, setCurrent] = useState<PropsList | null>();

    const fetchURL = `${urls.remoteURL}get-users`;
    const params = { type: 'select', };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setCurrent(data.find((x: PropsList) => x.value === value))
                setIsLoading(false)
            });
        }
    }, [isLoading])

    useEffect(() => {
        setCurrent(data?.find(x => x.value === value))
    }, [value])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    return (
        <Listbox
            as={'div'}
            value={value} onChange={onChange}
            className="w-full relative"
        >
            <Listbox.Button className={'w-full flex items-center gap-2 form-control text-md'}>
                <span className="">{current?.label || 'Select Person'}</span>
                <FontAwesomeIcon icon={faChevronDown} className="h-5 w-5 text-gray-400 ml-auto" aria-hidden="true" />
            </Listbox.Button>
            <Listbox.Options className={"absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm mb-10"}>
                {data?.map(x => <Listbox.Option
                    key={`person-option-${x.value}`}
                    value={x.value}
                    className={({ active }) =>
                        classNames(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-rhinoBlue-600 text-white' : 'text-gray-900'
                        )
                    }
                >
                    {({ active, selected }) => (
                        <>
                            <div>
                                <span className={classNames('truncate', selected && 'font-semibold')}>{x.label}</span>
                                <span className={classNames('ml-2 truncate text-gray-500', active ? 'text-indigo-200' : 'text-gray-500')}>{x.email}</span>
                            </div>

                            {current?.value === x.value && (
                                <span
                                    className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-rhinoBlue-600'
                                    )}
                                >
                                    <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
                                </span>
                            )}
                        </>
                    )}
                </Listbox.Option>)}
            </Listbox.Options>
        </Listbox>
    )
}

export default PeopleDropdownSelectControl;


type PropsDropDown = {
    onChange: React.Dispatch<React.SetStateAction<string | undefined>>,
    value?: string,
}

export const PeopleDropdownSelect = (props: PropsDropDown) => {
    const { value, onChange } = props;

    const [current, setCurrent] = useState<PropsList | null>();

    const fetchURL = `${urls.remoteURL}get-users`;
    const params = { type: 'select', };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setCurrent(data.find((x: PropsList) => x.value === value))
                setIsLoading(false)
            });
        }
    }, [isLoading])

    useEffect(() => {
        setCurrent(data?.find(x => x.value === value))
    }, [value])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    return (
        <Listbox
            as={'div'}
            defaultValue={value} onChange={onChange}
            className="w-full relative"
        >
            <Listbox.Button className={'w-full flex items-center gap-2 form-control text-md'}>
                <span className="">{current?.label || 'Select Person'}</span>
                <FontAwesomeIcon icon={faChevronDown} className="h-5 w-5 text-gray-400 ml-auto" aria-hidden="true" />
            </Listbox.Button>
            <Listbox.Options className={"absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm mb-10"}>
                {data?.map(x => <Listbox.Option
                    key={`person-option-${x.value}`}
                    value={x.value}
                    className={({ active }) =>
                        classNames(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-rhinoBlue-600 text-white' : 'text-gray-900'
                        )
                    }
                >
                    {({ active, selected }) => (
                        <>
                            <div>
                                <span className={classNames('truncate', selected && 'font-semibold')}>{x.label}</span>
                                <span className={classNames('ml-2 truncate text-gray-500', active ? 'text-indigo-200' : 'text-gray-500')}>{x.email}</span>
                            </div>

                            {current?.value === x.value && (
                                <span
                                    className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-rhinoBlue-600'
                                    )}
                                >
                                    <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
                                </span>
                            )}
                        </>
                    )}
                </Listbox.Option>)}
            </Listbox.Options>
        </Listbox>
    )
}
