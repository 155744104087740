import React, { useEffect, useState } from 'react';
import Breadcrumb, { BreadcrumbItem } from "../../components/Breadcrumb";
import { useParams } from 'react-router-dom';
import type { Params } from 'react-router-dom';

import { useCompany } from '../../context/companyProvider';
import { formatDate } from '../../common/dates';
import { isEmptyObject } from '../../common';
import { useGlobal } from '../../context/globals';
import { useLocation } from '../../context/LocationProvider';
import { useCompliance } from '../../context/ComplianceProvider';
import { useIncident } from '../../context/incidentProvider';

function CompanyBreadcrumbs() {
    const params = useParams<Params>();
    const [current, setCurrent] = useState<string[]>();
    const [pageName, setPageName] = useState<string>('404');

    const { company } = useCompany();
    const { global } = useGlobal();
    const { person, category } = global;
    const { location } = useLocation();
    // const { category } = useCategory();
    const { compliance } = useCompliance();
    const { incident } = useIncident();
    // const { document } = useDocument();
    // const location: any = {};
    // const compliance: any = {};
    const document: any = {};

    const [items, setItems] = useState<JSX.Element[]>();

    useEffect(() => {


        if (isEmptyObject(company)) {
            setItems([]);
            return;
        }

        const breadcrumbItems: JSX.Element[] = [];
        // <BreadcrumbItem key="view-all" link="/control-panel/companies">View All</BreadcrumbItem>,
        // <BreadcrumbItem key="company" link={`/control-panel/companies/${company.id}/dashboard`}>{company?.name}</BreadcrumbItem>
        // ];

        if (current?.includes('dashboard')) {
            setPageName('Dashboard');
            // breadcrumbItems.push(<BreadcrumbItem key="dashboard" >Dashboard</BreadcrumbItem>);
        }

        if (current?.includes('faqs')) {
            setPageName('FAQs');
            // breadcrumbItems.push(<BreadcrumbItem key="dashboard" >Dashboard</BreadcrumbItem>);
        }

        if (current?.includes('support')) {
            setPageName('Support');
            // breadcrumbItems.push(<BreadcrumbItem key="dashboard" >Dashboard</BreadcrumbItem>);
        }

        // if (current?.includes('edit') && Object.values(params).length == 2) {
        //     breadcrumbItems.push(<BreadcrumbItem key="edit" >Edit</BreadcrumbItem>);
        // }

        if (!isEmptyObject(person as Object) || params.personID || (current?.includes('people') && Object.keys(params).length === 1)) {
            setPageName('People');
            breadcrumbItems.push(<BreadcrumbItem key="people" link={params.personID ? '/people' : null} >View all</BreadcrumbItem>);
            if (params.personID) {
                breadcrumbItems.push(<BreadcrumbItem key="person">{person?.fullname}</BreadcrumbItem>);
            }
            if (current?.includes('edit')) {
                breadcrumbItems.push(<BreadcrumbItem key="person-edit" >Edit</BreadcrumbItem>);
            }
            if (current?.includes('locations')) {
                breadcrumbItems.push(<BreadcrumbItem key="person-edit" >Locations</BreadcrumbItem>);
            }
        }

        if (!isEmptyObject(location) || params.locationID || (current?.includes('locations') && Object.keys(params).length === 1)) {
            setPageName('Locations');
            breadcrumbItems.push(<BreadcrumbItem key="locations" link={params.locationID ? '../..' : null} >View all</BreadcrumbItem>);
            if (params.locationID) {
                breadcrumbItems.push(<BreadcrumbItem key="location" >{location?.name}</BreadcrumbItem>);
            }
            if (current?.includes('edit')) {
                breadcrumbItems.push(<BreadcrumbItem key="location-edit" >Edit</BreadcrumbItem>);
            }
            if (current?.includes('children')) {
                breadcrumbItems.push(<BreadcrumbItem key="location-children" >Sub Locations</BreadcrumbItem>);
            }
            if (current?.includes('managers')) {
                breadcrumbItems.push(<BreadcrumbItem key="location-managers" >Managers</BreadcrumbItem>);
            }
            if (current?.includes('people')) {
                breadcrumbItems.push(<BreadcrumbItem key="location-people" >People</BreadcrumbItem>);
            }
        }

        if (!isEmptyObject(category as Object) || (current?.includes('categories') && Object.keys(params).length === 1)) {
            setPageName('Categories');
            breadcrumbItems.push(<BreadcrumbItem key="categories" link={params.categoryID ? '/categories' : null} >View all</BreadcrumbItem>);
            if (params.categoryID) {
                breadcrumbItems.push(<BreadcrumbItem key="location" >{category?.name}</BreadcrumbItem>);
            }
        }

        if (!isEmptyObject(compliance) || params.complianceID || (current?.includes('compliance') && Object.keys(params).length === 1)) {
            setPageName('Compliance');
            breadcrumbItems.push(<BreadcrumbItem key="compliances" link={params.complianceID ? '/compliance' : current?.includes('add') ? '..' : null} >View all</BreadcrumbItem>);
            if (params.complianceID) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance" >{compliance?.name}</BreadcrumbItem>);
            }
            if (current?.includes('edit')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-edit" >Edit</BreadcrumbItem>);
            }
            if (current?.includes('add')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-edit" >Add</BreadcrumbItem>);
            }
            if (current?.includes('notes')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-notes" >Notes</BreadcrumbItem>);
            }

            if (current?.includes('renewals')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-renewals" >Renewals</BreadcrumbItem>);
            }
            if (current?.includes('documents')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-documents" >Documents</BreadcrumbItem>);
            }
            if (current?.includes('certificates')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-certificates" >Certificates</BreadcrumbItem>);
            }
            if (current?.includes('courses')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-courses" >Courses</BreadcrumbItem>);
            }
            if (current?.includes('courses')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-people" >People</BreadcrumbItem>);
            }

            if (current?.includes('assets')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-assets" link={params.optionID ? '..' : null} >Assets</BreadcrumbItem>);
                if (params.optionID) {
                    // breadcrumbItems.push(<BreadcrumbItem key="compliance-assets" >Edit</BreadcrumbItem>);
                }
            }

            if (current?.includes('bulletin')) {
                breadcrumbItems.push(<BreadcrumbItem key="compliance-bulletin" >Bulletin</BreadcrumbItem>);
            }

        }

        if (!isEmptyObject(incident) || params.incidentID || (current?.includes('accidents') && Object.keys(params).length === 1)) {
            setPageName('Accidents/Incidents');
            breadcrumbItems.push(<BreadcrumbItem key="accidents" link={params.incidentID ? '../..' : current?.includes('add') ? '..' : null}  >View all</BreadcrumbItem>);
            if (current?.includes('add')) {
                breadcrumbItems.push(<BreadcrumbItem key="accidents-edit" >Add</BreadcrumbItem>);
            }
            if (params.incidentID) {
                breadcrumbItems.push(<BreadcrumbItem key="accidents" >{formatDate({ time: incident?.datetime as number })}</BreadcrumbItem>);
            }

        }

        if (current?.includes('training')) {
            setPageName('Training');
        }

        if (current?.includes('courses')) {
            setPageName('Courses');
        }

        if (!isEmptyObject(document) || params.documentID || (current?.includes('documents') && Object.keys(params).length === 1)) {
            setPageName('Documents');
            breadcrumbItems.push(<BreadcrumbItem key="documents" link={params.documentID ? '..' : null} >View all</BreadcrumbItem>);
            if (document) {
                breadcrumbItems.push(<BreadcrumbItem key="document-edit" >{document.name}</BreadcrumbItem>);
            }
        }


        setItems(breadcrumbItems);
    }, [company, global, current])

    useEffect(() => {
        // console.log(params);
        if (params['*']) {
            const a = params['*'].split('/');
            setCurrent(a);
        } else {
            setCurrent([]);
        }
    }, [params])


    if (!company && !items) {
        return (<Breadcrumb title={pageName} />)
    }

    return (
        <>
            <Breadcrumb title={pageName}>
                {items?.map(x => x)}
            </Breadcrumb>
        </>
    );
}

export default CompanyBreadcrumbs;