import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import axios from "axios";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { faBiohazard, faThumbsUp } from '@fortawesome/pro-solid-svg-icons'

import { DatePicker } from "../../../../components/form/DatePicker";
import Input from "../../../../components/form/Input";
import { myToast, urls } from "../../../../common";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { PropsComplianceOption } from "../../types";
import Button from "../../../../components/Button";
import LoadingDots from "../../../../components/LoadingDots";
import Label from "../../../../components/form/Label";
import { fromUnixTime, getUnixTime } from "date-fns";
import TextArea from "../../../../components/form/Textarea";
import { Link } from "react-router-dom";

type formProps = {
    name?: string,
    serial?: string,
    notes?: string,
};

const ComplianceOptionAssetsForm = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    let { optionID } = useParams();
    const [loading, setLoading] = useState(true);

    const { compliance } = useCompliance();
    const [option, setOption] = useState<PropsComplianceOption>()
    const [asset, setAsset] = useState<formProps>();
    const [dateStart, setDateStart] = useState<Date | undefined>(new Date());
    const [dateExpire, setDateExpire] = useState<Date | undefined>(undefined);

    useEffect(() => {
        axios.get(`${urls.remoteURL}get-item/option/${optionID}`)
            .then(({ data }: { data: PropsComplianceOption }) => {
                setOption(data);
                setAsset(data.value);

                setDateStart(fromUnixTime(data.date_start as number));
                setDateExpire(fromUnixTime(data.date_expire as number));
            }).catch(error => {

            }).finally(() => {
                setLoading(false);
            });

    }, [compliance])


    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
        });

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<formProps>({
        resolver: yupResolver(schema),
    });


    const submit = (data: formProps) => {
        axios.put(`${urls.remoteURL}item/option/${option?.id}`, {
            date_start: dateStart ? getUnixTime(dateStart as Date) : 0,
            date_expire: dateExpire ? getUnixTime(dateExpire as Date) : 0,
            value: {
                ...data
            }
        }).then(res => {
            setOption((old) => ({
                ...old,
                date_start: dateStart ? getUnixTime(dateStart as Date) : 0,
                date_expire: dateExpire ? getUnixTime(dateExpire as Date) : 0,
                value: {
                    ...old?.value,
                    ...data
                }
            }));
        }).then(() => {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
        }).catch((error) => {
            myToast({ title: 'Error', message: error.response.data.message || 'Error saving asset?', colour: 'red', icon: faBiohazard, timing: 3 })
        })

    }

    if (loading) {
        return <LoadingDots />;
    }

    if (!option) {
        throw { status: 404, message: 'No Asset found?' }
    }

    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        {/* Add New */}
                        <Link className="btn btn-blue uppercase rounded-full px-6" to="../assets" >Back</Link>
                        <Button color="green" className=" uppercase rounded-full px-6"
                            onClick={() => { }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
            <div className="space-y-4">
                <div className="card p-5">
                    <div >
                        <Label className={classNames("block mb-2")} required htmlFor="name">
                            Name
                        </Label>
                        <Input
                            defaultValue={asset?.name}
                            {...register('name')}
                        />
                    </div>
                    <div >
                        <Label className={classNames("block mb-2")} htmlFor="serial">
                            Serial Number
                        </Label>
                        <Input
                            defaultValue={asset?.serial}
                            {...register('serial')}
                        />
                    </div>
                    <div >
                        <Label className={classNames("block mb-2")}>
                            Inspection Date
                        </Label>
                        <DatePicker
                            id={'start_date'}
                            value={dateStart as Date}
                            onChange={setDateStart}
                        />
                    </div>


                    <div >
                        <Label className={classNames("block mb-2")} >
                            Expiring Date
                        </Label>
                        <DatePicker
                            id={'expire_date'}
                            value={dateExpire}
                            onChange={setDateExpire}
                        />
                    </div>
                </div>
                <div className="card p-5">
                    <Label className="block mb-2">Notes</Label>
                    <TextArea
                        defaultValue={asset?.notes}
                        {...register('notes')}
                    />
                </div>
            </div>
        </form>
    )

}

export default ComplianceOptionAssetsForm;