import React, { useEffect } from "react";

import classNames from "classnames";

import Button from "./Button";
import Dropdown from "./Dropdown";

import usePagination, { dots } from "../utilities/hooks/usePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEllipsis } from "@fortawesome/pro-solid-svg-icons";

const Pagination = ({
  currentPage,
  totalCount,
  siblingCount = 1,
  showFirst = false,
  showLast = false,
  pageSize,
  hidePageSize,
  onPageChange,
  onPageSizeChange,
  className,
  compact,
}:
  {
    currentPage: number;
    pageSize: number;
    totalCount: number;
    hidePageSize?: boolean;
    siblingCount?: number;
    showFirst?: boolean;
    showLast?: boolean;
    onPageChange: (page: number | string) => void;
    onPageSizeChange?: (page: number | string) => void;
    className?: string;
    compact?: boolean;
  }) => {

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  useEffect(() => {
    const pageCount = paginationRange ? paginationRange?.length : 0;
    if (currentPage + 1 > pageCount) {
      changePage(currentPage-1);
    }
  }, [paginationRange])


  if (!paginationRange) { return null; }

  const lastPage: number = paginationRange[paginationRange.length - 1] as number;

  const displayingFrom = currentPage * pageSize + 1;

  const displayingTo =
    currentPage * pageSize < totalCount
      ? currentPage * pageSize
      : totalCount;

  const pageSizes = [5, 10, 15, 20, 30, 50];

  const changePage = (page: number | string) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const changePagSize = (size: number | string) => {
    if (onPageSizeChange) {
      onPageSizeChange(size);
      onPageChange(0);
    }
  };

  const next = () => {
    if (onPageChange) {
      onPageChange(currentPage + 1);
    }
  };

  const previous = () => {
    if (onPageChange) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <div className={classNames("lg:flex", className)}>
      {/* {JSON.stringify({
        paginationRange,
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
        lastPage,
        length: paginationRange[paginationRange.length-1],
      })} */}
      {paginationRange.length > 1 &&
        <nav className={classNames(
          "flex flex-1 items-center flex-wrap gap-2",
          compact ? 'p-2' : 'p-5'
        )}>
          <Button
            color="rhinoLightBlue"
            disabled={currentPage === 0}
            onClick={previous}
            className="rounded-full px-4"
          >
            Prev
          </Button>
          {showFirst ? (
            <Button
              color={currentPage === 0 ? "rhinoBlue" : "rhinoLightBlue"}
              onClick={() => changePage(0)}
              className="rounded-full px-4"
            >
              First
            </Button>
          ) : null}
          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === dots) {
              return (
                <span key={index} className="text-2xl"><FontAwesomeIcon icon={faEllipsis} /></span>
              );
            }

            return (
              <Button
                key={index}
                color={(pageNumber as number - 1) === currentPage ? "rhinoBlue" : "rhinoLightBlue"}
                // outlined={pageNumber === currentPage ? false : true}
                onClick={() => changePage((pageNumber as number) - 1)}
                className="rounded-full px-4"
              >
                {pageNumber}
              </Button>
            );
          })}
          {showLast ? (
            <Button
              color={currentPage === lastPage - 1 ? "rhinoBlue" : "rhinoLightBlue"}
              onClick={() => changePage(lastPage)}
              className="rounded-full px-4"
            >
              Last
            </Button>
          ) : null}
          <Button
            color="rhinoLightBlue"
            disabled={currentPage === lastPage - 1}
            onClick={next}
            className="rounded-full px-4"
          >
            Next
          </Button>
        </nav>
      }
      {/* <div className="flex items-center ml-auto p-5 border-t lg:border-t-0 border-divider">
        Displaying {displayingFrom}-{displayingTo} of {totalCount} items
      </div> */}
      {!hidePageSize &&
        <div className={classNames(
          "flex items-center gap-2 border-t lg:border-t-0 lg:border-l border-divider ml-auto",
          compact ? 'p-2' : 'p-5'
        )} >
          <span>Show</span>
          <Dropdown
            content={
              <div className="dropdown-menu">
                {pageSizes.map((size) => (
                  <button key={size} onClick={() => changePagSize(size)}>
                    {size}
                  </button>
                ))}
              </div>
            }
          >
            <Button color="rhinoLightBlue" className="uppercase pl-4 rounded-full">
              {pageSize}
              <span className="ml-2 text-xl leading-none"><FontAwesomeIcon icon={faCaretDown} fixedWidth /></span>
            </Button>
          </Dropdown>
          <span>items</span>
        </div>
      }
    </div >
  );
};

export default Pagination;
