import React, { useEffect, useRef, useState } from "react";


const FileDrop = ({ handleDrag, handleChangeFile, dragActive, handleDrop, help = 'PNG, JPG or GIF (MAX. 512x512px)' }:
    { handleDrag: (e: any) => void, handleChangeFile: (e: any) => void, dragActive: boolean, handleDrop: (e: any) => void, help?: string }) => {

    return (
        <div onDragEnter={handleDrag} className="relative flex items-center justify-center w-full" >
            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100   ">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-500 "><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 ">{help}</p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={e => { if (e.target.files) { handleChangeFile(e.target.files[0]); } }} />
            </label>
            {dragActive && <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 opacity-50 bg-rhinoBlue-600 rounded-lg" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </div >
    );
}

export default FileDrop;