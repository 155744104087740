import React from "react";
import axios from "axios";
import { myToast, urls } from "../../common";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import * as yup from 'yup';
import { faBiohazard, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";

export const handleAddCategory = (categoryID?: string, refetch?: Function) => {
    Swal.fire({
        title: 'Add Category',
        input: 'text',
        inputAttributes: {
            autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
            if (value === '') {
                Swal.showValidationMessage(`Name Required`);
                return;
            }

            return axios.post(`${urls.remoteURL}category`, { name: value, categoryID }).then(() => {
                return true;
            }).catch(error => {
                Swal.showValidationMessage(`${error}`)
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            if (typeof refetch === 'function') { refetch(); }
        }
    })
}



export const handleDeleteCategory = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}category/${id}`)
                .then(() => {
                    myToast({ title: 'Deleted!', message: 'The Category has been deleted.', colour: 'green', icon: faThumbsUp, timing: 3 })
                    if (typeof refetch === 'function') { refetch(); }
                })
                .catch((error) => {
                    myToast({ title: 'Error!', message: error.response.data.message || ' Error deleting Category?', colour: 'red', icon: faBiohazard, timing: 3 })
                });

        }
    })
}



export const handleBulkDeleteCategories = (ids: string[], refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, delete ${ids.length} categories!`
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}categories`, { data: ids })
                .then(() => {
                    myToast({ title: 'Deleted!', message: 'Categories has been deleted.', colour: 'green', icon: faThumbsUp, timing: 3 })
                    if (typeof refetch === 'function') { refetch(); }
                })
                .catch((error) => {
                    myToast({ title: 'Error!', message: error.response.data.message || ' Error deleting Categories?', colour: 'red', icon: faBiohazard, timing: 3 })
                });

        }
    })
}

