import React, { useEffect, useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup';

import { myToast, urls } from "../../../../common";
import ComplianceOptionCertificateAddForm from "./AddForm";
import { faFileUpload } from "@fortawesome/pro-solid-svg-icons";
import { PropsCompliance } from "../../types";

export const handleDownloadCertificate = (fileID: string, filename: string) => {
    axios.get(`${urls.remoteURL}item/option/${fileID}/file`, { responseType: 'blob' })
        .then(({ data }) => {
            fileDownload(data, filename)
        });
}



export const handleAddComplianceCertificate = (compliance: PropsCompliance, refetch: Function) => {
    const MySwal = withReactContent(Swal)
    let file: Blob | null;


    const schema = yup.object().shape({
        attachment: yup.mixed().required('File is required').test("fileSize", "The file is too large", (value: { size: number }) => {
            return value.size <= 20000000
        }),
    })

    const handleFileUploads = (uploadeFile: Blob | null) => {
        file = uploadeFile;
    }

    MySwal.fire({
        title: <p>Add Certificate</p>,
        html: <ComplianceOptionCertificateAddForm compliance={compliance} inputID="addCertificateFormJSON" setFiles={handleFileUploads} />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        customClass: {
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            MySwal.resetValidationMessage();
            if (!file) {
                MySwal.showValidationMessage(
                    `Please Select a certificate`
                )
                return false;
            }
            const data = JSON.parse((document.getElementById("addCertificateFormJSON") as HTMLInputElement).value);
            const formData = new FormData();
            formData.append("upload", file);
            formData.append("dateStart", data.dateStart || 0);
            formData.append("dateExpire", data.dateExpire || 0);
            if (data.person) { formData.append("person", data.person); }
            if (data.courseID) { formData.append("courseID", data.courseID); }

            return schema
                .validate({
                    attachment: file,
                })
                .then(() => {
                    return axios.post(`${urls.remoteURL}item/option/${compliance?.id}/certificate`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((x) => {
                        return true;
                    }).catch((err) => {
                        MySwal.showValidationMessage(
                            err.response.data.message ?? `Problem Saving certificate`
                        )
                        return false;
                    });
                }).catch(err => {
                    MySwal.showValidationMessage(err?.message || `Fill in all required fields!`)
                    return false;
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faFileUpload, timing: 3 })
            refetch();
        }
    })
}