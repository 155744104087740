import React from "react";
import axios from "axios";

import { myToast, urls } from "../../../../common";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import * as yup from 'yup';
import ComplianceOptionCourseAddForm from "./AddForm";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { PropsCompliance } from "../../types";


export const handleAddComplianceCourse = (compliance: PropsCompliance, refetch: Function) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
        title: "Add Courses",
        html: <ComplianceOptionCourseAddForm compliance={compliance} inputID="addCourseFormJSON" />,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        width: 800,
        customClass: {
            // popup: 'w-96',
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addCourseFormJSON") as HTMLInputElement).value);

            if (data.courseIDs.length === 0) {
                MySwal.showValidationMessage(`Please select at least one course!`)
            }
            return axios.post(`${urls.remoteURL}item/option/${compliance.id}/course`, data).then((x) => {
                return true;
            }).catch((err) => {
                MySwal.showValidationMessage(
                    err.response.data.message ?? `Problem Saving courses`
                )
                return false;
            });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            refetch();
        }
    })
}

