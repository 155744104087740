import React, { useEffect, useState } from "react";
import Label from "../../../../components/form/Label";

import classNames from "classnames";
import { DatePicker } from "../../../../components/form/DatePicker";
import { getUnixTime } from "date-fns";

type formProps = { dateStart?: number, dateExpire?: number };

const ComplianceOptionRenewalAddForm = ({ inputID, updated }: { inputID: string, updated?: (data: any) => void }) => {
    const [json, setJSON] = useState<formProps>({});
    const [dateStart, setDateStart] = useState<Date | undefined>(new Date());
    const [dateExpire, setDateExpire] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (typeof updated === 'function') { updated({ dateStart, dateExpire }) }

        setJSON({ dateStart: dateStart ? getUnixTime(dateStart) : 0, dateExpire: dateExpire ? getUnixTime(dateExpire) : 0 });
    }, [dateStart, dateExpire])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <div >
                <Label className={classNames("block mb-2")} required htmlFor="name">
                    Start Date
                </Label>
                <DatePicker
                    id={'start_date'}
                    value={dateStart as Date}
                    onChange={setDateStart}
                />
            </div>


            <div >
                <Label className={classNames("block mb-2")} htmlFor="name">
                    Expiring Date
                </Label>
                <DatePicker
                    id={'expire_date'}
                    value={dateExpire}
                    onChange={setDateExpire}
                />
            </div>


            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    );
}

export default ComplianceOptionRenewalAddForm;