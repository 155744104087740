import React from "react";
import axios from "axios";

import { myToast, urls } from "../../../../common";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import * as yup from 'yup';
import ComplianceOptionRenewalAddForm from "./AddForm";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";


export const handleAddComplianceRenewal = (complianceID: string, refetch: Function) => {
    const MySwal = withReactContent(Swal)
    const schema = yup.object().shape({
        dateStart: yup.number().required(),
    });

    MySwal.fire({
        title: "Add Renewal",
        html: <ComplianceOptionRenewalAddForm inputID="addAssetFormJSON" />,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        // width: 800,
        customClass: {
            // popup: 'w-96',
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addAssetFormJSON") as HTMLInputElement).value);
            console.log(data);

            return schema
                .isValid(data)
                .then((valid) => {
                    if (valid) {
                        return axios.post(`${urls.remoteURL}item/option/${complianceID}/asset`, data).then((x) => {
                            return true;
                        }).catch((err) => {
                            MySwal.showValidationMessage(
                                err.response.data.message ?? `Problem Saving Asset`
                            )
                            return false;
                        });
                    } else {
                        MySwal.showValidationMessage(`Fill in all required fields!`)
                        return false;
                    }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            refetch();
        }
    })
}

