import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    Navigate,
} from 'react-router-dom';
import Wrapper from './components/Wrapper';
import RequireAuth from './common/RequireAuth';

import Error from './Pages/Errors';
import LogoutPage from './Pages/Logout';
import LoginPage from './Pages/Login';
import PeopleList from './Pages/People/List';
import CompanyBreadcrumbs from './Pages/Company/Breadcrumbs';
import CompanyWrapper from './Pages/Company/Wrapper';
import RequireAuthPage from './common/RequireAuthPage';
import PeoplePage from './Pages/People';
import CompanyDashboard from './Pages/Dashboard/Dashboard';
import CategoriesList from './Pages/Categories/List';
import CategoryPage from './Pages/Categories';
import LocationsList from './Pages/Locations/List';
import LocationPage from './Pages/Locations';
import ComplianceList from './Pages/Compliance/List';
import CompliancePage from './Pages/Compliance';
import CoursesList from './Pages/Courses/List';
import DocumentsList from './Pages/Documents/List';
import DocumentPage from './Pages/Documents';
import IncidentsList from './Pages/Incidents/List';
import ComplianceAddForm from './Pages/Compliance/AddForm';
import IncidentAddForm from './Pages/Incidents/AddForm';
import IncidentPage from './Pages/Incidents';
import DashboardCategoryDetails from './Pages/Dashboard/CategoryDetails';
import CompanyReportLocationDetails from './Pages/Dashboard/LocationDetails';
import ResetPasswordPage from './Pages/ResetPassword';
import FAQs from './Pages/FAQs';
import FAQWrapper from './Pages/FAQs/Wrapper';
import Support from './Pages/Support';
import ViewDeclaration from './Pages/Declarations/View';
import DocumentFileBrowser from './Pages/Documents/FileBrowser';
import TrainingMatrix from './Pages/Training/Matrix';


const router = createBrowserRouter(createRoutesFromElements(
    <Route element={<Wrapper />} errorElement={<Error />}>
        <Route path={"login"} element={<LoginPage />} />
        <Route path={"reset-password"}>
            <Route path=":key" element={<ResetPasswordPage />} />
        </Route>
        {/* <Route index element={<TestPage />} /> */}

        <Route path={"declaration"} element={<CompanyWrapper />} errorElement={<Error />}>
            <Route index element={<Error status={404} message='Declaration not found?' />} />
            <Route path={':token'} element={<ViewDeclaration />} />
        </Route>

        <Route path={'*'} element={<RequireAuth />} errorElement={<Error />}>
            <Route index element={<Navigate to={`dashboard`} replace />} />
            <Route path={'dashboard'} element={<CompanyWrapper />} >
                <Route index element={<CompanyDashboard />} />
                <Route path={'category/:categoryID'}>
                    <Route index element={<DashboardCategoryDetails MenuElement={<CompanyBreadcrumbs />} />} />
                    <Route path={'locations'} element={<CompanyReportLocationDetails MenuElement={<CompanyBreadcrumbs />} />} />
                </Route>
            </Route>

            <Route path={'people'} element={<RequireAuthPage permissions='view_people' ><CompanyWrapper /></RequireAuthPage>}>
                <Route index element={<PeopleList MenuElement={<CompanyBreadcrumbs />} />} />
                <Route path={":personID/*"} element={<PeoplePage />} />
                <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />
            </Route>

            <Route path={'locations'} element={<RequireAuthPage permissions='view_sites' ><CompanyWrapper /></RequireAuthPage>}>
                <Route index element={<LocationsList MenuElement={<CompanyBreadcrumbs />} />} />
                <Route path={":locationID/*"} element={<LocationPage />} errorElement={<Error />} />
                <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />
            </Route>

            <Route path={'compliance'} element={<RequireAuthPage permissions='view_items' ><CompanyWrapper /></RequireAuthPage>}>
                <Route index element={<ComplianceList MenuElement={<CompanyBreadcrumbs />} />} />
                <Route path={"add"} element={<ComplianceAddForm MenuElement={<CompanyBreadcrumbs />} />} />
                <Route path={":complianceID/*"} element={<CompliancePage />} />
                <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />
            </Route>

            <Route path={'categories'} element={<RequireAuthPage permissions='view_categories' ><CompanyWrapper /></RequireAuthPage>}>
                <Route index element={<CategoriesList MenuElement={<CompanyBreadcrumbs />} />} />
                <Route path={":categoryID/*"} element={<CategoryPage />} />
                <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />
            </Route>

            <Route path={'accidents'} element={<CompanyWrapper />}>
                <Route index element={<IncidentsList MenuElement={<CompanyBreadcrumbs />} />} />
                <Route path={'add'} element={<IncidentAddForm MenuElement={<CompanyBreadcrumbs />} />} />
                <Route path={":incidentID/*"} element={<IncidentPage />} />
                <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />
            </Route>

            <Route path={'courses'} element={<CompanyWrapper />} >
                <Route index element={<CoursesList MenuElement={<CompanyBreadcrumbs />} />} />
            </Route>

            <Route path={'training'} element={<CompanyWrapper />} >
                <Route index element={<TrainingMatrix MenuElement={<CompanyBreadcrumbs />} />} />
            </Route>

            <Route path={'documents'} element={<CompanyWrapper />} >
                <Route index element={<DocumentFileBrowser MenuElement={<CompanyBreadcrumbs />} />} />
                {/* <Route index element={<DocumentsList MenuElement={<CompanyBreadcrumbs />} />} /> */}
                <Route path={":documentID/*"} element={<DocumentPage />} />
            </Route>


            <Route path={"faqs"} element={<FAQWrapper />}>
                <Route index element={<FAQs MenuElement={<CompanyBreadcrumbs />} />} />
            </Route>

            <Route path={"support"} element={<FAQWrapper />}>
                <Route index element={<Support MenuElement={<CompanyBreadcrumbs />} />} />
                <Route path={'faq'} element={<FAQs MenuElement={<CompanyBreadcrumbs />} />} />
            </Route>

            <Route path={"logout"} element={<LogoutPage />} />
            <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />
        </Route>
    </Route>
));

export default router;