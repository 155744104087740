import React, { useEffect, useState } from "react";
import Label from "../../../../components/form/Label";

import classNames from "classnames";
import { DatePicker } from "../../../../components/form/DatePicker";
import { getUnixTime } from "date-fns";
import Input from "../../../../components/form/Input";
import CustomSelect from "../../../../components/form/CustomSelect";
import TextArea from "../../../../components/form/Textarea";

type formProps = {
    type?: string,
    description?: string,
    user_id?: string,
};

const ComplianceOptionNoteAddForm = ({ inputID, updated }: { inputID: string, updated?: (data: any) => void }) => {
    const [json, setJSON] = useState<formProps>({});
    const [note, setNote] = useState<formProps>({type: 'NOTE'});

    useEffect(() => {
        setJSON({ ...note });
    }, [note])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <div >
                <Label required >
                    Type
                </Label>
                <CustomSelect
                    required
                    defaultValue={note?.type}
                    onChange={(e) => { setNote(prev => ({ ...prev, type: e.target.value })) }}
                >
                    <option value="NOTE">Note</option>
                    <option value="RECOMMENDATION">Recommendation</option>
                </CustomSelect>
            </div>

            <div>
                <TextArea
                    required
                    onChange={(e) => { setNote(prev => ({ ...prev, description: e.target.value })) }}
                    rows={10}
                >{note?.description}</TextArea>
            </div>

            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    );
}

export default ComplianceOptionNoteAddForm;