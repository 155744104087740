import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios from 'axios';
import LoadingDots from '../components/LoadingDots';
import { useGlobal } from './globals';
import { PropsIncident } from '../Pages/Incidents/types';


interface incidentContext {
  incident: PropsIncident;
  setIncident: React.Dispatch<React.SetStateAction<PropsIncident>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const IncidentContext = createContext<incidentContext>({} as incidentContext);


export const IncidentProvider = ({ id, children }: { id: string, children: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [incident, setIncident] = useState<PropsIncident>({} as PropsIncident);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { setGlobal } = useGlobal();

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}incident/${id}`)
        .then(({ data }) => {
          setGlobal(prev => ({ ...prev, incident: data }));
          setIncident({ ...data, id });
        })
        .catch((e) => {
          setIncident({} as PropsIncident);
        })
        .finally(() => {
          setLoading(false)
        });
    } else {
      setLoading(false);
      setIncident({} as PropsIncident);
    }
  }, [id, refresh]);

  return (
    <IncidentContext.Provider value={{ incident, setIncident, setRefresh }}>
      {loading ? <LoadingDots /> : children}
    </IncidentContext.Provider>
  );
}

export const useIncident = () => useContext(IncidentContext);