import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useUser } from "../context/userProvider";
import Header from "./layout/Header";
import SideBar from "./layout/SideBar";
import { useGlobal } from "../context/globals";
import classNames from "classnames";

const Wrapper = () => {
    const { user } = useUser();
    const { global, setGlobal } = useGlobal();
    const params = useParams();

    useEffect(() => {
        // tidying up global
        const { personID, locationID, complianceID, categoryID, incidentID } = params;
        const a = params['*']?.split('/');
        let complianceOptionID;
        if (a) {
            // console.log(a)
            switch (a[0]) {
                case 'compliance':
                    if (a[4]) { complianceOptionID = a[4]; }
                    break;
            }
        }

        // console.log(params);
        if (global.person && !personID) { setGlobal(prev => ({ ...prev, person: undefined })) }
        if (global.category && !categoryID) { setGlobal(prev => ({ ...prev, category: undefined })) }
        if (global.location && !locationID) { setGlobal(prev => ({ ...prev, location: undefined })) }
        if (global.compliance && !complianceID) { setGlobal(prev => ({ ...prev, compliance: undefined })) }
        if (global.incident && !incidentID) { setGlobal(prev => ({ ...prev, incident: undefined })) }
        // if (global.complianceOption && !complianceOptionID) { setGlobal(prev => ({ ...prev, complianceOption: undefined })) }
    }, [params])

    return (
        <div className={
            classNames(
                "relative flex w-full min-h-screen",
                { 'menu-hidden': !global.menuOpen }
            )}>
            <Header />
            {!!user && <SideBar />}
            <Outlet />
        </div>
    )
}

export default Wrapper;