import React, { useEffect, useState } from "react";
import { USERTYPES } from "../../common";
import Label from "../../components/form/Label";
import classNames from "classnames";
import Input from "../../components/form/Input";
import { HeadlessUIRadioGroup } from "../../components/form/RadioGroupButton";
import { PropsPeople } from "./types";

type formProps = { firstname: string, lastname: string, email: string, admin?: string };

const PersonAddForm = ({ inputID, userType, updated }: { inputID: string, userType?: boolean, updated?: (user: PropsPeople) => void }) => {
    const [json, setJSON] = useState({});
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [admin, setAdmin] = useState(USERTYPES[0].id);

    useEffect(() => {
        if (typeof updated === 'function') { updated({ firstname, lastname, email, position, admin: admin === 'admin', site_admin: admin === 'site_admin' }) }

        setJSON({ firstname, lastname, email, position, admin });
    }, [firstname, lastname, email, position, admin])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <div >
                <Label className={classNames("block mb-2")} required htmlFor="firstname">
                    Name
                </Label>
                <div className="grid grid-cols-2 gap-2">
                    <Input
                        placeholder="John"
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                    <Input
                        placeholder="Doh"
                        onChange={(e) => setLastname(e.target.value)}
                    />
                </div>
            </div>

            <div>
                <Label className={classNames("block mb-2")} required={admin !== 'staff'} htmlFor="email">
                    Email
                </Label>
                <Input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="email@domain.com"
                />
                {admin === 'staff' && <small className="text-muted">Please note, email is required to send notifications.</small>}
            </div>

            <div>
                <Label className={classNames("block mb-2")} >
                    Position
                </Label>
                <Input
                    type="text"
                    onChange={(e) => setPosition(e.target.value)}
                    placeholder="Administrator"
                />
            </div>

            <div>
                <Label className={classNames("block mb-2",)} required >
                    User Type
                </Label>
                <HeadlessUIRadioGroup value={admin} onChange={setAdmin} options={USERTYPES} />
            </div>

            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    );
}

export default PersonAddForm;