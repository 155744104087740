import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import Tooltip from "../../../../components/Tooltip";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, formatDate } from "../../../../common/dates";
import { handleBulkDeleteComplianceOptions, handleDeleteComplianceOption } from "../../common";
import { handleAddComplianceBulletin } from "./common";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { PropsDocument } from "../../../Documents/types";
import { handleDownloadFile } from "../../../Documents/common";
import { useCompany } from "../../../../context/companyProvider";
import { PropsCompliance } from "../../types";

const ComplianceOptionBulletinList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();
    const { compliance, setRefresh } = useCompliance();

    const fetchURL = `${urls.remoteURL}get-item/options/${compliance?.id}/bulletin`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-bulletin`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance bulletin items?' };
    }

    type TableProps = {
        id: string,
        value: {
            document_id: string,
            document: PropsDocument
        },

        created: number,
        updated: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.document.name', {
            cell: info => <>
                {info.getValue()}
            </>,
            header: 'Filename',
            footer: 'Filename',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('updated', {
            cell: info => formatDate({ time: info.getValue() }),
            header: 'Uploaded',
            footer: 'Uploaded',
            meta: {
                className: 'w-40'
            }
        }),
        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                <Tooltip content={'Download File'}>
                    <button
                        className="btn btn-orange"
                        onClick={() => { handleDownloadFile({ documentID: info.row.original.value.document_id, filename: info.row.original.value.document.download_name as string }) }}
                    ><span className="sr-only">Download File</span><FontAwesomeIcon icon={faDownload} fixedWidth /></button>
                </Tooltip>

                {!!company.edit_items &&
                    <Tooltip content="Remove File">
                        <Button type="button" color="red"
                            onClick={() => handleDeleteComplianceOption(info.getValue(), 'File', () => { setSelectedRows([]); refetch(); setRefresh(prev => !prev); })}
                        ><span className="sr-only">Remove File</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                    </Tooltip>
                }
            </div >
        )
    }


    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            filename: row.value.document.filename,
            updated: formatDate({ time: row.updated, formatStr: 'd/M/yyyy' }),
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        {/* Add New */}
                        {!!company.edit_items &&
                            <Button color="green" className=" uppercase rounded-full px-6"
                                onClick={() => { handleAddComplianceBulletin(compliance as PropsCompliance, () => { refetch(); setRefresh(prev => !prev); }) }}
                            >
                                Add New
                            </Button>
                        }
                        <DownloadDataButton downloadFilename={`${compliance?.name}-files`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                selectable: !!company.edit_items, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => !!company.edit_items ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'Files', () => { setSelectedRows([]); refetch(); setRefresh(prev => !prev); })
                                    }} >Remove</Button> : null
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionBulletinList;