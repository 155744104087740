import React, { useEffect, useState } from "react";
import axios from "axios";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

import { urls } from "../../common";
import LoadingDots from "../../components/LoadingDots";
import CustomSelect from "../../components/form/CustomSelect";

type PropsList = {
    value: string,
    label: string,
}


const CategoryDropdownSelectControl = (props: UseControllerProps<FieldValues, string>) => {
    const {
        field: { value, onChange }
    } = useController(props);

    const fetchURL = `${urls.remoteURL}get-categories`;
    const params = { type: 'select', };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    return (
        <CustomSelect
            defaultValue={value} onChange={onChange}
        >
            <option value="0">Select category</option>
            {data?.map(x => <option key={`category-option-${x.value}`} value={x.value}>{x.label}</option>)}
        </CustomSelect>
    )
}

export default CategoryDropdownSelectControl;



export const CategoryDropdownSelect = ({ value, onChange }: { value: string, onChange: React.Dispatch<React.SetStateAction<string>> }) => {
    const fetchURL = `${urls.remoteURL}get-categories`;
    const params = { type: 'select', };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    return (
        <CustomSelect
            defaultValue={value} onChange={(e) => onChange(e.target.value)}
        >
            <option value=''>{!value ? `Select Category` : `No Category`}</option>
            {data?.map(x => <option key={`folder-option-${x.value}`} value={x.value}>{x.label}</option>)}
        </CustomSelect>
    )
}