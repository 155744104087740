import { addDays, addMonths, endOfDay, format, fromUnixTime, getUnixTime, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns';
import toast, { ToastPosition } from 'react-hot-toast';
import { IconDefinition, faCalendarCheck, faCertificate, faEnvelopeOpenText, faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faFiles, faFireExtinguisher, faImage, faMessageCaptions, faMusic, faPresentation, faQuestion, faUser, faUserGroup, faUserUnlock, faUsers, faVideo } from '@fortawesome/pro-solid-svg-icons';
import TailwindToast from '../components/TailwindToast';

export const STAGE = process.env.REACT_APP_STAGE || 'localhost';

export const urls = {
  URL: process.env.REACT_APP_URL_BASE,
  URL_CP: process.env.REACT_APP_URL_CP,
  remoteURL: process.env.REACT_APP_REMOTE_URL,
}

export const tokenID = "rhino-portal";

export type COLOURS = 'green' | 'red' | 'orange';

export const myToast = ({ title, message, icon, colour, timing, position }:
  { title: string, message?: string, icon?: IconDefinition, colour?: COLOURS, timing?: number, position?: ToastPosition }) => {
  return toast.custom(t => <TailwindToast t={t} title={title} description={message} icon={icon || faQuestion} bg={colour} timing={timing} />, { position: position || 'top-right' })
}


// copy to api
export type ITEMTYPESTYPE = {
  id: string, label: string, description: string, link: string, icon: IconDefinition, default?: boolean, buttonColour?: string,
  permanent?: boolean,
}

export const ITEMTYPES: ITEMTYPESTYPE[] = [
  { id: 'NOTE', label: 'Notes', description: '', link: 'notes', default: true, icon: faMessageCaptions, buttonColour: 'btn-rhinoBlue', permanent: true },
  { id: 'RENEWAL', label: 'Renewal', description: 'Date where action required.', link: 'renewal', default: true, icon: faCalendarCheck, buttonColour: 'btn-red' },
  { id: 'FILE', label: 'Documents', description: 'General files associated to the compliance item.', link: 'documents', icon: faFiles, buttonColour: 'btn-blue' },
  { id: 'CERTIFICATE', label: 'Certificates', description: 'Certificate Files linked to the compliance item. ', link: 'certificates', icon: faCertificate, buttonColour: 'btn-pink' },
  { id: 'COURSE', label: 'Courses', description: 'Course linked to to the compliance item, usually used with people.', link: 'courses', icon: faPresentation, buttonColour: 'btn-orange' },
  { id: 'PEOPLE', label: 'People', description: 'People who are related to the compliance item, usually in relation to a role.', link: 'people', icon: faUsers, buttonColour: 'btn-purple' },
  { id: 'ASSET', label: 'Assets', description: 'Physical items relating to compliance items, eg: fire extinguisher', link: 'assets', icon: faFireExtinguisher, buttonColour: 'btn-green' },
  // { id: 'BULLETIN', label: 'Bulletin', description: 'Used to inform and track interactions with people, eg. reading documents.', link: 'bulletin', icon: faEnvelopeOpenText, buttonColour: 'btn-rhinoLightBlue' },
];

export const FREQUENCY = [
  { id: 'DAY', label: 'Days' },
  { id: 'WEEKS', label: 'Weeks' },
  { id: 'MONTHS', label: 'Months' },
  { id: 'YEARS', label: 'Years' },
]

export const USERTYPES = [
  { name: 'Admin', id: 'admin' },
  { name: 'Site Admin', id: 'site_admin' },
  { name: 'Staff', id: 'staff' },
];



export const USERFILTER = [
  {
    name: 'All People',
    icon: faUsers,
    type: 'all'
  },
  {
    name: 'Administrators',
    icon: faUserUnlock,
    type: 'admins'
  },
  {
    name: 'Site Administrators',
    icon: faUserGroup,
    type: 'site-admins'
  },
  {
    name: 'People',
    icon: faUser,
    type: 'people'
  },
];




export const STATUS = [
  { id: 'passed', name: 'Passed', color: 'green' },
  { id: 'upcoming', name: 'Upcoming', color: 'orange' },
  { id: 'expired', name: 'Expired', color: 'red' },
  { id: 'setup', name: 'Setup', color: 'gray' },
]





export function getCurrentBreakpoint(): 'sm' | 'md' | 'lg' | 'xl' | '2xl' {
  const width = window.innerWidth;
  if (width < 640) {
    return 'sm';
  } else if (width < 768) {
    return 'md';
  } else if (width < 1024) {
    return 'lg';
  } else if (width < 1280) {
    return 'xl';
  } else {
    return '2xl'; // You can define your own breakpoint for screens larger than 1280px
  }
}



export const fileIcon = (file: File): IconDefinition => {
  if (file) {
    const fileType = file.type;

    if (fileType.startsWith('image/')) {
      return faImage;
    } else if (fileType.startsWith('video/')) {
      return faVideo;
    } else if (fileType.startsWith('audio/')) {
      return faMusic;
    } else {
      const fileExtension = file.name.includes('.') ? file.name.split('.').pop()?.toLowerCase() : 'file';
      switch (fileExtension) {
        case 'doc':
        case 'docx':
          return faFileWord; // Use your custom FontAwesome icon for Word files
        case 'xls':
        case 'xlsx':
          return faFileExcel; // Use your custom FontAwesome icon for Excel files
        case 'ppt':
        case 'pptx':
          return faFilePowerpoint; // Use your custom FontAwesome icon for PowerPoint files
        case 'pdf':
          return faFilePdf;
        default:
          return faFile; // Default icon for other file types
      }
    }
  }
  return faFile; // Default icon for other file types
}


export function isEmptyObject(obj: object): boolean {
  if (!obj) { return true; }
  return Object.keys(obj).length === 0;
}


export const getObjTrueKeys = (data: any) => {
  return Object.keys(data).filter(key => data[key] === true);
}


export function getFileExtension(filename: string): string {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return '';
  }

  return filename.substring(lastDotIndex + 1);
}

export const getFilenameWithoutExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1) {
    return filename;
  }

  return filename.substring(0, lastDotIndex);
}

export const allowedFile = (file: File, ext: string[]): boolean => {
  if (file) {
    const fileType = file.type;
    const fileExtension = file.name.includes('.') ? (file.name.split('.').pop() as string).toLowerCase() : 'file';

    switch (true) {
      case (fileType.startsWith('image/') || fileType.startsWith('video/') || fileType.startsWith('audio/')) && ext.includes('media'):
        return true;
      case (fileType.startsWith('image/')) && ext.includes('image'):
        return true;
      case (['doc', 'docx', 'xls', 'xlsx', 'csv', 'ppt', 'pptx'].includes(fileExtension) && ext.includes('office')):
      case (['doc', 'docx'].includes(fileExtension) && ext.includes('document')):
        return true;
      case (['xls', 'xlsx', 'csv'].includes(fileExtension) && ext.includes('spreadsheet')):
        return true;
      case (['ppt', 'pptx'].includes(fileExtension) && ext.includes('presentation')):
        return true;
      case (['pdf'].includes(fileExtension) && ext.includes('pdf')):
        return true;

      default: return false;
    }
  }
  return false;
}

