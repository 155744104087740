import { FC, InputHTMLAttributes } from "react";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

import classNames from "classnames";

interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean,
  className?: string,
  outlined?: boolean,
  label?: string,
}


const SwitchControl = (props: UseControllerProps<FieldValues, string> & PropsInput) => {
  const { outlined, className, label } = props;
  const {
    field: { value, onChange }
  } = useController(props);

  return (
    <label
      className={classNames("switch", { switch_outlined: outlined }, className)}
    >
      <input type="checkbox" defaultChecked={value} onChange={onChange} />
      <span></span>
      {label ? <span>{label}</span> : null}
    </label>
  );
}

export const Switch = (props: { id: string, defaultChecked: boolean, setValue: React.Dispatch<React.SetStateAction<boolean>>, outlined?: boolean, className?: string, label?: string, value?: string, as?: React.ElementType }) => {
  const { id, defaultChecked, setValue, outlined, className, label, value, as = 'label' } = props;
  const As = as;
  return (
    <As
      className={classNames("switch", { switch_outlined: outlined }, className)}
    >
      <input id={id} type="checkbox" checked={defaultChecked} value={value} readOnly />
      <span onClick={() => setValue(prev => !prev)}></span>
      {label ? <span>{label}</span> : null}
    </As>
  );
}


export default SwitchControl;
