import React from 'react';
import Error from '../Errors';
import {
    Routes,
    Route,
    useParams,
} from 'react-router-dom';
import RequireAuthPage from '../../common/RequireAuthPage';
import CompanyBreadcrumbs from '../Company/Breadcrumbs';
import LocationForm from './Form';
import { LocationProvider } from '../../context/LocationProvider';
import LocationsListChildren from './ListChildren';
import LocationsListPeople from './ListPeople';

function LocationPage() {
    let { locationID } = useParams();

    return (
        <>
            <LocationProvider id={locationID as string}>
                <Routes>
                    <Route errorElement={<Error fullscreen={false} />}>
                        <Route path={'edit'} element={<RequireAuthPage permissions='edit_sites' redirect='../../'><LocationForm MenuElement={<CompanyBreadcrumbs />} /></RequireAuthPage>} />

                        <Route path='children' element={<LocationsListChildren MenuElement={<CompanyBreadcrumbs />} />} />
                        <Route path='managers' element={<LocationsListPeople userType="manager" MenuElement={<CompanyBreadcrumbs />} />} />
                        <Route path='people' element={<LocationsListPeople userType="people" MenuElement={<CompanyBreadcrumbs />} />} />

                        <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />

                    </Route>
                </Routes>
            </LocationProvider>
        </>
    );
}

export default LocationPage;