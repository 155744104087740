import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Table from "../../../../components/Table";
import { _DaysAway, _now, formatDate } from "../../../../common/dates";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { PropsPeople } from "../../../People/types";
import Tooltip from "../../../../components/Tooltip";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { handleBulkDeleteComplianceOptions, handleDeleteComplianceOption } from "../../common";
import Button from "../../../../components/Button";
import { handleAddComplianceNote } from "./common";
import { useCompany } from "../../../../context/companyProvider";
import { TruncateString } from "../../../../common/text";

const ComplianceOptionNotesList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { company } = useCompany();
    const { compliance } = useCompliance();
    const [selectedRows, setSelectedRows] = useState<string[]>();

    const fetchURL = `${urls.remoteURL}item/options/${compliance?.id}/notes`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-notes`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance notes?' };
    }



    type TableProps = {
        id: string,
        value: {
            type: string,
            description: string,
        },
        user: PropsPeople
        created: number,
        updated: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.type', {
            cell: info => info.getValue(),
            header: 'Type',
            footer: 'Type',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('value.description', {
            cell: info => <TruncateString text={info.getValue()} maxLength={100} />,
            header: 'Notes',
            footer: 'Notes',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('user', {
            cell: info => info.getValue().fullname,
            header: 'Person',
            footer: 'Person',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('created', {
            cell: info => formatDate({ time: info.getValue(), formatStr: 'HH:mm do MMMM yyyy' }),
            header: () => 'Created',
            footer: () => 'Created',
            meta: {
                className: 'w-40'
            }
        }),

        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">

                <Tooltip content={`${company.edit_items ? 'Edit' : 'view'} Note`}>
                    <Link
                        className="btn btn-success"
                        to={info.getValue()}
                    ><span className="sr-only">{`${company.edit_items ? 'Edit' : 'view'} Note`}</span><FontAwesomeIcon icon={company.edit_items ? faPencil : faEye} fixedWidth /></Link>
                </Tooltip>

                {!!company.edit_items &&
                    <Tooltip content="Delete Note">
                        <Button type="button" color="red"
                            onClick={() => handleDeleteComplianceOption(info.getValue(), 'Renewal', refetch)}
                        ><span className="sr-only">Delete Note</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                    </Tooltip>
                }
            </div>
        )
    }


    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            message: row.value.description,
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        {!!company.edit_items && <Button color="green" className=" uppercase rounded-full px-6"
                            onClick={() => { handleAddComplianceNote(compliance?.id as string, refetch) }}
                        >
                            Add New
                        </Button>
                        }
                        <DownloadDataButton downloadFilename={`${compliance?.name}-logs`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                selectable: !!company.edit_items, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'Notes', () => { setSelectedRows([]); refetch(); })
                                    }} >DELETE</Button>
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionNotesList;