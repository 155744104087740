import React from 'react';
import Error from '../Errors';
import {
    Routes,
    Route,
    useParams,
} from 'react-router-dom';
import CompanyBreadcrumbs from '../Company/Breadcrumbs';
import DocumentForm from './Form';
import { DocumentProvider } from '../../context/documentProvider';

function DocumentPage() {
    let { documentID } = useParams();

    return (
        <>
            <DocumentProvider id={documentID as string}>
                <Routes>
                    <Route errorElement={<Error fullscreen={false} />}>
                        <Route index element={<DocumentForm MenuElement={<CompanyBreadcrumbs />} />} />

                        <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />

                    </Route>
                </Routes>
            </DocumentProvider>
        </>
    );
}

export default DocumentPage;