import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { _DaysAway, _now, formatDate } from "../../common/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPresentation, faSearch, faTrash } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useGlobal } from "../../context/globals";
import Button from "../../components/Button";
import { handleAddTrainingRecord } from "./common";
import { toTitleCase } from "../../common/text";


const TrainingMatrix = ({ MenuElement }: { MenuElement?: JSX.Element }) => {

    const { global } = useGlobal();
    const [courses, setCourses] = useState<any[]>([]);
    const [headerData, setHeaderData] = useState<any[]>([]);
    const [rowsData, setRowsData] = useState<any>([]);


    const { isLoading, isError, data, refetch } = useQuery(`company-training`, () => axios.all([
        axios.get(`${urls.remoteURL}courses`),
        axios.get(`${urls.remoteURL}get-company/courses`),
        axios.get(`${urls.remoteURL}get-training`)
    ]));

    if (isError) {
        throw { code: 500, message: 'Error getting company categories?' };
    }

    const rows = useMemo(() => {
        if (isLoading === false && rowsData) {
            return Object.values(rowsData)
        }
        return [];
    }, [rowsData]);

    useEffect(() => {
        if (!isLoading && !isError && data) {
            const selected = data ? data[1].data.map((x: { course_id: string }) => x.course_id) : []
            setCourses(data ? Object.values(data[0].data) : []);
            setHeaderData(data ? Object.values(data[0]?.data.filter((x: { id: string }) => selected.includes(x.id))) : []);
            setRowsData(data ? Object.values(data[2]?.data) : []);
        }
    }, [data]);



    type TableProps = {
        id: string,
        fullname: string,
        lastname: string,
        firstname: string,
        email: string,
        [key: string]: string
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = useMemo(() => {
        return [
            columnHelper.accessor('fullname', {}),
            columnHelper.accessor('lastname', {
                cell: info => <>
                    {info.row.original.fullname}
                    {/* <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span> */}
                </>,
                header: 'Name',
                footer: 'Name',
                sortingFn: 'alphanumeric',
                meta: {
                    className: 'sticky left-0 z-10 freeze'
                }
            }),

            ...headerData?.length > 0 ?
                headerData.map((x) => {
                    return columnHelper.accessor(x.id, {
                        cell: info => courseResultCell(info as any, x),
                        header: x.name,
                        footer: x.name,
                        enableSorting: false,
                        meta: {
                            className: 'text-center  justify-center max-w-[100px]'
                        }
                    })
                })
                : []
        ];
    }, [headerData]);

    type PropsResultsCell = { status: string, date: number, renew: number, id: string };

    const courseResultCell = (info: CellContext<TableProps, PropsResultsCell>, course: string) => {
        const { status, date, renew, id } = info.getValue();
        return (
            <div className="group relative">
                <button type="button" className={
                    classNames(
                        `btn group`,
                        status === 'n/a' ? 'btn-gray' : '',
                        status === 'passed' && _now() <= renew ? 'btn-success' : '',
                        status === 'passed' && renew == 0 ? 'btn-success' : '',
                        _DaysAway(7) >= renew && _now() <= renew ? 'btn-orange' : '',
                        status === 'failed' || (_now() > renew && renew > 0) ? 'btn-danger' : '',
                        `rounded p-2 whitespace-nowrap`
                    )}
                    onClick={() => { handleAddTrainingRecord(info.row.original.id, id, refetch) }}
                >
                    {date > 0 ?
                        <div className={``}>{renew > 0 ? 'Renewal' : toTitleCase(status)} <br /> {formatDate({ time: renew > 0 ? renew : date, formatStr: 'd MMM yyyy' })}</div>
                        : status}
                    {/* <div className={`hidden group - hover: block`}>{date > 0 ? formatDate({ time: date, formatStr: 'd MMM yyyy' }) : ''}</div>
                <div className={`hidden group - hover: block`}>{renew > 0 ? formatDate({ time: renew, formatStr: 'd MMM yyyy' }) : ''}</div> */}
                </button >
                {/* {status !== 'n/a' && <Button color="gray" className="hidden group-hover:block absolute top-0 right-0"><FontAwesomeIcon icon={faTrash} /></Button>} */}
            </div>
        )
    }

    const [search, setSearch] = useState<string>();


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <div className="flex gap-x-2">
                        {/* Add New */}
                        {/* <Button color="green" className=" uppercase rounded-full px-6" onClick={() => {}}>
                            Add New
                        </Button> */}
                    </div>
                </div>
            </div>
            <div className={
                classNames(
                    "card p-5",
                    global.menuOpen ? "max-w-[85vw] xl:max-w-[91vw]" : "max-w-[91vw] xl:max-w-[95vw] ",
                )}>
                <div className="">
                    {
                        isLoading ?
                            <div className=" p-10"><LoadingDots /></div> :
                            headerData?.length > 0 ?
                                <Table
                                    columns={columns}
                                    defaultData={rows}
                                    options={{
                                        tableClass: classNames('min-w-full overflow-hidden overflow-x-auto border-t border-r '), freezeCol: true,
                                        search, hiddenColumns: ['fullname'],
                                    }} />
                                :
                                <div className="mx-auto max-w-md p-4">
                                    <Link
                                        to={'courses'}
                                        type="button"
                                        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-rhinoBlue-500 focus:ring-offset-2"
                                    >
                                        <FontAwesomeIcon icon={faPresentation} size="5x" className="text-gray-500" />
                                        <span className="mt-2 block text-sm font-semibold text-gray-900">Associate Courses</span>
                                    </Link>
                                </div>
                    }
                </div>
            </div>
        </>
    )
}

export default TrainingMatrix;