import React from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";

import { myToast, urls } from "../../common";
import LoadingDots from "../../components/LoadingDots";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiohazard, faComment, faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import Label from "../../components/form/Label";
import classNames from "classnames";
import Input from "../../components/form/Input";
import { CustomSelectControl } from "../../components/form/CustomSelect";
import TextArea from "../../components/form/Textarea";

const Support = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const schema = yup
        .object()
        .shape({
            subject: yup.string().required(),
            priority: yup.string().required(),
            message: yup.string().required(),
        });

    const priorities = ['Low', 'Medium', 'High']

    type FormProps = {
        subject?: string,
        message?: string,
        priority?: 'Low' | ' Medium' | 'High'
    };

    const { register, handleSubmit, formState: { errors }, control, reset } = useForm<FormProps>({
        resolver: yupResolver(schema),
        defaultValues: { priority: 'Low', message: '', subject: '' }
    });


    const submit = (data: FormProps) => {
        axios.post(`${urls.remoteURL}support`, data).then(res => {
            reset();
            myToast({ title: 'Sent', message: 'Thank you for contacting support, we will be in touch shortly.', colour: 'green', icon: faPaperPlane, timing: 3 });
        }).catch((error) => {
            myToast({ title: 'Error', message: error.response.data.message || error.message, colour: 'red', icon: faBiohazard, timing: 3 });
        })
    }


    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between align-top " >
                {MenuElement}
                <div className="flex gap-x-2 align-top">

                </div>
            </div>
            <div className="md:grid md:grid-cols-2">
                <div className="card p-5 space-y-4">
                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.subject })} required htmlFor="subject">
                            Subject
                        </Label>
                        <Input
                            {...register('subject')}
                            placeholder="Your Question"
                            invalid={!!errors.subject}
                            className={classNames({ 'invalid': errors.subject })}
                        />
                    </div>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.priority })} required htmlFor="priority">
                            Priority
                        </Label>
                        <CustomSelectControl
                            name={'priority'}
                            invalid={!!errors.priority}
                            control={control}
                        >
                            {priorities?.map(x => <option key={`priority-option-${x.toLowerCase()}`} value={x.toLowerCase()}>{x}</option>)}
                        </CustomSelectControl>
                    </div>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.message })} required htmlFor="message">
                            Message
                        </Label>
                        <TextArea
                            invalid={!!errors.message}
                            {...register('message')}
                            placeholder={''}
                            rows={10}
                        />
                    </div>
                    <div className="text-right">

                        <Button
                            type="submit"
                            color="green"
                            className="rounded-full px-6"
                        >Send <FontAwesomeIcon icon={faPaperPlane} fixedWidth className="ml-2" />
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Support;