import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import Tooltip from "../../../../components/Tooltip";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, formatDate } from "../../../../common/dates";
import { handleAddCompliancePeople } from "./common";
import { handleBulkDeleteComplianceOptions, handleDeleteComplianceOption } from "../../common";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { PropsCompliance } from "../../types";
import { useCompany } from "../../../../context/companyProvider";

const ComplianceOptionPeopleList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();
    const { compliance, setRefresh } = useCompliance();

    const fetchURL = `${urls.remoteURL}get-item/options/${compliance?.id}/people`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-people`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance people?' };
    }



    type TableProps = {
        id: string,
        value: {
            user_id: string,
            complete: number,
            user: {
                lastname: number,
                fullname: number,
                email: number,
            }
        },

        created: number,
        updated: number,
    }



    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.user.fullname', {}),
        columnHelper.accessor('value.user.lastname', {
            cell: info => info.row.original.value.user.fullname,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('value.user.email', {
            cell: info => info.row.original.value.user.email,
            header: 'Email',
            footer: 'Email',
            sortingFn: 'alphanumeric',
        }),


        ...compliance?.options?.includes('BULLETIN') ? [
            columnHelper.accessor('value.complete', {
                cell: info => info.getValue() > 0 ? formatDate({ time: info.getValue(), formatStr: 'H:m - do MMM yyyy' }) : 'n/a',
                header: () => 'Completed',
                footer: () => 'Completed',
                meta: {
                    className: 'w-52'
                }
            })
        ] : []
        ,

        columnHelper.accessor('created', {
            cell: info => info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a',
            header: () => 'Created',
            footer: () => 'Created',
            meta: {
                className: 'w-40'
            }
        }),
        ... !!company.edit_items ? [
            columnHelper.accessor('id', {
                header: 'Actions',
                footer: 'Actions',
                cell: info => actionCell(info),
                enableSorting: false,
                enableGlobalFilter: false,
                meta: {
                    className: 'w-28 text-center'
                }
            })
        ] : [],
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                <Tooltip content="Delete Person">
                    <Button type="button" color="red"
                        onClick={() => handleDeleteComplianceOption(info.getValue(), 'Person', () => { refetch(); setRefresh(prev => !prev); })}
                    ><span className="sr-only">Delete Person</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                </Tooltip>
            </div>
        )
    }

    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            name: row.value.user.fullname,
            email: row.value.user.email,
            ...compliance?.options?.includes('BULLETIN') ? { complete: row.value.complete > 0 ? formatDate({ time: row.value.complete, formatStr: 'H:m d/M/yyyy' }) : 'n/a' } : {},
            created: row.created > 0 ? formatDate({ time: row.created, formatStr: 'd/M/yyyy' }) : 'n/a',
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                    <div className="flex gap-x-2">
                        {/* Add New */}
                        {!!company.edit_items &&
                            <>
                                <Button color="green" className=" uppercase rounded-full px-6"
                                    onClick={() => { handleAddCompliancePeople(compliance as PropsCompliance, () => { refetch(); setRefresh(prev => !prev); }) }}
                                >Add New</Button>
                            </>
                        }
                        <DownloadDataButton downloadFilename={`${compliance?.name}-people`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                hiddenColumns: ['value_user.fullname'],
                                selectable: !!company.edit_items, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => !!company.edit_items ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'People', () => { setSelectedRows([]); refetch(); setRefresh(prev => !prev); })
                                    }} >DELETE</Button> : null
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionPeopleList;