import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PropsDeclaration } from "./types";
import LoadingDots from "../../components/LoadingDots";
import axios from "axios";
import { urls } from "../../common";
import Error from "../Errors";

import parse from 'html-react-parser';
import { PropsCompany } from "../Company/types";
import SignaturePad from "react-signature-pad-wrapper";
import Button from "../../components/Button";
import { handleSignDeclaration } from "./common";
import { formatDate } from "../../common/dates";
import Label from "../../components/form/Label";
import Input from "../../components/form/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

const ViewDeclaration = () => {
    const { token } = useParams();
    const [company, setCompany] = useState<PropsCompany>();
    const [declaration, setDeclaration] = useState<PropsDeclaration>();
    const [notFound, setNotFound] = useState(false);
    const [reload, setReload] = useState(false);
    const [signature_name, setSignatureName] = useState<string>('');
    const [signature_position, setSignaturePosition] = useState<string>('');

    const signaturePadRef = React.createRef<SignaturePad>();

    useEffect(() => {
        axios.get(`${urls.remoteURL}get-declaration/${token}`)
            .then(({ data }) => {
                setDeclaration(data.declaration);
                setCompany(data.company);
            }).catch((response) => {
                setNotFound(true);
            })
    }, [token, reload]);

    if (notFound) {
        return <Error status={404} message="Declaration not found?" />
    }

    if (!declaration) {
        return <LoadingDots />
    }
    return (
        <div className="max-w-[900px] w-full mx-auto">
            <div className="card p-5 space-y-4">
                <div className="flex items-center justify-between border-b py-5">
                    <h1 className="flex-1 order-2 md:-order-none lg:text-3xl">{declaration.name}</h1>
                    <div className="order-1 md:-order-none">
                        {!company?.logo ? <span className="brand relative">{company?.name}</span> : <img src={urls.remoteURL + 'company/' + company.id + '/logo'} className="max-h-20 p-2" />}
                    </div>
                </div>
                <div className="pb-4 border-b">
                    {parse(declaration.content as string)}
                </div>
                <div className="space-y-4">
                    <h4>Signature:</h4>
                    <div>
                        <Label required>Name</Label>
                        <Input
                            type="text"
                            defaultValue={declaration.signature_name}
                            onChange={(e) => { setSignatureName(e.target.value) }}
                            disabled={!!declaration.completed_date}
                        />
                    </div>
                    <div>
                        <Label required>Position</Label>
                        <Input
                            type="text"
                            defaultValue={declaration.signature_position}
                            onChange={(e) => { setSignaturePosition(e.target.value) }}
                            disabled={!!declaration.completed_date}
                        />
                    </div>
                    <div className="border rounded-lg">
                        {declaration?.signature ?
                            <div className="relative">
                                <img src={declaration.signature} className="rounded-lg p-4" />
                                <span className="bg-green-600 text-white absolute rounded-full px-2 -right-1 -bottom-1">{formatDate({ time: declaration?.signature_date as number })}</span>
                            </div>
                            :
                            <div className="relative">
                                <SignaturePad height={250} redrawOnResize ref={signaturePadRef} />
                                <Button color="red" className="absolute -bottom-1 -right-1" onClick={() => signaturePadRef.current?.clear()}><FontAwesomeIcon icon={faTrash} fixedWidth /></Button>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <Button color="blue" disabled={!!declaration.completed_date} className="block w-full text-xl"
                        onClick={() => handleSignDeclaration(token as string, signaturePadRef.current as SignaturePad, { signature_name, signature_position }, () => setReload(prev => !prev))}>{!declaration.completed_date ? "Sign" : "Signed"}</Button>
                </div>
            </div>
        </div >
    )
}


export default ViewDeclaration;