import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios from 'axios';
import LoadingDots from '../components/LoadingDots';
import { useGlobal } from './globals';
import { PropsLocation } from '../Pages/Locations/types';


interface locationContext {
  location: PropsLocation;
  setLocation: React.Dispatch<React.SetStateAction<PropsLocation>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationContext = createContext<locationContext>({} as locationContext);


export const LocationProvider = ({ id, children }: { id: string, children: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<PropsLocation>({} as PropsLocation);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { setGlobal } = useGlobal();

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}get-site/${id}`)
        .then(({ data }) => {
          setGlobal(prev => ({ ...prev, location: data }));
          setLocation({ ...data, id });
        })
        .catch((e) => {
          setLocation({} as PropsLocation);
        })
        .finally(() => {
          setLoading(false)
        });
    } else {
      setLoading(false);
      setLocation({} as PropsLocation);
    }
  }, [id, refresh]);

  return (
    <LocationContext.Provider value={{ location, setLocation, setRefresh }}>
      {loading ? <LoadingDots /> : children}
    </LocationContext.Provider>
  );
}

export const useLocation = () => useContext(LocationContext);