import React, { useEffect, useState } from "react";

import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";
import _ from "lodash";

import Label from "../../components/form/Label";
import classNames from "classnames";
import Input from "../../components/form/Input";
import { PropsIncident, PropsWitness } from "./types";
import { useCompany } from "../../context/companyProvider";
import { allowedFile, fileIcon, myToast, urls } from "../../common";
import { faBiohazard, faFaceGlasses, faPenAlt, faPlus, faSave, faSignature, faThumbsUp, faXmark } from "@fortawesome/pro-solid-svg-icons";
import CustomSelect, { CustomSelectControl } from "../../components/form/CustomSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Button";
import DatePickerControl from "../../components/form/DatePicker";
import TextArea from "../../components/form/Textarea";
import { INCIDENTINVOLVEDTYPE, INCIDENTTYPES, handleAddIncidentWitness, handleDownloadIncidentFile, handleEditIncidentWitness, handleSignIncident } from "./common";
import { fromUnixTime, getUnixTime } from "date-fns";
import { useIncident } from "../../context/incidentProvider";
import SwitchControl, { Switch } from "../../components/form/Switch";
import { _DaysAwayD } from "../../common/dates";
import LocationDropdownSelectControl from "../Locations/DropdownList";
import FileDrop from "../../components/form/FileDrop";
import Tooltip from "../../components/Tooltip";

const IncidentForm = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { company } = useCompany();
    const { incident, setIncident } = useIncident();

    const [witness_details, setWitnessDetails] = useState<PropsWitness[]>(incident?.witness_details || [])

    const schema = yup
        .object()
        .shape({
            // type: yup.string().required(),
            datetime: yup.date().required(),
            ref: yup.string().required(),
            involved_name: yup.string().required(),
        });


    const { register, handleSubmit, formState: { errors }, control, watch } = useForm<PropsIncident>({
        resolver: yupResolver(schema), mode: "onChange",
        defaultValues: {
            // ...incident as PropsIncident,
            action_taken: incident?.action_taken,
            action_taken_date: incident?.action_taken_date,
            action_taken_signiture: incident?.action_taken_signiture,
            authorised_task: incident?.authorised_task,
            authorised_task_comments: incident?.authorised_task_comments,
            authorised_vicinity: incident?.authorised_vicinity,
            authorised_vicinity_comments: incident?.authorised_vicinity_comments,
            circumstances: incident?.circumstances,
            conditions: incident?.conditions,
            damage: incident?.damage,
            // datetime: incident?.datetime,
            description: incident?.description,
            // description_date: incident?.description_date,
            // description_signature: incident?.description_signature,
            immediate_action_taken: incident?.immediate_action_taken,
            incident_type: incident?.incident_type,
            incident_type_other: incident?.incident_type_other,
            injuries: incident?.injuries,
            involved_address: incident?.involved_address,
            involved_company: incident?.involved_company,
            // involved_date: incident?.involved_date,
            involved_name: incident?.involved_name,
            involved_position: incident?.involved_position,
            involved_postcode: incident?.involved_postcode,
            // involved_signature: incident?.involved_signature,
            involved_type: incident?.involved_type,
            involved_type_other: incident?.involved_type_other,
            location: incident?.location,
            location_id: incident?.location_id,
            normal_operating: incident?.normal_operating,
            normal_operating_comments: incident?.normal_operating_comments,
            ref: incident?.ref,
            // reporting_date: incident?.reporting_date,
            reporting_name: incident?.reporting_name,
            reporting_position: incident?.reporting_position,
            // reporting_signature: incident?.reporting_signature,
            // riddor_date: incident?.riddor_date,
            riddor_reportedby: incident?.riddor_reportedby,
            treatment: incident?.treatment,
            // witness_details: incident?.witness_details,
            witness_statements_comments: incident?.witness_statements_comments,
            witness_statements_obtained: incident?.witness_statements_obtained,
            witnesses: incident?.witnesses,
            witnesses_comments: incident?.witnesses_comments,
            works_authorised: incident?.works_authorised,
            works_authorised_comments: incident?.works_authorised_comments,

            datetime: incident?.datetime as number > 0 ? fromUnixTime(incident?.datetime as number) : undefined,
            reporting_date: incident?.reporting_date as number > 0 ? fromUnixTime(incident?.reporting_date as number) : undefined,
            riddor_date: incident?.riddor_date as number > 0 ? fromUnixTime(incident?.riddor_date as number) : undefined,
            description_date: incident?.description_date as number > 0 ? fromUnixTime(incident?.description_date as number) : undefined,
            involved_date: incident?.involved_date as number > 0 ? fromUnixTime(incident?.involved_date as number) : undefined,
        }
    });

    const [file, setFile] = useState<File | null>(null);
    const [dragActive, setDragActive] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);



    const submit = (data: PropsIncident) => {

        const updateData: PropsIncident = {
            ...data,
            datetime: getUnixTime(data.datetime as Date),
            reporting_date: getUnixTime(data.reporting_date as Date),
            riddor_date: getUnixTime(data.riddor_date as Date),
            description_date: getUnixTime(data.description_date as Date),
            // action_taken_date: getUnixTime(data.action_taken_date as Date),
            involved_date: getUnixTime(data.involved_date as Date),
            incident_type: incident?.incident_type,
            witness_details,
            involved_signature: incident?.involved_signature,
            reporting_signature: incident?.reporting_signature,
            description_signature: incident?.description_signature,
        }
        if (incident?.type === 'incident' && (!updateData.incident_type || updateData.incident_type?.length === 0)) {
            myToast({ title: 'Error', message: "Please select at least one incident type!", colour: 'red', icon: faBiohazard, timing: 3 });
            return;
        }

        const formData = new FormData();
        if (file) {
            formData.append("upload", file);
            updateData.pdf_file = file.name;
            updateData.pdf_filename = file.name;
        }
        for (const key in updateData) { if (typeof updateData[key] !== 'undefined') formData.append(key, updateData[key]); }



        // return;
        axios.put(`${urls.remoteURL}incident/${incident?.id}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            setIncident(prev => ({ ...prev, ...updateData }))
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 });
            setFile(null);
            setFileSelected(false);
        }).catch((error) => {
            myToast({ title: 'Error', message: error.response.data.message || "Error saving incident?", colour: 'red', icon: faBiohazard, timing: 3 });
        })
    }

    const watchInvolvedName = useWatch({ control, name: 'involved_name', defaultValue: incident?.involved_name })
    const watchInvolvedPosition = useWatch({ control, name: 'involved_position', defaultValue: incident?.involved_position })


    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            switch (name) {
                case 'involved_type':
                    setIncident(prev => ({ ...prev, involved_type: value.involved_type }))
                    break;
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files.length > 1) {
            alert('Please choose one file')
            return false;
        }

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleChangeFile(e.dataTransfer.files[0])
        }
    };

    const handleChangeFile = (file: File | null) => {
        if (file && allowedFile(file, ['pdf'])) {
            setFile(file);
            setFileSelected(true);
        } else {
            myToast({ title: 'Error', message: 'Please select a pdf file.', colour: 'red', icon: faBiohazard, timing: 3 })
        }
    }



    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className=" my-3 sm:mt-0 sm:ml-4">
                    <Button
                        type="submit"
                        color="green"
                        className="px-6 rounded-full"
                    >
                        Save <FontAwesomeIcon icon={faSave} fixedWidth className="ml-2" />
                    </Button>
                </div>
            </div>

            <div className="space-y-4">
                <div className="lg:grid grid-cols-2 gap-4">
                    <div className="card p-5 space-y-4">
                        <div >
                            <Label className={classNames("block mb-2", { 'invalid': errors.datetime })} required htmlFor="datetime">
                                Date/Time
                            </Label>
                            <DatePickerControl
                                id={'datetime'}
                                name={'datetime'}
                                control={control}
                                // value={fromUnixTime(incident?.datetime as number) as any}
                                time
                                inputFormat="HH:mm do MMMM yyyy"
                                className={classNames({ 'invalid': errors.datetime })}
                            />
                        </div>

                        <div >
                            <Label className={classNames("block mb-2", { 'invalid': errors.ref })} required htmlFor="ref">
                                Reference
                            </Label>
                            <Input
                                id={'ref'}
                                {...register('ref')}
                                className={classNames({ 'invalid': errors.ref })}
                            />
                        </div>

                        <div >
                            <Label className={classNames("block mb-2")} >Location</Label>
                            <LocationDropdownSelectControl control={control} name={'location_id'} nested />
                        </div>
                    </div>

                    <div className="card p-5 space-y-4">
                        <div className="space-y-2">
                            <Label className={classNames("mb-2 flex justify-between align-middle")}>
                                Upload PDF of {incident?.type === 'incident' ? 'Incident' : 'Accident'}
                                <Button type="button" disabled={!incident?.pdf_file} onClick={() => { handleDownloadIncidentFile({ incidentID: incident?.id as string, filename: incident?.pdf_filename as string }) }}>Download</Button>
                            </Label>

                            {!fileSelected && !file ?
                                <FileDrop
                                    handleChangeFile={handleChangeFile}
                                    handleDrag={handleDrag}
                                    handleDrop={handleDrop}
                                    dragActive={dragActive}
                                    help="PDF (max 2mb)"
                                />
                                :
                                <div className="flex gap-2 items-center ">
                                    <div className="flex items-center gap-2">
                                        <div className="bg-green-700 p-2 rounded !w-auto">
                                            <FontAwesomeIcon icon={fileIcon(file as File)} className={'text-white'} fixedWidth />
                                        </div>
                                        {file?.name}
                                    </div>
                                    <Tooltip content={'Remove File'}>
                                        <Button color="red" className="rounded-full" onClick={() => { setFile(null); setFileSelected(false); }}><FontAwesomeIcon icon={faXmark} fixedWidth /></Button>
                                    </Tooltip>
                                </div>
                            }
                            <p className="text-muted italic">Uploading a new file replace the current.</p>
                        </div>
                    </div>
                </div>

                <div className="card p-5 space-y-4">
                    <h3>{incident?.type === 'incident' ? 'Person(s) Involved' : 'Injured Person'}</h3>
                    <div >
                        <Label className={classNames("block mb-2", { 'invalid': errors.ref })} required htmlFor="involved_name">
                            Name(s)
                        </Label>
                        <TextArea
                            id={'involved_name'}
                            {...register('involved_name')}
                            className={classNames({ 'invalid': errors.involved_name })}
                        />
                    </div>
                    <div >
                        <Label className={classNames("block mb-2")} htmlFor="involved_company">
                            Company(s)
                        </Label>
                        <TextArea
                            id={'involved_company'}
                            {...register('involved_company')}
                        />
                    </div>
                    <div >
                        <Label className={classNames("block mb-2")} htmlFor="involved_position">
                            Position(s)
                        </Label>
                        <TextArea
                            id={'involved_position'}
                            {...register('involved_position')}
                        />
                    </div>

                    <div >
                        <Label className={classNames("block mb-2")} required htmlFor="involved_type">
                            Involved Type
                        </Label>
                        <CustomSelect
                            defaultValue={'involved_type'}
                            {...register('involved_type')}
                        >
                            <option value="">Select Type</option>
                            {INCIDENTINVOLVEDTYPE.map(x => <option key={`involved-type-${x.value}`} value={x.value}>{x.label}</option>)}
                        </CustomSelect>
                    </div>

                    {incident?.involved_type === 'other' &&
                        <>
                            <div className="pb-2">
                                <label className={`block text-sm font-medium `}>
                                    Type Other
                                </label>
                                <div className="mt-1 flex-1">
                                    <textarea
                                        {...register('involved_type_other')}
                                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                                        placeholder="Other details about involved"
                                    />
                                </div>
                            </div>
                        </>
                    }


                    {incident?.type === 'incident' &&
                        <div className="pb-2">
                            <Label className={classNames("block mb-2")} required >
                                Incident Type(s)
                            </Label>
                            <div className="block md:grid md:grid-cols-2 lg:grid-cols-3 gap-2 space-y-2 md:space-y-0 mt-1 ">
                                {INCIDENTTYPES.map((option, index) => (
                                    <Label
                                        key={option.value} htmlFor={option.value}
                                        className="flex rounded-lg border border-gray-300 bg-white p-4 cursor-pointer"
                                    >
                                        <div className="min-w-0 flex-1 text-sm leading-6"
                                            onClick={() => {
                                                setIncident(prev => ({
                                                    ...prev, incident_type:
                                                        prev?.incident_type?.includes(option.value) ? prev.incident_type.filter(x => x !== option.value) :
                                                            [...(prev?.incident_type || []), option.value]
                                                }))
                                            }}>
                                            {option.label}
                                            <p id={`${option.value}-description`} className="text-gray-500"></p>
                                        </div>
                                        <div className="ml-3 flex h-6 items-center">
                                            <Switch
                                                as="div"
                                                id={option.value}
                                                setValue={() => {
                                                    setIncident(prev => ({
                                                        ...prev, incident_type:
                                                            prev?.incident_type?.includes(option.value) ? prev.incident_type.filter(x => x !== option.value) :
                                                                [...(prev?.incident_type || []), option.value]
                                                    }))
                                                }}
                                                value={option.value}
                                                defaultChecked={!!incident?.incident_type?.includes(option.value)}
                                            />
                                        </div>
                                    </Label>
                                ))}
                            </div>
                        </div>
                    }

                    {incident?.type === 'accident' &&
                        <div >
                            <Label className={classNames("block mb-2")} htmlFor="involved_address">
                                Address
                            </Label>
                            <TextArea
                                id={'involved_address'}
                                {...register('involved_address')}
                            />
                        </div>
                    }

                </div>

                <div className="card p-5 space-y-4">
                    <h3>{_.startCase(incident?.type)} Details</h3>

                    <div >
                        <Label className={classNames("block mb-2")} htmlFor="location">
                            Location
                        </Label>
                        <TextArea
                            id={'location'}
                            {...register('location')}
                        />
                    </div>

                    <div >
                        <Label className={classNames("block mb-2")} htmlFor="circumstances">
                            Circumstances
                        </Label>
                        <TextArea
                            id={'circumstances'}
                            {...register('circumstances')}
                        />
                        <p className="italic text-sm">explain what was happening at the time</p>
                    </div>

                    <div >
                        <Label className={classNames("block mb-2")} htmlFor="conditions">
                            Environmental conditions
                        </Label>
                        <TextArea
                            id={'conditions'}
                            {...register('conditions')}
                        />
                        <p className="italic text-sm">Inside/outside, ground conditions, day/night, lighting, weather etc</p>
                    </div>

                    {incident?.type === 'incident' &&
                        <>
                            <div >
                                <Label className={classNames("block mb-2")} htmlFor="conditions">
                                    Damage sustained
                                </Label>
                                <TextArea
                                    id={'damage'}
                                    {...register('damage')}
                                />
                                <p className="italic text-sm">If personal injury was sustained, an accident investigation form should be completed</p>
                            </div>

                            <div >
                                <Label className={classNames("block mb-2")} htmlFor="immediate_action_taken">
                                    Immediate action taken to prevent injury and make the area safe
                                </Label>
                                <TextArea
                                    id={'immediate_action_taken'}
                                    {...register('immediate_action_taken')}
                                />
                            </div>

                        </>
                    }

                    {incident?.type === 'accident' &&
                        <>
                            <div >
                                <Label className={classNames("block mb-2")} htmlFor="injuries">
                                    Injuries Sustained
                                </Label>
                                <TextArea
                                    id={'injuries'}
                                    {...register('injuries')}
                                />
                            </div>
                            <div >
                                <Label className={classNames("block mb-2")} htmlFor="treatment">
                                    Immediate first aid given and action taken to make the area safe
                                </Label>
                                <TextArea
                                    id={'treatment'}
                                    {...register('treatment')}
                                />
                            </div>
                        </>
                    }


                    {incident?.type === 'incident' &&
                        <>
                            <div className="space-y-2">
                                <div className="flex">
                                    <div className="flex-1">
                                        <Label className={classNames("block")} htmlFor={'normal_operating'}>
                                            Were normal operating procedures occurring?
                                        </Label>
                                    </div>
                                    <CustomSelectControl
                                        name={'normal_operating'}
                                        control={control}
                                    >
                                        <option value={'no'}>No</option>
                                        <option value={'yes'}>Yes</option>
                                    </CustomSelectControl>
                                    {/* <SwitchControl
                                        name={'normal_operating'}
                                        control={control}
                                    /> */}
                                </div>

                                <div >
                                    <TextArea
                                        placeholder="Comments if any"
                                        id={'normal_operating_comments'}
                                        {...register('normal_operating_comments')}
                                    />
                                </div>
                            </div>

                            <div className="space-y-2">
                                <div className="flex">
                                    <div className="flex-1">
                                        <Label className={classNames("block")} htmlFor={'works_authorised'}>
                                            If not, were activities/works authorised?
                                        </Label>
                                    </div>
                                    <CustomSelectControl
                                        name={'works_authorised'}
                                        control={control}
                                    >
                                        <option value={'no'}>No</option>
                                        <option value={'yes'}>Yes</option>
                                    </CustomSelectControl>
                                    {/* <SwitchControl
                                        name={'works_authorised'}
                                        control={control}
                                    /> */}
                                </div>

                                <div >
                                    <TextArea
                                        placeholder="Comments if any"
                                        id={'works_authorised_comments'}
                                        {...register('works_authorised_comments')}
                                    />
                                </div>
                            </div>

                        </>
                    }


                    {incident?.type === 'accident' &&
                        <>

                            <div className="space-y-2">
                                <div className="flex">
                                    <div className="flex-1">
                                        <Label className={classNames("block")} htmlFor={'authorised_vicinity'}>
                                            Was the person authorised to be in the vicinity?
                                        </Label>
                                    </div>
                                    <CustomSelectControl
                                        name={'authorised_vicinity'}
                                        control={control}
                                    >
                                        <option value={'no'}>No</option>
                                        <option value={'yes'}>Yes</option>
                                    </CustomSelectControl>
                                    {/* <SwitchControl
                                        name={'authorised_vicinity'}
                                        control={control}
                                    /> */}
                                </div>

                                <div >
                                    <TextArea
                                        placeholder="Comments if any"
                                        id={'authorised_vicinity_comments'}
                                        {...register('authorised_vicinity_comments')}
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <div className="flex">
                                    <div className="flex-1">
                                        <Label className={classNames("block")} htmlFor={'authorised_task'}>
                                            Was the person authorised to do the task?
                                        </Label>
                                    </div>
                                    <CustomSelectControl
                                        name={'authorised_task'}
                                        control={control}
                                    >
                                        <option value={'no'}>No</option>
                                        <option value={'yes'}>Yes</option>
                                    </CustomSelectControl>
                                    {/* <SwitchControl
                                        name={'authorised_task'}
                                        control={control}
                                    /> */}
                                </div>

                                <div >
                                    <TextArea
                                        placeholder="Comments if any"
                                        id={'authorised_task_comments'}
                                        {...register('authorised_task_comments')}
                                    />
                                </div>
                            </div>
                        </>
                    }


                    <div className="space-y-2">
                        <div className="flex">
                            <div className="flex-1">
                                <Label className={classNames("block")} htmlFor={'witnesses'}>
                                    Were there any witnesses?
                                </Label>
                            </div>
                            <CustomSelectControl
                                name={'witnesses'}
                                control={control}
                            >
                                <option value={'no'}>No</option>
                                <option value={'yes'}>Yes</option>
                            </CustomSelectControl>
                            {/* <SwitchControl
                                name={'witnesses'}
                                control={control}
                            /> */}
                        </div>

                        <div >
                            <TextArea
                                placeholder="Comments if any"
                                id={'witnesses_comments'}
                                {...register('witnesses_comments')}
                            />
                        </div>
                    </div>

                    <div className="space-y-2">
                        <div className="flex">
                            <div className="flex-1">
                                <Label className={classNames("block")} htmlFor={'witness_statements_obtained'}>
                                    If yes, were witness statements obtained?
                                </Label>
                            </div>
                            <CustomSelectControl
                                name={'witness_statements_obtained'}
                                control={control}
                            >
                                <option value={'no'}>No</option>
                                <option value={'yes'}>Yes</option>
                            </CustomSelectControl>
                            {/* <SwitchControl
                                name={'witness_statements_obtained'}
                                control={control}
                            /> */}
                        </div>

                        <div >
                            <TextArea
                                placeholder="Comments if any"
                                id={'witness_statements_comments'}
                                {...register('witness_statements_comments')}
                            />
                        </div>
                    </div>
                </div>

                <div className="card p-5 space-y-4">
                    <div className="flex items-center justify-between">
                        <h3 className="">Witnesses:</h3>
                        <div className="pb-2">
                            <Button type="button" color="green"
                                onClick={() => {
                                    handleAddIncidentWitness({
                                        setWitness: (data) => setWitnessDetails(prev => ([...prev || [], data]))
                                    })
                                }}
                            ><FontAwesomeIcon icon={faPlus} /></Button>
                        </div>
                    </div>

                    <div>
                        {witness_details?.length > 0 ?
                            <div className="mt-4 block md:grid md:grid-cols-3 gap-2">
                                {witness_details.map((witness, index) => {
                                    return (
                                        <div key={witness.id}>
                                            <div className="mb-2  text-md font-medium text-gray-900 border-b flex justify-between pb-1">
                                                <p>Witness: {++index}</p>
                                                <div className="space-x-2">
                                                    <button type="button" className="btn btn-success"
                                                        onClick={() => {
                                                            handleEditIncidentWitness({
                                                                current: witness, setWitness: (data) => {
                                                                    setWitnessDetails(witness_details.map(item => (item.id === witness.id ? data : item)));
                                                                }
                                                            })
                                                        }}
                                                    ><FontAwesomeIcon icon={faPenAlt} fixedWidth /></button>
                                                    <button type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => { setWitnessDetails(prev => ([...prev.filter(x => x.id !== witness.id)])) }}><FontAwesomeIcon icon={faXmark} fixedWidth /></button>
                                                </div>
                                            </div>
                                            <p className="mb-2 block text-sm font-medium text-gray-900">Name: {witness.name}</p>
                                            <p className="mb-2 block text-sm font-medium text-gray-900">Telephone: {witness.tel}</p>
                                            <div className="mb-2 block text-sm font-medium text-gray-900">Address:
                                                {!!witness?.address && Object.entries(witness.address).map(([key, value]) => (
                                                    value && (
                                                        <div key={key}>
                                                            {value}
                                                        </div>
                                                    )
                                                ))}</div>

                                        </div>
                                    )
                                })}
                            </div>
                            : <button
                                type="button"
                                className="mt-4 relative block w-full max-w-lg mx-auto rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-rhinoBlue-500 focus:ring-offset-2"
                                onClick={() => {
                                    handleAddIncidentWitness({
                                        setWitness: (data) => setWitnessDetails(prev => ([...prev || [], data]))
                                    })
                                }}
                            >
                                <span className="mb-2 block text-sm font-medium text-gray-900">No Witnesses added yet</span>
                                <FontAwesomeIcon icon={faFaceGlasses} className="text-gray-400 h-10" />
                                <span className="mt-2 block text-sm font-medium text-gray-900">Click to Add Witness</span>
                            </button>
                        }
                    </div>

                </div>

                {incident?.type === 'accident' &&
                    <div className="card p-5 space-y-4">
                        <h3>Injured</h3>
                        <div className="block md:grid md:grid-cols-2 gap-2 py-2">
                            <div className="">
                                {!!incident.involved_signature ?
                                    <div className="relative">
                                        <p className="block text-sm font-medium text-gray-700">Signature of person entering this report</p>
                                        <img src={incident.involved_signature} className="border rounded-lg p-4" />
                                        <button type="button" className="btn btn-blue absolute bottom-2 right-2"
                                            onClick={() => {
                                                handleSignIncident({
                                                    current: incident?.involved_signature as string,
                                                    setSign: (data) => { setIncident(prev => ({ ...prev, involved_signature: data })) }
                                                })
                                            }}><FontAwesomeIcon icon={faSignature} fixedWidth /></button>
                                    </div>
                                    : <button
                                        type="button"
                                        className="mt-4 relative block w-full  mx-auto rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-rhinoBlue-500 focus:ring-offset-2"
                                        onClick={() => {
                                            handleSignIncident({
                                                current: incident?.involved_signature as string,
                                                setSign: (data) => { setIncident(prev => ({ ...prev, involved_signature: data })) }
                                            })
                                        }}
                                    >
                                        <span className="mb-2 block text-sm font-medium text-gray-900">No Signature</span>
                                        <FontAwesomeIcon icon={faSignature} className="text-gray-400 h-10" />
                                        <span className="mt-2 block text-sm font-medium text-gray-900">Signature of Injured Person (if possible)</span>
                                    </button>}
                            </div>
                            <div className="space-y-2">
                                <div className="">
                                    <Label className={classNames("block")} htmlFor={'involved_date'}>Date</Label>
                                    <DatePickerControl
                                        id={'involved_date'}
                                        name={'involved_date'}
                                        control={control}
                                        maxDate={_DaysAwayD(1)}
                                        prev
                                    />
                                </div>

                                <div >
                                    <Label className={classNames("block")}>
                                        Name
                                    </Label>
                                    <div className="form-control cursor-not-allowed text-muted">
                                        {watchInvolvedName}
                                    </div>
                                </div>
                                <div >
                                    <Label className={classNames("block")} htmlFor="reporting_position">
                                        Position
                                    </Label>
                                    <div className="form-control cursor-not-allowed text-muted">
                                        {watchInvolvedPosition}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }


                <div className="card p-5 space-y-4">
                    <h3>Reporting</h3>
                    <div className="block md:grid md:grid-cols-2 gap-2 py-2">
                        <div className="">
                            {!!incident?.reporting_signature ?
                                <div className="relative">
                                    <p className="block text-sm font-medium text-gray-700">Signature of person entering this report</p>
                                    <img src={incident.reporting_signature} className="border rounded-lg p-4" />
                                    <button type="button" className="btn btn-blue absolute bottom-2 right-2"
                                        onClick={() => {
                                            handleSignIncident({
                                                current: incident?.reporting_signature as string,
                                                setSign: (data) => { setIncident(prev => ({ ...prev, reporting_signature: data })) }
                                            })
                                        }}><FontAwesomeIcon icon={faSignature} fixedWidth /></button>
                                </div>
                                : <button
                                    type="button"
                                    className="mt-4 relative block w-full  mx-auto rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-rhinoBlue-500 focus:ring-offset-2"
                                    onClick={() => {
                                        handleSignIncident({
                                            current: incident?.reporting_signature as string,
                                            setSign: (data) => { setIncident(prev => ({ ...prev, reporting_signature: data })) }
                                        })
                                    }}
                                >
                                    <span className="mb-2 block text-sm font-medium text-gray-900">No Signature</span>
                                    <FontAwesomeIcon icon={faSignature} className="text-gray-400 h-10" />
                                    <span className="mt-2 block text-sm font-medium text-gray-900">Signature of Person Reporting</span>
                                </button>}
                        </div>
                        <div className="space-y-2">
                            <div className="">
                                <Label className={classNames("block")} htmlFor={'reporting_date'}>Date</Label>
                                <DatePickerControl
                                    id={'reporting_date'}
                                    name={'reporting_date'}
                                    control={control}
                                    maxDate={_DaysAwayD(1)}
                                    prev
                                />
                            </div>

                            <div >
                                <Label className={classNames("block")} htmlFor="reporting_name">
                                    Reporting Name
                                </Label>
                                <Input
                                    id={'reporting_name'}
                                    // disabled
                                    {...register('reporting_name')}
                                    placeholder="John Doe"
                                />
                            </div>
                            <div >
                                <Label className={classNames("block")} htmlFor="reporting_position">
                                    Position
                                </Label>
                                <Input
                                    id={'reporting_position'}
                                    {...register('reporting_position')}
                                    placeholder=""
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card p-5 space-y-4">
                    <div>
                        <h3>Description of {incident?.type === 'incident' ? 'Incident' : 'Accident'}</h3>
                        <h4 className="italic font-medium"> {incident?.type === 'incident' ? 'Include details of cause damage, and witnesses' :
                            'Include details of cause and nature of injury, and witnesses'}</h4>
                    </div>

                    <TextArea
                        id={'description'}
                        {...register('description')}
                    />

                    <div className="block md:grid md:grid-cols-2 gap-2 py-2 border-t">
                        <div className="">
                            {!!incident?.description_signature ?
                                <div className="relative">
                                    <p className="block text-sm font-medium text-gray-700">Signed</p>
                                    <img src={incident.description_signature} className="border rounded-lg p-4" />
                                    <button type="button" className="btn btn-blue absolute bottom-2 right-2"
                                        onClick={() => {
                                            handleSignIncident({
                                                current: incident?.description_signature as string,
                                                setSign: (data) => { setIncident(prev => ({ ...prev, description_signature: data })) }
                                            })
                                        }}><FontAwesomeIcon icon={faSignature} fixedWidth /></button>
                                </div>
                                : <button
                                    type="button"
                                    className="mt-4 relative block w-full  mx-auto rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-rhinoBlue-500 focus:ring-offset-2"
                                    onClick={() => {
                                        handleSignIncident({
                                            current: incident?.description_signature as string,
                                            setSign: (data) => { setIncident(prev => ({ ...prev, description_signature: data })) }
                                        })
                                    }}
                                >
                                    <span className="mb-2 block text-sm font-medium text-gray-900">No Signature</span>
                                    <FontAwesomeIcon icon={faSignature} className="text-gray-400 h-10" />
                                </button>}
                        </div>
                        <div className="">
                            <Label className={classNames("block")} htmlFor={'description_date'}>Date</Label>
                            <DatePickerControl
                                id={'description_date'}
                                name={'description_date'}
                                control={control}
                                maxDate={_DaysAwayD(1)}
                                prev
                            />
                        </div>
                    </div>
                </div>



                <div className="card p-5 space-y-4">
                    <h3>EMPLOYER</h3>
                    <h4 className="italic text-red-700">Complete below if accident is reportable under RIDDOR</h4>

                    <div className="block md:grid md:grid-cols-2 gap-2 py-2">
                        <div className="">
                            <Label className={classNames("block")} htmlFor={'riddor_date'}>Date Reported</Label>
                            <DatePickerControl
                                id={'riddor_date'}
                                name={'riddor_date'}
                                control={control}
                                maxDate={_DaysAwayD(1)}
                                prev
                            />
                        </div>
                        <div>
                            <Label className={classNames("block")} htmlFor={'riddor_reportedby'}>Reported by</Label>
                            <Input
                                id={'riddor_reportedby'}
                                {...register('riddor_reportedby')}
                                placeholder="John Doe"
                            />
                        </div>
                    </div>
                </div>



            </div>
        </form>
    );
}

export default IncidentForm;