import React, { useEffect, useState } from "react";
import { DatePicker } from "../../components/form/DatePicker";
import { getUnixTime } from "date-fns";
import { TRAININGSTATUS } from "./common";
import CustomSelect from "../../components/form/CustomSelect";


type FormProps = { dateStart?: number, dateExpire?: number };


const TrainingRecordAddForm = ({ inputID }: { inputID: string }) => {
    const [json, setJSON] = useState({});

    const [dateStart, setDateStart] = useState<Date | undefined>(new Date());
    const [dateRenewal, setDateRenewal] = useState<Date | undefined>(undefined);

    const [status, setStatus] = useState<string>('passed');

    useEffect(() => {
        // if (typeof updated === 'function') { updated({ dateStart, dateExpire }) }
        const newJSON: FormProps = {};

        setJSON({ ...newJSON, status, date: dateStart ? getUnixTime(dateStart) : 0, renew: dateRenewal ? getUnixTime(dateRenewal) : 0 });
    }, [dateStart, dateRenewal, status]);


    return (
        <form onSubmit={(e) => { e.preventDefault(); }}>
            <div className={`p-4 w-full text-left relative`}>

                <div className="mb-2 flex-1">
                    <label className={`block text-sm font-medium text-gray-700`}>Date <span className="text-red-800">*</span></label>
                    <DatePicker
                        id={'start_date'}
                        value={dateStart as Date}
                        onChange={setDateStart}
                    />
                </div>

                <div className="mb-2 flex-1">
                    <label className={`block text-sm font-medium text-gray-700`}>Renewal Date</label>
                    <DatePicker
                        id={'renewal_date'}
                        value={dateRenewal as Date}
                        onChange={setDateRenewal}
                        showClear={true}
                    />
                </div>

                <div className="pb-2">
                    <label className={`block text-sm font-medium `}>
                        Status <span className="text-red-800">*</span>
                    </label>
                    <div className="mt-1 flex-1">
                        <CustomSelect
                            value={status}
                            onChange={(e) => { setStatus(e.target.value) }}>


                            <option value="">Select ...</option>
                            {TRAININGSTATUS.map(x => {
                                return (
                                    <option key={x.id} value={x.id}>{x.label}</option>
                                )
                            })}
                        </CustomSelect>
                    </div>
                </div>

            </div>
            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    )
}

export default TrainingRecordAddForm;