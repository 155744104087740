import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGauge, faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import { useCompany } from '../../context/companyProvider';
import { urls } from '../../common';
import LoadingDots from '../../components/LoadingDots';
import DashboardItemsListPage from './List';
import Error from '../Errors';
import Tooltip from '../../components/Tooltip';

type categoryProps = {
    name: string,
    setup: number,
    passed: number,
    upcoming: number,
    expired: number,
    sites: siteProps[],
}

type siteProps = {
    id: string,
    name: string,
    passed: number,
    expired: number,
    upcoming: number,
    setup: number,
};

function CompanyReportLocationDetails({ MenuElement }: { MenuElement?: JSX.Element }) {
    const { company } = useCompany();
    const { categoryID } = useParams();

    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState<categoryProps>()

    const fetchURL = `${urls.remoteURL}reporting/category/${categoryID}/sites`;


    useEffect(() => {
        axios.get(fetchURL)
            .then(({ data }) => {
                setCategory(data);
            })
            .catch(error => {
                throw { status: error.response.data.code || 500, message: error.response.data.message || 'Error getting sites reports' }
            })
            .finally(() => { setLoading(false) })
    }, [categoryID])

    if (loading) {
        return (<LoadingDots />);
    }

    if (!category) {
        return <Error status={404} title={'Not Found?'} />
    }

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4  sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className="flex gap-2 my-3 sm:mt-0 sm:ml-4">
                    <Link to='..' className='btn btn-rhinoBlue gap-1 px-6 rounded-full'><FontAwesomeIcon icon={faLayerGroup} fixedWidth /> Category</Link>
                    <Link to='../..' className='btn btn-info gap-1  px-6 rounded-full'><FontAwesomeIcon icon={faGauge} fixedWidth /> Dashboard</Link>
                </div>
            </div>

            <div className="space-y-4">
                <dl className="mt-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                    <div key={'Passed'} className="flex flex-col bg-green-600/5 p-8">
                        <dt className="text-sm font-semibold leading-6 text-green-600">
                            <Tooltip content="Compliance Passed">
                                <Link to={`/compliance`} className='btn btn-green' state={{ status: 'passed', category: categoryID !== 'company' ? categoryID : '' }}>Compliance Passed</Link>
                            </Tooltip>
                        </dt>
                        <dd className="order-first text-3xl font-semibold tracking-tight text-green-900">{category?.passed}</dd>
                    </div>
                    <div key={'expired'} className="flex flex-col bg-red-400/5 p-8">
                        <dt className="text-sm font-semibold leading-6 text-red-600">
                            <Tooltip content="View Expired Compliance">
                                <Link to={`/compliance`} className='btn btn-red' state={{ status: 'expired', category: categoryID !== 'company' ? categoryID : '' }}>Compliance Expired</Link>
                            </Tooltip>
                        </dt>                        <dd className="order-first text-3xl font-semibold tracking-tight text-red-900">{category?.expired}</dd>
                    </div>
                    <div key={'upcoming'} className="flex flex-col bg-blue-400/5 p-8">
                        <dt className="text-sm font-semibold leading-6 text-blue-600">
                            <Tooltip content="View Upcoming Compliance">
                                <Link to={`/compliance`} className='btn btn-blue' state={{ status: 'upcoming', category: categoryID !== 'company' ? categoryID : '' }}>Upcoming Compliance</Link>
                            </Tooltip>
                        </dt>
                        <dd className="order-first text-3xl font-semibold tracking-tight text-blue-900">{category?.upcoming}</dd>
                    </div>
                    <div key={'setup'} className="flex flex-col bg-gray-400/5 p-8">
                        <dt className="text-sm font-semibold leading-6 text-gray-600">
                            <Tooltip content="View Compliance Awaiting Setup">
                                <Link to={`/compliance`} className='btn btn-gray' state={{ status: 'setup', category: categoryID !== 'company' ? categoryID : '' }}>Compliance Awaiting Setup</Link>
                            </Tooltip>
                        </dt>
                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{category?.setup}</dd>
                    </div>
                </dl>

                <div className="card p-5">
                    <div className="pb-4 ">
                        <h3 className="">Sites: {category?.sites.length}</h3>
                    </div>

                    <ul role="list" className="divide-y divide-gray-100">
                        {category?.sites.map(site => {
                            const status = site.passed > 0 && site.expired === 0 && site.upcoming === 0 && site.setup === 0 ? 'text-green-400 bg-green-400/10' :
                                site.expired > 0 ? 'text-red-400 bg-red-400/10' :
                                    site.upcoming > 0 ? 'text-orange-400 bg-red-orange/10' :
                                        'text-gray-400 bg-gray-gray/10';
                            return (
                                <li key={site.id} className="relative block md:flex items-center space-x-4 py-4">
                                    <div className="min-w-0 flex-auto">
                                        <div className="flex items-center gap-x-3">
                                            <div className={`${status} flex-none rounded-full p-1`}>
                                                <div className="h-3 w-3 rounded-full bg-current" />
                                            </div>
                                            <h2 className="min-w-0 text-sm font-semibold leading-6 text-gray-900">
                                                {site.id != "0" ? <Link to={'../../../locations/' + site.id + '/edit'} className="flex text-lg gap-x-2 ">
                                                    <span className="truncate">{site.name}</span>
                                                </Link> : <div className="flex text-lg gap-x-2 ">
                                                    <span className="truncate">{company?.name}</span>
                                                </div>}
                                            </h2>
                                        </div>
                                    </div>
                                    <dl className=" grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                                        <div key={'Passed'} className="flex flex-col bg-green-600/5 p-2">
                                            <dt className="text-sm font-semibold leading-6 text-green-600">
                                                <Tooltip content="View Expired Compliance">
                                                    <Link to={`/compliance`} className='btn btn-green' state={{ status: 'passed', category: categoryID !== 'company' ? categoryID : '', location: site.id }}>Compliance Passed</Link>
                                                </Tooltip>
                                            </dt>
                                            <dd className="order-first text-3xl font-semibold tracking-tight text-green-900">{site.passed}</dd>
                                        </div>
                                        <div key={'expired'} className="flex flex-col bg-red-400/5 p-2">
                                            <dt className="text-sm font-semibold leading-6 text-red-600">
                                                <Tooltip content="View Expired Compliance">
                                                    <Link to={`/compliance`} className='btn btn-red' state={{ status: 'expired', category: categoryID !== 'company' ? categoryID : '', location: site.id }}>Compliance Expired</Link>
                                                </Tooltip>
                                            </dt>
                                            <dd className="order-first text-3xl font-semibold tracking-tight text-red-900">{site.expired}</dd>
                                        </div>
                                        <div key={'upcoming'} className="flex flex-col bg-blue-400/5 p-2">
                                            <dt className="text-sm font-semibold leading-6 text-blue-600">
                                                <Tooltip content="View Upcoming Compliance">
                                                    <Link to={`/compliance`} className='btn btn-blue' state={{ status: 'upcoming', category: categoryID !== 'company' ? categoryID : '', location: site.id }}>Upcoming Compliance</Link>
                                                </Tooltip>
                                            </dt>
                                            <dd className="order-first text-3xl font-semibold tracking-tight text-blue-900">{site.upcoming}</dd>
                                        </div>
                                        <div key={'setup'} className="flex flex-col bg-gray-400/5 p-2">
                                            <dt className="text-sm font-semibold leading-6 text-gray-600">
                                                <Tooltip content="View Compliance Awaiting Setup">
                                                    <Link to={`/compliance`} className='btn btn-gray' state={{ status: 'setup', category: categoryID !== 'company' ? categoryID : '', location: site.id }}>Compliance Awaiting Setup</Link>
                                                </Tooltip>
                                            </dt>
                                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{site.setup}</dd>
                                        </div>
                                    </dl>
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className="card p-5">
                    <DashboardItemsListPage categoryID={categoryID} sitesOnly={true} />
                </div>
            </div>
        </>
    )
}

export default CompanyReportLocationDetails;