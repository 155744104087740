import React, { useEffect, useState } from "react";
import { PropsAddress, PropsWitness } from "./types";
import Label from "../../components/form/Label";
import Input from "../../components/form/Input";
import classNames from "classnames";

const IncidentWitnessForm = ({ current, inputID }: { current?: PropsWitness, inputID: string }) => {
    const [json, setJSON] = useState({});

    const [name, setName] = useState<string>(current?.name || '');
    const [address, setAddress] = useState<PropsAddress>(current?.address as PropsAddress);
    const [tel, setTel] = useState<string>(current?.tel || '');

    useEffect(() => {
        setJSON({ id: current?.id, name, address, tel });
    }, [name, address, tel])


    return (
        <form onSubmit={(e) => { e.preventDefault(); }}>
            <div className={`p-4 w-full text-left relative space-y-2`}>

                <div className="">
                    <Label className={classNames("block mb-2")} htmlFor="name" required>
                        Name
                    </Label>
                    <Input
                        id="name"
                        defaultValue={name}
                        onChange={(e) => { setName(e.target.value) }}
                        placeholder="John Doe"
                    />
                </div>


                <div className="">
                    <Label className={classNames("block mb-2")} htmlFor="tel">
                        Telephone
                    </Label>
                    <Input
                        id="tel"
                        defaultValue={tel}
                        onChange={(e) => { setTel(e.target.value) }}
                        placeholder="++447891023456"
                    />
                </div>

                <div className="">
                    <Label className={classNames("block mb-2")} htmlFor="address">
                        Address
                    </Label>
                    <div className="space-y-2">

                        <Input
                            id='address'
                            defaultValue={address?.address}
                            onChange={(e) => { setAddress(prev => ({ ...prev, address: e.target.value })) }}
                            placeholder="Address line 1"
                        />
                        <Input
                            defaultValue={address?.address_2}
                            onChange={(e) => { setAddress(prev => ({ ...prev, address_2: e.target.value })) }}
                            placeholder="Address line 2"
                        />
                        <Input
                            defaultValue={address?.address_3}
                            onChange={(e) => { setAddress(prev => ({ ...prev, address_3: e.target.value })) }}
                            placeholder="Address line 3"
                        />
                        <Input
                            defaultValue={address?.city}
                            onChange={(e) => { setAddress(prev => ({ ...prev, city: e.target.value })) }}
                            placeholder="Town/City"
                        />
                        <Input
                            defaultValue={address?.county}
                            onChange={(e) => { setAddress(prev => ({ ...prev, county: e.target.value })) }}
                            placeholder="County"
                        />
                        <Input
                            defaultValue={address?.postcode}
                            onChange={(e) => { setAddress(prev => ({ ...prev, postcode: e.target.value })) }}
                            placeholder="postcode"
                        />
                    </div>
                </div>
            </div>
            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form >
    )
}

export default IncidentWitnessForm;