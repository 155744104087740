import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios from 'axios';
import LoadingDots from '../components/LoadingDots';
import { useGlobal } from './globals';
import { PropsCategory } from '../Pages/Categories/types';


interface categoryContext {
  category: PropsCategory;
  setCategory: React.Dispatch<React.SetStateAction<PropsCategory>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryContext = createContext<categoryContext>({} as categoryContext);


export const CategoryProvider = ({ id, children }: { id: string, children: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [category, setCategory] = useState<PropsCategory>({} as PropsCategory);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { setGlobal } = useGlobal();

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}get-category/${id}`)
        .then(({ data }) => {
          setGlobal(prev => ({ ...prev, category: data }));
          setCategory({ ...data, id });
        })
        .catch((e) => {
          setCategory({} as PropsCategory);
        })
        .finally(() => {
          setLoading(false)
        });
    } else {
      setLoading(false);
      setCategory({} as PropsCategory);
    }
  }, [id, refresh]);

  return (
    <CategoryContext.Provider value={{ category, setCategory, setRefresh }}>
      {loading ? <LoadingDots /> : children}
    </CategoryContext.Provider>
  );
}

export const useCategory = () => useContext(CategoryContext);