import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { urls } from "../../common";
import { createColumnHelper } from "@tanstack/react-table";
import LoadingDots from "../../components/LoadingDots";
import Table from "../../components/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";

const CourseSelectionList = (
    { type, selectedRows, setSelectedRows, disabled }:
        { type: string, selectedRows: string[], setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>, disabled?: string[] }) => {
    type TableProps = {
        id: string,
        code: string,
        name: string,
    }

    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState<string>('');
    const [data, setData] = useState<TableProps[]>();

    const fetchURL = `${urls.remoteURL}get-company/courses`;
    const params = type === 'compliance' ? { } : {};

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params: { ...params } }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    const tableRows = useMemo(() => {
        return isLoading === false && data ? data : [];
    }, [data, selectedRows]);

    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('code', {
            cell: info => info.getValue() || 'n/a',
            header: 'Code',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: 'Course',
            sortingFn: 'alphanumeric',
        }),
    ];


    return (
        <div className="overflow-x-auto">
            <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">

                {/* Search */}
                <div className="flex ml-auto" >
                    <label className="form-control-addon-within rounded-full items-center">
                        <input
                            className="form-control border-none expanding"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                        />
                        <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                    </label>
                </div>
            </div>
            {isLoading ? <LoadingDots /> :
                <Table
                    defaultData={tableRows}
                    columns={columns}
                    options={{
                        search, selectable: true,
                        hideSelection: true,
                        selectedRows, selectableFunction: setSelectedRows, selectDisabled: disabled,
                        compactFooter: true,
                    }}
                />
            }
        </div>
    );
}

export default CourseSelectionList;