import React, { useEffect, useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { useCSVReader } from "react-papaparse";

import { urls } from "../../common";
import { PropsPeople } from "./types";
import isEmailValid from "../../common/isEmail";

type BulkPeopleProps = PropsPeople & { duplicateMessage?: string, errorMessage?: string, successMessage?: string }

const PeopleBulkAddForm = ({ inputID, userType, updated, mySwal }: { inputID: string, userType?: boolean, updated?: (user: PropsPeople) => void, mySwal?: any }) => {
    const { CSVReader } = useCSVReader();

    const [json, setJSON] = useState<BulkPeopleProps[]>([]);

    const csvData = [
        ["firstname", "lastname", "email", "password", "admin", "site", "site_two", "site_three",],
        ["John", "Doe", "johndoe@gmail.com", "mypassword", "yes", "Sandbach", "London", "Dubai"],
        ["Alice", "Bloggs", "alicebloggs@gmail.com", "", "no", "Sandbach"],
    ];


    const handlePeopleCSVDrop = ({ data }: any) => {
        mySwal.resetValidationMessage();
        try {
            const newPeople: BulkPeopleProps[] = data.map((v: BulkPeopleProps, i: number) => {
                if (!v.firstname) { return null; }
                if ((v.email && !!v.email.trim() && !isEmailValid(v.email as string)) || !v.firstname) {
                    return {
                        ...v,
                        errorMessage: 'Check required columns'
                    };
                } else if (!v.email?.trim() && (v.admin as unknown as string) === 'yes') {
                    return {
                        ...v,
                        errorMessage: 'Email required for admins!'
                    };
                } else {
                    return {
                        ...v,
                        successMessage: 'Good to go!'
                    };
                }
            }).filter((x: null) => x !== null);

            let nodups: BulkPeopleProps[] = [];
            for (const u of newPeople) {
                if (u.email && u.email?.length > 0) {
                    if (!nodups.find(x => x.email === u.email)) {
                        nodups.push(u);
                    }
                } else {
                    nodups.push(u);
                }
            }

            if (nodups.length > 0) {
                const button = mySwal.getConfirmButton();
                return axios.post(`${urls.remoteURL}users/check-emails`, { emails: nodups.map(x => x.email) })
                    .then(({ data }) => {
                        const checked: BulkPeopleProps[] = [];
                        for (const user of nodups) {
                            checked.push({ ...user, duplicateMessage: data.includes(user.email) ? 'Duplicate User!' : undefined });
                        }
                        setJSON(checked);
                        button.disabled = false;
                    }).catch(error => {
                        const checked: BulkPeopleProps[] = [];
                        for (const user of nodups) {
                            checked.push({ ...user, errorMessage: error.response.data.errors.includes(user.email) ? 'Not unique!' : undefined });
                        }
                        setJSON(checked);
                        button.disabled = true;
                        mySwal.showValidationMessage(`Error Checking users!`)
                    });
            } else {
                mySwal.showValidationMessage(`No users in list!`)
            }
        } catch (error) {
            console.error(error);
            mySwal.showValidationMessage(`Error Processing CSV!`)
        }
    }

    // useEffect(() => {
    // if (typeof updated === 'function') { updated({}) }
    // 
    // setJSON();
    // }, [])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}>
            <div className={`p-4 w-full text-left relative`}>
                <CSVReader
                    config={{ header: true }}
                    onUploadAccepted={handlePeopleCSVDrop}
                >
                    {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                    }: any) => (
                        <>{!json || !acceptedFile ?
                            <div className=" relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center" {...getRootProps()} >
                                <button type='button' className="btn" >
                                    Browse file
                                </button>
                                <ProgressBar />
                            </div>
                            :
                            <div >
                                {/* <h3 className="text-center font-bold text-lg">{acceptedFile.name}</h3> */}
                                <div className="max-h-80 overflow-auto">
                                    <ul className="space-y-2">
                                        {json.map((row) => {
                                            return (
                                                <li key={row.email} className={`p-2 rounded ${row.errorMessage ? 'bg-red-400/25' : row.duplicateMessage ? 'bg-blue-400/25' : 'bg-green-400/25'} text-gray-700 `}>{row.firstname} {row.lastname}: {row.email} - {row.errorMessage || (row.duplicateMessage) || row.successMessage}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <dl className="mt-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-3 lg:grid-cols-3">
                                    <div className="flex flex-col bg-gray-400/25 p-3">
                                        <dt className="text-sm font-semibold leading-6 text-gray-600">Total Users</dt>
                                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                            {json.length}</dd>
                                    </div>
                                    <div className="flex flex-col bg-gray-400/25 p-3">
                                        <dt className="text-sm font-semibold leading-6 text-gray-600">Errors</dt>
                                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                            {json.filter((x) => !!x.errorMessage).length}</dd>
                                    </div>
                                    <div className="flex flex-col bg-gray-400/25 p-3">
                                        <dt className="text-sm font-semibold leading-6 text-gray-600">Duplicates</dt>
                                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{json.filter((x) => x.duplicateMessage).length}</dd>
                                    </div>
                                </dl>
                                <div className="text-center mt-3">
                                    <button {...getRemoveFileProps()} className="btn btn-danger mx-auto" >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        }
                        </>
                    )}
                </CSVReader>

                <p className="text-gray-500 text-sm mt-2"><CSVLink data={csvData} filename="new-users.csv">Click here</CSVLink> to download example file.</p>
            </div>
            <input type="hidden" readOnly id={inputID} value={JSON.stringify({ people: json })} />
        </form>
    );
}

export default PeopleBulkAddForm;