import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { handleUpdateCompanyCourses } from "./common";


const CoursesList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [courseRowsData, setCourseRowsData] = useState<TableProps[]>();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const { isLoading, isError, data, refetch } = useQuery(`company-courses-`, () => axios.all([
        axios.get(`${urls.remoteURL}courses`),
        axios.get(`${urls.remoteURL}get-company/courses`)
    ]));

    useEffect(() => {
        if (!isLoading && !isError) {
            setSelectedRows(data ? data[1]?.data.map((x: { course_id: string }) => x.course_id) : []);
            setCourseRowsData(data ? Object.values(data[0]?.data) : [])
        }
    }, [data]);

    const courseRows = useMemo(() => {
        return isLoading === false && courseRowsData ? Object.values(courseRowsData) : [];
    }, [courseRowsData, selectedRows]);


    if (isError) {
        throw { code: 500, message: 'Error getting company courses?' };
    }


    type TableProps = {
        id: string,
        name: string,
        code: number,
    }

    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => <>
                {info.getValue()}
                {/* <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span> */}
            </>,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('code', {
            cell: info => info.getValue() || 'n/a',
            header: 'Code',
            footer: 'Code',
            sortingFn: 'alphanumeric',
        }),

    ];


    const [search, setSearch] = useState<string>();


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <div className="flex gap-x-2">
                        {/* Add New */}
                        <Button color="green" className=" uppercase rounded-full px-6"
                            onClick={() => { handleUpdateCompanyCourses(selectedRows) }}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={courseRows}
                            columns={columns}
                            options={{ search, selectable: true, selectedRows, selectableFunction: setSelectedRows }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default CoursesList;