import { Link } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";

interface PropsBreadcrumb {
  title: string,
  className?: string,
  children?: JSX.Element|JSX.Element[],
}


const Breadcrumb = (props: PropsBreadcrumb) => {
  const { title, className, children } = props;

  const lastIndex = Array.isArray(children) ? children.length - 1 : 0;

  const renderItems = () => {
    const Item = (props: any) => {
      const { item, index } = props;

      return (
        <>
          {item}
          {index < lastIndex ? (
            <li className="divider"><FontAwesomeIcon icon={faArrowRight} /></li>
          ) : null}
        </>
      );
    };

    return  Array.isArray(children) ? children.map((item: any, index: number) => {
      return <Item key={index} item={item} index={index} />;
    }) : children;
  };

  return (
    <section className={classNames("breadcrumb", className)}>
      {title ? <h1>{title}</h1> : null}
      <ul>{renderItems()}</ul>
    </section>
  );
};


const BreadcrumbItem = (props: { link?: string | null, children: any }) => {
  const { link, children } = props;

  if (link) {
    return (
      <li>
        <Link to={link}>{children}</Link>
      </li>
    );
  } else {
    return <li>{children}</li>;
  }
};

export default Breadcrumb;

export { BreadcrumbItem };
