import React, { useRef } from "react";
import axios from "axios";
import { myToast, urls } from "../../common";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import { v4 as uuidv4 } from 'uuid';
import SignaturePad from 'react-signature-pad-wrapper'

import * as yup from 'yup';
import IncidentWitnessForm from "./WitnessForm";
import { PropsWitness } from "./types";
import { faBiohazard, faFileUpload, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import fileDownload from "js-file-download";




export const INCIDENT = [{ value: 'accident', label: 'Accident' }, { value: 'incident', label: 'Incident' },];

export const INCIDENTINVOLVEDTYPE = [
    { value: 'employee', label: 'Employee' },
    { value: 'visitor', label: 'Visitor' },
    { value: 'contractor', label: 'Contractor' },
    { value: 'other', label: 'Other' },
]

export const INCIDENTTYPES = [
    { value: 'near-miss', label: 'Near Miss' },
    { value: 'environmental-incident', label: 'Environmental Incident' },
    { value: 'equipment-damage', label: 'Equipment Damage' },
    { value: 'property-damage', label: 'Property Damage' },
    { value: 'service-strike', label: 'Service Strike' },
]






export const handleAddIncidentWitness = ({ setWitness }: { setWitness: (data: PropsWitness) => void }) => {
    const MySwal = withReactContent(Swal)
    const schema = yup.object().shape({
        name: yup.string().required(),
    });

    MySwal.fire({
        title: <p>Add Witness</p>,
        html: <IncidentWitnessForm current={{ id: uuidv4() }} inputID="addIncidentWitnessFormJSON" />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        // width: 800,
        customClass: {
            htmlContainer: '!z-auto !overflow-visible '
        },
        preConfirm: () => {
            const data: PropsWitness = JSON.parse((document.getElementById("addIncidentWitnessFormJSON") as HTMLInputElement).value);
            return schema
                .isValid({
                    name: data.name,
                })
                .then((valid) => {
                    if (valid) {
                        setWitness(data);
                    } else {
                        MySwal.showValidationMessage(`Fill in all required fields!`)
                        return false;
                    }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {

    })
}

export const handleEditIncidentWitness = ({ current, setWitness }: { current: PropsWitness, setWitness: (data: PropsWitness) => void }) => {
    const MySwal = withReactContent(Swal)
    const schema = yup.object().shape({
        name: yup.string().required(),
    });

    MySwal.fire({
        title: <p>Edit Witness</p>,
        html: <IncidentWitnessForm current={current} inputID="addIncidentWitnessFormJSON" />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        // width: 800,
        customClass: {
            htmlContainer: '!z-auto !overflow-visible '
        },
        preConfirm: () => {
            const data: PropsWitness = JSON.parse((document.getElementById("addIncidentWitnessFormJSON") as HTMLInputElement).value);
            return schema
                .isValid({
                    name: data.name,
                })
                .then((valid) => {
                    if (valid) {
                        setWitness(data);
                    } else {
                        MySwal.showValidationMessage(`Fill in all required fields!`)
                        return false;
                    }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {

    })
}




export const handleDownloadIncidentFile = ({ incidentID, filename, cb }: { incidentID: string, filename: string, cb?: Function }) => {
    console.log(filename);
    axios.get(`${urls.remoteURL}incident/${incidentID}/download`, { responseType: 'blob' })
        .then(({ data }) => {
            fileDownload(data, filename || 'download')
            if (typeof cb === 'function') {
                cb();
            }
        })
        .catch(async error => {
            const responseObj = JSON.parse(await error.response.data.text());
            myToast({
                title: 'Error',
                message: responseObj.message,
                icon: faFileUpload,
                colour: 'red', timing: 5
            });
        });
}




export const handleSignIncident = ({ current, setSign }: { current: string, setSign: (data: string) => void }) => {
    const MySwal = withReactContent(Swal)
    let signRef: any = null;
    const updateSignRef = (update: any) => { signRef = update; }

    const SignForm = () => {
        const ref = useRef(null);
        updateSignRef(ref);
        return (
            <div className="border rounded">
                <SignaturePad ref={ref} height={250} />
            </div>
        )
    }

    MySwal.fire({
        title: <p>Sign here...</p>,
        html: <SignForm />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        width: 800,
        customClass: {
            htmlContainer: '!z-auto !overflow-visible '
        },
        preConfirm: () => {
            // const data: PropsWitness = JSON.parse((document.getElementById("addIncidentWitnessFormJSON") as HTMLInputElement).value);
            if (signRef.current.isEmpty()) {
                MySwal.showValidationMessage(`Please sign!`)
                return false;
            }
            setSign(signRef.current.toDataURL());
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {

    })
}



export const handleDeleteIncident = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this accident, this is perminant!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete accident!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}incident`, { data: [id] })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Deleted', colour: 'green', icon: faThumbsUp })
                })
                .catch((error) => {
                    myToast({ title: 'Error', 'message': error.response.data.message || 'Error deleting accident', colour: 'red', icon: faBiohazard })
                });

        }
    })
}

