import React, { useEffect, useState } from "react";
import { USERTYPES } from "../../common";
import Label from "../../components/form/Label";
import classNames from "classnames";
import Input from "../../components/form/Input";
import { HeadlessUIRadioGroup } from "../../components/form/RadioGroupButton";

type PropsForm = { name: string, admin?: string };

const LocationAddForm = ({ inputID, userType, updated }: { inputID: string, userType?: boolean, updated?: (location: PropsForm) => void }) => {
    const [json, setJSON] = useState({});
    const [name, setName] = useState('');
    const [admin, setAdmin] = useState(USERTYPES[0].id);

    useEffect(() => {
        if (typeof updated === 'function') { updated({ name, admin }) }

        setJSON({ name, admin });
    }, [name, admin])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <div >
                <Label className={classNames("block mb-2")} required htmlFor="name">
                    Name
                </Label>
                <Input
                    placeholder="Location"
                    onChange={(e) => setName(e.target.value)}
                />
            </div>



            {userType &&
                <div>
                    <Label className={classNames("block mb-2",)} required >
                        User Type
                    </Label>
                    <HeadlessUIRadioGroup value={admin} onChange={setAdmin} options={USERTYPES} />
                </div>
            }

            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    );
}

export default LocationAddForm;