import React from 'react';
import Error from '../Errors';
import {
    Routes,
    Route,
    useParams,
} from 'react-router-dom';
import RequireAuthPage from '../../common/RequireAuthPage';
import CompanyBreadcrumbs from '../Company/Breadcrumbs';
import { CategoryProvider } from '../../context/CategoryProvider';
import CategoryForm from './Form';

function CategoryPage() {
    let { categoryID } = useParams();

    return (
        <>
            <CategoryProvider id={categoryID as string}>
                <Routes>
                    <Route errorElement={<Error fullscreen={false} />}>
                        <Route path={'edit'} element={<RequireAuthPage permissions='edit_categories' redirect='..'><CategoryForm MenuElement={<CompanyBreadcrumbs />} /></RequireAuthPage>} />

                        <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />

                    </Route>
                </Routes>
            </CategoryProvider>
        </>
    );
}

export default CategoryPage;