import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC, SelectHTMLAttributes, forwardRef } from "react";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

interface PropsSelect extends SelectHTMLAttributes<HTMLSelectElement> {
  invalid?: boolean,
  className?: string,
}

const CustomSelect: FC<PropsSelect> = forwardRef<HTMLSelectElement, PropsSelect>(({
  id,
  name,
  className = '',
  value,
  invalid,
  ...props
}, ref) => {

  return (
    <div className="custom-select">
      <select
        id={id}
        name={name}
        ref={ref}
        className={classNames(
          "form-control",
          { "is-invalid": invalid },
          className
        )}
        value={value}
        {...props}
      >
        {props.children}
      </select>
    </div>
  );
});

export default CustomSelect;


export const CustomSelectControl = (props: UseControllerProps<FieldValues, string> & PropsSelect) => {
  const { invalid, className } = props;
  const {
    field: { value, onChange }
  } = useController(props);


  return (
    <div className="custom-select">
      <select
        value={value} onChange={onChange}
        className={classNames(
          "form-control",
          { "is-invalid": invalid },
          className
        )}
        {...props}
      >
        {props.children}
      </select>
    </div>
  );
}

