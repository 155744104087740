import React, { useEffect, useState } from "react";
import axios from "axios";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

import { urls } from "../../common";
import LoadingDots from "../../components/LoadingDots";
import CustomSelect from "../../components/form/CustomSelect";

type PropsList = {
    value: string,
    label: string,
    default_option: boolean,
    parentID: string,
}

const recursiveParents = (data: PropsList[], id: string, names: string[] = []): string[] => {
    const parent = data.find(x => x.value === id);
    if (!parent) return names;
    names.push('-');
    return recursiveParents(data, parent.parentID, names);
}

// Recursive function to build options
const buildOptions = (data: PropsList[], parentID: string | null = null): any => {

    return data.filter(x => (!parentID && !x.parentID) || x.parentID === parentID).map((option: PropsList) => {
        const children = data.filter(x => x.parentID === option.value);
        const parents = recursiveParents(data, option.parentID);

        if (children.length > 0) {
            return (
                <>
                    <option key={option.value} value={option.value}>
                        {parents.length > 0 && (parents.join('') + ' ')}{option.label}
                    </option>
                    {buildOptions(data, option.value)}
                </>
            )
        }
        return (
            <option key={option.value} value={option.value}>
                {parents.length > 0 && (parents.join('') + ' ')}{option.label}
            </option>
        );

    });
};



const FolderDropdownSelectControl = (props: UseControllerProps<FieldValues, string> & { what?: 'all' | 'rhino' | 'company', defaultOption?: boolean }) => {
    const {
        field: { value, onChange }
    } = useController(props);

    const fetchURL = `${urls.remoteURL}get-document-folders`;
    const params = { type: 'select', what: props?.what || 'rhino' };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    useEffect(() => {
        if (props.defaultOption && !value && !!data) {
            onChange(data?.find(x => x.default_option)?.value as string)
        }
    }, [data])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    if (!data) {
        return <></>;
    }

    return (
        <CustomSelect
            value={value} onChange={onChange}
        >
            <option value="">{!value ? `Select Folder` : `No Folder`}</option>
            {buildOptions(data)}
        </CustomSelect>
    )
}

export default FolderDropdownSelectControl;


export const FolderDropdownSelect = ({ value, onChange, what, defaultOption }: { value: string, onChange: React.Dispatch<React.SetStateAction<string>>, what?: 'all' | 'rhino' | 'company', defaultOption?: boolean }) => {

    const fetchURL = `${urls.remoteURL}get-document-folders`;
    const params = { type: 'select', what: what || 'rhino' };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    useEffect(() => {
        if (defaultOption && !value && !!data) {
            onChange(data?.find(x => x.default_option)?.value as string)
        }
    }, [data])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    if (!data) {
        return <></>;
    }

    return (
        <CustomSelect
            value={value} onChange={(e) => onChange(e.target.value)}
        >
            <option value=''>{!value ? `Select Folder` : `No Folder`}</option>
            {buildOptions(data)}
        </CustomSelect>
    )
}