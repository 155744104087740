import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSecret } from "@fortawesome/pro-solid-svg-icons";

interface PropsAvatar {
  size?: "small" | "medium" | "large",
  status?: "primary" | "secondary" | "success" | "danger" | "warning" | "info",
  withShadow?: boolean,
  className?: string,
  children?: any,
  impersonator?: boolean,
}

const Avatar = (props: PropsAvatar) => {
  const { size, status, withShadow, className, children, impersonator } = props;

  return (
    <span
      className={classNames(
        "avatar",
        {
          "w-16 h-16 text-2xl": size === "medium",
          "w-20 h-20 text-4xl": size === "large",
          "avatar_with-shadow": withShadow,
        },
        className
      )}
    >
      {status && !impersonator? (
        <span
          className={classNames("status", {
            "bg-rhinoBlue-400": status === "primary",
            "bg-lightBlue-400": status === "secondary",
            "bg-green-400": status === "success",
            "bg-red-400": status === "danger",
            "bg-orange-400": status === "warning",
            "bg-blue-400": status === "info",
          })}
        ></span>
      ) : null}
      {impersonator ? (
        <FontAwesomeIcon icon={faUserSecret} className="status !-right-1" />
      ) : null}
      {children}
    </span>
  );
};

Avatar.defaultProps = {
  arrow: true,
  status: 'primary',
};

export default Avatar;
