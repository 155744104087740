import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { fileIcon, myToast } from "../../common";
import Label from "../../components/form/Label";
import { FolderDropdownSelect } from "./FolderDropdownList";
import { faBiohazard, faTrash } from "@fortawesome/pro-solid-svg-icons";
import Button from "../../components/Button";

const DocumentBulkUploadForm = ({ inputID, setFiles, folderID }: { inputID: string, setFiles: (files: File[] | null) => void, folderID?: string }) => {
    const [json, setJSON] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const maxSize = 20_000_000;
    const [folder_id, setFolderID] = useState<string>(folderID as string);


    const onDrop = (dropedFiles: File[]) => {
        setUploadedFiles((prevFiles) => [...prevFiles, ...dropedFiles]);
    };

    useEffect(() => { setFiles(uploadedFiles); }, [uploadedFiles])

    useEffect(() => {
        setJSON({ folder_id });
    }, [folder_id]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        fileRejections,
    } = useDropzone({
        onDrop,
        accept: {
            'image/*': [],
            'application/pdf': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/vnd.ms-excel': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
            'application/zip': [],
            'application/x-zip-compressed': [],
            'text/csv': []
        },
        multiple: true,
        maxSize,
        noClick: false,
    });

    useEffect(() => {
        if (fileRejections.length > 0) {
            myToast({
                title: 'Check File',
                message: fileRejections.length + ' file(s) had issues, please check that the file is PDF, DOC, XLS, PNG or JPG and a maxiumum of 20mb',
                timing: 5,
                icon: faBiohazard,
                colour: 'red',
                position: 'top-center'
            })
        }
    }, [fileRejections])

    return (
        <div className="space-y-4">
            <div
                {...getRootProps()}
                className={
                    classNames(
                        "flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100 ",
                        isDragActive ? `bg-gray-100` : `bg-gray-50`,
                    )
                } >
                <input {...getInputProps()} />
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    {!isDragActive ? <p className="mb-2 text-sm text-gray-500 "><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        : <p className="mb-2 text-sm text-gray-500 ">Drop your files here!</p>}
                    <p className="text-xs text-gray-500 ">PDF, DOC, XLS, PNG or JPG (MAX. 20mb)</p>
                </div>
            </div >

            <div className={
                classNames(
                    `gap-2 grid-cols-4`,
                    !uploadedFiles || uploadedFiles.length === 0 ? 'hidden' : 'grid'
                )
            }>
                {uploadedFiles.map((file, index) => {
                    return (
                        <div className={`border border-gray-300 rounded p-2 relative`} key={file.name}>
                            <div className={`h-full space-y-1 divide-y divide-gray-300 relative`}>
                                <FontAwesomeIcon icon={fileIcon(file)} size="2xl" className="text-rhinoBlue-600" />
                                <div className="text-sm truncate text-rhinoBlue-600" title={`${file.name}`}>{file.name}</div>
                            </div>
                            <Button onClick={() => {
                                setUploadedFiles(prev => ([...prev.filter((x, i) => i !== index)]))
                            }} className="absolute -top-2 -right-2 rounded-full" color="red"><FontAwesomeIcon icon={faTrash} size="sm" className="" /></Button>
                        </div>
                    );
                })}
            </div>

            <div>
                <Label className={classNames("block mb-2 text-left")}>Folder</Label>
                <FolderDropdownSelect
                    value={folder_id}
                    onChange={setFolderID}
                />
            </div>

            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </div>

    )

}

export default DocumentBulkUploadForm;