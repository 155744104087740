import React from "react";
import { IconDefinition, faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toast } from "react-hot-toast";
import { COLOURS } from "../common";
import classNames from "classnames";

/*
 * example
            toast.custom(t => <TailwindToast t={t} title="Error" icon={faXmark} bg={'red'} />,
                { position: 'top-right', duration: Infinity })
*/

const TailwindToast = ({ t, title, description, bg, icon, timing = 0 }:
    { t: Toast, title: string, description?: string, icon?: IconDefinition, bg?: COLOURS, timing?: number }) => {
    if (timing > 0) {
        setTimeout(() => toast.dismiss(t.id), timing * 1000)
    }

    return (
        <div
            className={`${t.visible ? 'animate-enter' : 'animate-leave'
                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5`}
        >
            <div className="flex">
                <div className="flex-1 w-0 p-4">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 pt-0.5">
                            <div className={
                                classNames(
                                    `h-10 w-10 rounded-full flex justify-around items-center`,
                                    {
                                        'bg-green-600': bg === 'green',
                                        'bg-red-700': bg === 'red',
                                        'bg-orange-700': bg === 'orange',
                                        'bg-gray-600': !bg
                                    })}
                            >
                                <FontAwesomeIcon
                                    className="text-white"
                                    icon={icon || faQuestion}
                                    size="lg"
                                    fixedWidth
                                />
                            </div>
                        </div>
                        <div className="ml-3 flex-1">
                            <p className="text-sm font-medium text-gray-900">
                                {title}
                            </p>
                            {!!description && <p className="mt-1 text-sm text-gray-500">
                                {description}
                            </p>}
                        </div>
                    </div>
                </div>
                <div className="flex border-l border-gray-200">
                    <button
                        onClick={() => toast.dismiss(t.id)}
                        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-rhinoBlue-600 hover:text-rhinoBlue-500 focus:outline-none focus:ring-2 focus:ring-rhinoBlue-500"
                    >
                        Close
                    </button>
                </div>
            </div>
            {
                timing > 0 &&
                <div className="progress-bar h-2 w-full bg-gray-300 rounded-b-lg">
                    <div className={`progress-bar-inner h-2 bg-blue-500 rounded-b-lg progress ${timing === 5 ? `animate-progress5` :
                        timing === 4 ? `animate-progress4` :
                            timing === 3 ? `animate-progress3` :
                                timing === 2 ? `animate-progress2` :
                                    ``} `}></div>
                </div>
            }
        </div >
    )
}

export default TailwindToast