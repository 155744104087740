import React from "react";
import Swal from "sweetalert2";


import { ITEMTYPES, myToast, urls } from "../../common";

import { icon } from "@fortawesome/fontawesome-svg-core";
import { faArchive, faBiohazard, faMessageDots, faPaperPlane, faPencil, faTrashAlt, faTrashUndo } from "@fortawesome/pro-solid-svg-icons";

import { PropsCompliance } from "./types";
import { PropsMenu } from "../../components/layout/SideBar";
import axios from "axios";
import { PropsCompany } from "../Company/types";
import { _now } from "../../common/dates";

export const getComplianceMenuTypes = (compliance: PropsCompliance, company: PropsCompany): PropsMenu[] => {
    if (!compliance) { return []; }


    // { id: 'companies-compliance-edit', name: 'Edit', to: `companies/${global.company?.id}/compliance/${global.compliance?.id}/edit`, icon: faPencil, hidden: !global.compliance, },
    return (
        [
            {
                id: 'companies-compliance-edit',
                name: `Edit ${compliance.name}`,
                to: `compliance/${compliance.id}/edit`,
                icon: faPencil,
                hidden: !compliance || !company.edit_items,
                closeMenu: false,
            },
            ...ITEMTYPES.filter(x => x.permanent || compliance.options?.includes(x.id)).map(x => {
                return {
                    id: 'companies-compliance-' + x.link,
                    name: x.label, to: `compliance/${compliance.id}/${x.link}`,
                    icon: x.icon,
                    hidden: !compliance,
                    closeMenu: false,
                };
            }),
            // { name: 'Options', to: `items/${params[2]}/files`, current: params[3] === 'files', icon: FontAwesomeIcon, faIcon: faFiles },
            {
                id: 'companies-compliance-logs',
                name: 'Logs', to: `compliance/${compliance.id}/logs`,
                icon: faMessageDots,
                hidden: !compliance,
                closeMenu: false,
            },
        ]
    )
}




export const handleComplianceSendEmail = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send email!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.post(`${urls.remoteURL}item/${id}/email`)
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Email Sent!', colour: 'green', icon: faPaperPlane, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error sending notification email?', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}


export const handleComplianceSendBulletinEmail = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send bulletin to all people!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.post(`${urls.remoteURL}item/${id}/send-bulletin`)
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Bulletin Sent!', message: 'Bulletin will be sent to people shortly', colour: 'green', icon: faPaperPlane, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error sending bulletin email?', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}





export const handleArchiveCompliance = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        iconHtml: icon(faArchive).html.join(''),
        text: 'Archiving this compliance item will remove from all reporting and charts.',
        customClass: {
            icon: 'border-0 text-red-600'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, archive Compliance Item!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.put(`${urls.remoteURL}item/${id}`, { archived: true })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Archived!', colour: 'green', icon: faTrashAlt, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error archiving Compliance?', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}

export const handleUndoArchiveCompliance = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        iconHtml: icon(faArchive).html.join(''),
        text: 'Undoing this compliance item archive will add back it into all reporting and charts.',
        customClass: {
            icon: 'border-0 text-red-600'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, archive Compliance Item!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.put(`${urls.remoteURL}item/${id}`, { archived: false })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Archived!', colour: 'green', icon: faTrashAlt, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error archiving Compliance?', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}


export const handleDeleteCompliance = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        iconHtml: icon(faTrashAlt).html.join(''),
        text: 'Deleting this compliance item is permanent.',
        customClass: {
            icon: 'border-0 text-red-600'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete Compliance Item!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}item/${id}`)
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Deleted!', colour: 'green', icon: faTrashAlt, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error deleting Compliance?', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}




export const handleArchiveComplianceOption = (id: string, label: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        iconHtml: icon(faArchive).html.join(''),
        text: 'Archiving this will remove from all compliance checks.',
        customClass: {
            icon: 'border-0 text-red-600'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, archive ${label}!`
    }).then((result) => {
        if (result.isConfirmed) {
            axios.put(`${urls.remoteURL}item/option/${id}`, { archived: _now() })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Archived', colour: 'green', icon: faTrashAlt, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error Archiving Compliance Option', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}


export const handleUndoArchiveComplianceOption = (id: string, label: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        iconHtml: icon(faTrashUndo).html.join(''),
        text: 'Undoing this archive will add back into all compliance checks.',
        customClass: {
            icon: 'border-0 text-green-600'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, undo archive!`
    }).then((result) => {
        if (result.isConfirmed) {
            axios.put(`${urls.remoteURL}item/option/${id}`, { archived: 0 })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Archive Undone', colour: 'green', icon: faTrashAlt, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error Archiving Compliance Option', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}



export const handleDeleteComplianceOption = (id: string, label: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, delete ${label}!`
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}item/option/${id}`)
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Deleted', colour: 'green', icon: faTrashAlt, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error Deleting Compliance Option', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}

export const handleBulkDeleteComplianceOptions = (ids: string[], label: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete all selected ${label}, this is perminant!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, delete ${label}!`,
        focusConfirm: false,
        focusCancel: true,
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}item/options`, { data: ids })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({ title: 'Deleted', colour: 'green', icon: faTrashAlt, timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'Error Deleting Compliance Option', colour: 'red', icon: faBiohazard, timing: 3 });
                });

        }
    })
}