import React, { useEffect, useMemo, useState } from "react";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import '@tanstack/table-core';

import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfo, faPaperPlane, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { PropsCategory } from "../Categories/types";
import { ITEMTYPES, urls } from "../../common";
import { statusColour, statusDateColour } from "../../common/colours";
import { formatDate } from "../../common/dates";
import LoadingDots from "../../components/LoadingDots";
import Table from "../../components/Table";
import { useCompany } from "../../context/companyProvider";
import Tooltip from "../../components/Tooltip";
import Button from "../../components/Button";
import { handleComplianceSendBulletinEmail } from "../Compliance/common";

type TableProps = {
    id: string,
    name: string,
    category: PropsCategory,
    site: { name: string }
    renewal: { date_expire: number }
    status: {
        status: string,
        renewal: { time: number, status: string },
        certificate: { status: string, count: number, failed: number, upcoming: number, passed: number },
        assets: { status: string, count: number, failed: number, upcoming: number, passed: number },
    },
    type: string,
    list_order: number,
    options: string[],
}

const DashboardItemsListPage = ({ categoryID, siteID, sitesOnly }: { categoryID?: string, siteID?: string, sitesOnly?: boolean }) => {
    const { company } = useCompany();

    const fetchURL = `${urls.remoteURL}reporting/items`;
    const [rowsData, setRowsData] = useState<TableProps[]>();

    const { isLoading, isError, data, refetch } = useQuery(`dashbaord-category-items-` + [siteID, categoryID, sitesOnly ? 'sitesOnly' : ''].join('-'),
        () => axios.get(fetchURL, { params: { siteID, categoryID, sitesOnly } }));


    const siteRows = useMemo(() => {
        return isLoading === false && rowsData ? Object.values(rowsData) : [];
    }, [rowsData]);

    useEffect(() => {
        if (!isLoading && !isError) {
            setRowsData(data ? Object.values(data?.data) : []);
        }
    }, [data?.data]);


    if (isError) {
        throw { code: 500, message: 'Error getting compliance items?' };
    }

    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
            meta: {
                className: 'md:w-1/4',
            }
        }),
        // columnHelper.accessor('type', {
        //     cell: info => ITEMTYPES.filter(x => x.id === info.getValue())[0].label,
        //     header: info => 'Type',
        //     footer: info => 'Type',
        //     sortingFn: 'alphanumeric',
        // }),
        columnHelper.accessor('category', {
            cell: info => info.getValue()?.name || 'n/a',
            header: 'Category',
            footer: 'Category',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('site.name', {
            cell: info => info.getValue() ? info.getValue() : 'n/a',
            header: 'Location',
            footer: 'Location',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('renewal.date_expire', {
            cell: info => <span
                className={`${statusDateColour(info.getValue())} px-2 py-1 text-white rounded-full`}>{info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a'}</span>,
            header: 'Renewal',
            footer: 'Renewal',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('status', {
            cell: info => rowStatus(info),
            header: 'Status',
            footer: 'Status',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('options', {
            header: 'Options',
            footer: 'Options',
            cell: info => optionsCell(info.row.original),
            enableSorting: false,
            enableGlobalFilter: false,
        }),

        ... !!company.edit_items ? [
            columnHelper.accessor('id', {
                header: 'Actions',
                footer: 'Actions',
                cell: info => actionCell(info),
                enableSorting: false,
                enableGlobalFilter: false,
                meta: {
                    className: 'w-28 text-center',
                    thClassName: 'justify-center',
                }
            }),
        ] : []

    ];

    const optionsCell = (options: TableProps) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                {ITEMTYPES.filter(x => x.permanent || options.options.includes(x.id)).map(type => {
                    return (
                        <Tooltip content={`${type.label}`}>
                            <Link to={`/compliance/${options.id}/${type.link}`} className={`btn ${type.buttonColour}`}><span className="sr-only">{type.label}</span><FontAwesomeIcon icon={type.icon} fixedWidth /></Link>
                        </Tooltip>
                    )
                })}
            </div>
        )
    }



    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="block sm:flex gap-1 justify-center">
                <Tooltip content={'Edit Item'}>
                    <Link
                        className="btn btn-success"
                        to={`/compliance/${info.getValue()}/edit`}
                    ><span className="sr-only">Edit Item</span><FontAwesomeIcon icon={faPencil} fixedWidth /></Link>
                </Tooltip>
                {info.row.original.options.includes('BULLETIN') && info.row.original.options.includes('PEOPLE') &&
                    <Tooltip content="Send Bulletin">
                        <Button
                            type="button" color="orange"
                            onClick={() => { handleComplianceSendBulletinEmail(info.getValue(), refetch) }}
                        ><span className="sr-only">Send Bulletin</span><FontAwesomeIcon icon={faPaperPlane} fixedWidth /></Button>
                    </Tooltip>
                }


            </div>
        )
    }

    const rowStatus = (info: CellContext<TableProps, any>) => {
        // if (!info.getValue().certificates && !info.getValue().assets) {
        return (
            <>
                {
                    info.getValue().status === 'passed' ? <span className={`bg-green-500 px-2 py-2 text-white rounded-full`} ><FontAwesomeIcon icon={faCheck} fixedWidth /></span> :
                        <Tippy content={info.getValue().notes}>
                            <span className={
                                `${statusColour(info.getValue().status)} px-2 py-2 text-white rounded-full cursor-pointer`} ><FontAwesomeIcon icon={faInfo} fixedWidth /></span>
                        </Tippy>
                }
            </>
        )
        // }

        if (info.getValue().certificates) {
            return (
                <>
                    {
                        info.getValue().certificates.status === 'passed' ? <span className={`bg-green-500 px-2 py-2 text-white rounded-full`} ><FontAwesomeIcon icon={faCheck} fixedWidth /></span> :
                            <Tippy content={info.getValue().certificates.notes}>
                                <span className={
                                    `${statusColour(info.getValue().certificates.status)} px-2 py-2 text-white rounded-full cursor-pointer`} ><FontAwesomeIcon icon={faInfo} fixedWidth /></span>
                            </Tippy>
                    }
                </>
            )
        }

        if (info.getValue().assets) {
            return (
                <>
                    {
                        info.getValue().assets.status === 'passed' ? <span className={`bg-green-500 px-2 py-2 text-white rounded-full`} ><FontAwesomeIcon icon={faCheck} fixedWidth /></span> :
                            <Tippy content={info.getValue().assets.notes}>
                                <span className={
                                    `${statusColour(info.getValue().assets.status)} px-2 py-2 text-white rounded-full cursor-pointer`} ><FontAwesomeIcon icon={faInfo} fixedWidth /></span>
                            </Tippy>
                    }
                </>
            )
        }

    }

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }


    return (
        <>
            <h3 className="py-4">Compliance Items</h3>
            <div className="">
                <Table
                    columns={columns}
                    defaultData={siteRows}
                />
            </div>

        </>
    )
}

export default DashboardItemsListPage;
