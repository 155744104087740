import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";

import { myToast, urls } from "../../common";
import { PropsLocation } from './types';
import { faAddressBook, faBiohazard, faEarthEurope, faMapLocation, faMapPin, faSave, faTreeCity } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../components/form/Label';
import classNames from 'classnames';
import Input from '../../components/form/Input';
import { useLocation } from '../../context/LocationProvider';
import SwitchControl from '../../components/form/Switch';
import Button from '../../components/Button';

function LocationForm({ MenuElement }: { MenuElement?: JSX.Element }) {

    const { location, setLocation } = useLocation();

    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
        });


    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<PropsLocation>({
        resolver: yupResolver(schema),
    });


    const submit = (data: PropsLocation) => {

        axios.put(`${urls.remoteURL}site/${location?.id}`, data).then(res => {
            setLocation((old) => ({
                ...old,
                ...data,
            }));
        })
            .then(() => {
                myToast({ title: 'Saved', colour: 'green', icon: faSave, timing: 3 });
            })
            .catch((error) => {
                myToast({ title: 'Error', message: error.response.data.message || error.message, colour: 'red', icon: faBiohazard, timing: 3 });
            })
    }

    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className=" my-3 sm:mt-0 sm:ml-4">
                    <Button
                        color="green"
                        type="submit"
                        className="px-6 rounded-full"
                    >
                        Save <FontAwesomeIcon icon={faSave} fixedWidth className="ml-2" />
                    </Button>
                </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4 mb-4">
                <div className="card p-5 space-y-4">
                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.name })} required htmlFor="firstname">
                            Name
                        </Label>
                        <Input
                            {...register('name')}
                            placeholder="Location"
                            defaultValue={location?.name}
                            className={classNames({ 'invalid': errors.name })} />
                    </div>

                    <div className="flex items-center">
                        <div className="flex-1">
                            <Label className={classNames("block")} htmlFor="firstname">
                                Active
                            </Label>
                            <small className="text-muted">  Will deactivate location and disable all users from logging in.</small>
                        </div>
                        <div className="">
                            <SwitchControl
                                name="active"
                                defaultValue={!!location?.active as unknown as string}
                                control={control}
                            />
                        </div>
                    </div>

                </div>

                <div className="card p-5 space-y-4">

                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="address">
                            Address
                        </Label>
                        <div className="space-y-1">
                            <div className="form-control-addon-within focus-within">
                                <span className="p-2 pr-0">
                                    <FontAwesomeIcon icon={faAddressBook} fixedWidth />
                                </span>
                                <Input
                                    {...register('address')}
                                    className="border-none"
                                    defaultValue={location?.address}
                                />
                            </div>
                            <div className="form-control-addon-within focus-within">
                                <span className="p-2 w-7">

                                </span>
                                <Input
                                    {...register('address_line_2')}
                                    className="border-none"
                                    defaultValue={location?.address_line_2}
                                />
                            </div>

                            <div className="form-control-addon-within focus-within">
                                <span className="p-2  w-7">

                                </span>
                                <Input
                                    {...register('address_line_3')}
                                    className="border-none"
                                    defaultValue={location?.address_line_3}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="email">
                            Town/City
                        </Label>
                        <div className="form-control-addon-within focus-within">
                            <span className="p-2 pr-0">
                                <FontAwesomeIcon icon={faTreeCity} fixedWidth />
                            </span>
                            <Input
                                {...register('town')}
                                className="border-none"
                                defaultValue={location?.town}
                            />
                        </div>
                    </div>
                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="email">
                            Region
                        </Label>
                        <div className="form-control-addon-within focus-within">
                            <span className="p-2 pr-0">
                                <FontAwesomeIcon icon={faMapLocation} fixedWidth />
                            </span>
                            <Input
                                {...register('region')}
                                className="border-none"
                                defaultValue={location?.region}
                            />
                        </div>
                    </div>
                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="email">
                            Postcode
                        </Label>
                        <div className="form-control-addon-within focus-within">
                            <span className="p-2 pr-0">
                                <FontAwesomeIcon icon={faMapPin} fixedWidth />
                            </span>
                            <Input
                                {...register('postcode')}
                                className="border-none"
                                defaultValue={location?.postcode}
                            />
                        </div>
                    </div>
                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="email">
                            Country
                        </Label>
                        <div className="form-control-addon-within focus-within">
                            <span className="p-2 pr-0">
                                <FontAwesomeIcon icon={faEarthEurope} fixedWidth />
                            </span>
                            <Input
                                {...register('country')}
                                className="border-none"
                                defaultValue={location?.country}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </form>
    )

}

export default LocationForm;