import { FC, InputHTMLAttributes, forwardRef } from "react";
import classNames from "classnames";

interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean,
  className?: string,
}


const Input: FC<PropsInput> = forwardRef<HTMLInputElement, PropsInput>(({
  id,
  name,
  type = 'text',
  className = '',
  placeholder,
  value,
  ...props
}, ref) => {

  return (
    <input
      id={id}
      ref={ref}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      className={classNames(
        "form-control",
        className
      )}
      {...props}
    />
  );
}
);


export default Input;
