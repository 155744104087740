import React, { useEffect, useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import { myToast, urls } from "../../../../common";
import ComplianceOptionBulletinAddForm from "./AddForm";
import { faFileUpload } from "@fortawesome/pro-solid-svg-icons";
import { PropsCompliance } from "../../types";

export const handleAddComplianceBulletin = (compliance: PropsCompliance, refetch: Function) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
        title: <p>Add Bulletin Point</p>,
        html: <ComplianceOptionBulletinAddForm compliance={compliance} inputID="addBulletinFormJSON" />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        width: 900,
        customClass: {
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            MySwal.resetValidationMessage();

            const data = JSON.parse((document.getElementById("addBulletinFormJSON") as HTMLInputElement).value);

            return axios.post(`${urls.remoteURL}item/option/${compliance.id}/bulletin`, data).then((x) => {
                return true;
            }).catch((err) => {
                MySwal.showValidationMessage(
                    err.response.data.message ?? `Problem Saving bulletin`
                )
                return false;
            });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faFileUpload, timing: 3 })
            refetch();
        }
    })
}