import React, { useEffect, useState } from "react";
import Label from "../../../../components/form/Label";

import { PropsCompliance } from "../../types";
import { PropsPeople } from "../../../People/types";
import Tabs, { TabsContent, TabsContentItem, TabsNavigation, TabsNavigationItem } from "../../../../components/Tabs";
import PersonAddForm from "../../../People/AddForm";
import PeopleSelectionList from "../../../People/SelectionList";

type formProps = {
    tab: string,
    users?: string[],
    newUser?: PropsPeople,
}

const ComplianceOptionRenewalAddForm = ({ compliance, inputID }: { compliance: PropsCompliance, inputID: string }) => {
    const [tab, setTab] = useState(0);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [newUser, setNewUser] = useState<PropsPeople>();

    const tabs = [
        { name: 'Add New', tab: 'new' },
        { name: 'Add Current', tab: 'current' },
    ];

    const [json, setJSON] = useState<formProps>({ tab: tabs[0].tab });

    useEffect(() => {
        setJSON({ tab: tabs[tab].tab, users: selectedRows, newUser });
    }, [tab, selectedRows, newUser])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <Tabs activeIndex={0} onActiveIndexChange={(i) => setTab(i)} className="mt-5">
                <TabsNavigation>
                    {tabs.map((tab, index) =>
                        <TabsNavigationItem key={`tab_${tab.tab}`} index={index} className="">
                            {tab.name}
                        </TabsNavigationItem>
                    )}
                </TabsNavigation>
                <TabsContent>
                    <TabsContentItem index={0}>
                        <PersonAddForm inputID="addNewPersonFormJSON" userType={true} updated={setNewUser} />
                    </TabsContentItem>
                    <TabsContentItem index={1}>
                        <PeopleSelectionList
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            userType={'all'}
                            disabled={compliance?.item_options?.filter(x => x.element === 'PEOPLE').map(person => person.value.user_id) as string[]}
                        />
                    </TabsContentItem>

                </TabsContent>
            </Tabs>


            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    );
}

export default ComplianceOptionRenewalAddForm;