import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";

import { USERTYPES, myToast, urls } from "../../common";
import { PropsPeople } from './types';
import { usePerson } from '../../context/PeopleProvider';
import { faBiohazard, faSave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../components/form/Label';
import classNames from 'classnames';
import Input from '../../components/form/Input';
import SwitchControl from '../../components/form/Switch';
import HeadlessUIRadioGroupControl from '../../components/form/RadioGroupButton';
import Button from '../../components/Button';
import isEmailValid from '../../common/isEmail';

function PersonForm({ MenuElement }: { MenuElement?: JSX.Element }) {
    const { person, setPerson } = usePerson();

    const schema = yup
        .object()
        .shape({
            firstname: yup.string().required(),
            lastname: yup.string().required(),
            email: yup.string().email(),
            _password: yup.string()
                .trim()
                .nullable()
                // .transform((curr, orig) => (orig === "" ? null : curr))
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    {
                        message: "Must contain 8 characters, one uppercase, one lowercase, one number, and one special character",
                        excludeEmptyString: true,
                    }),

            confirm_password: yup.string().when('_password', {
                is: (password: any, schema: any) => password && password.length > 0,
                then: yup
                    .string()
                    .required('Please confirm password')
                    .oneOf([yup.ref('_password')], 'Passwords do not match'),
            }),

        });

    type FormProps = Omit<PropsPeople, 'admin'> & { _password?: string, confirm_password?: string, admin?: string };



    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<FormProps>({
        resolver: yupResolver(schema),
    });

    const admin = useWatch({ name: 'admin', control, defaultValue: person?.admin ? 'admin' : person?.site_admin ? 'site_admin' : 'staff' });

    const submit = (data: FormProps) => {
        if (data._password && data._password.length > 0) {
            data.password = data._password;
            delete data._password;
            delete data.confirm_password;
        }

        axios.put(`${urls.remoteURL}user/${person?.id}`, data).then(res => {
            setPerson((old) => ({
                ...old,
                ...data,
                admin: data.admin as unknown as string === 'admin',
                site_admin: data.admin as unknown as string === 'site_admin',
            }));
        })
            .then(() => {
                setValue('_password', '');
                setValue('confirm_password', '');
                myToast({ title: 'Saved', colour: 'green', icon: faSave, timing: 3 });
            })
            .catch((error) => {
                myToast({ title: 'Error', message: error.response.data.message || error.message, colour: 'red', icon: faBiohazard, timing: 3 });
            })
    }

    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className=" my-3 sm:mt-0 sm:ml-4">
                    <Button
                        type="submit"
                        color="green"
                        className="rounded-full px-6"
                    >
                        Save <FontAwesomeIcon icon={faSave} fixedWidth className="ml-2" />
                    </Button>
                </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4 mb-4">
                <div className="card p-5 space-y-2">
                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.firstname })} required htmlFor="firstname">
                            Name
                        </Label>
                        <div className="grid grid-cols-2 gap-2">
                            <Input
                                {...register('firstname')}
                                placeholder="John"
                                defaultValue={person?.firstname}
                                className={classNames({ 'invalid': errors.firstname })}
                                autoComplete="off"
                            />
                            <Input
                                {...register('lastname')}
                                placeholder="Doh"
                                defaultValue={person?.lastname}
                                className={classNames({ 'invalid': errors.lastname })}
                                autoComplete="off"
                            />
                        </div>
                    </div>

                    <div>
                        <Label className={classNames("block mb-2",)} >
                            Position
                        </Label>
                        <Input
                            {...register('position')}
                            placeholder="Administrator"
                            defaultValue={person?.position}
                        />
                    </div>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.email })} required={admin !== 'staff'} htmlFor="email">
                            Email
                        </Label>
                        <Input
                            {...register('email')}
                            required={admin !== 'staff'}
                            placeholder="email@domain.com"
                            defaultValue={isEmailValid(person?.email as string) ? person?.email : ''}
                            className={classNames({ 'invalid': errors.email })}
                            autoComplete="off"
                        />
                    </div>

                    <h3 className="mt-4 border-b">Security</h3>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors._password })} htmlFor="email">
                            Password
                        </Label>
                        <Input
                            type="password"
                            autoComplete="new-password"
                            {...register('_password')}
                            placeholder="***************"
                            className={classNames({ 'invalid': errors._password })} />
                        <small className="text-red-700">{errors._password?.message}</small>
                    </div>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.confirm_password })} htmlFor="email">
                            Confirm Password
                        </Label>
                        <Input
                            type="password"
                            autoComplete="new-password"
                            {...register('confirm_password')}
                            placeholder="***************"
                            className={classNames({ 'invalid': errors.confirm_password })} />
                        <small className="text-red-700">{errors.confirm_password?.message}</small>
                    </div>

                </div>

                <div className="card p-5 space-y-2">
                    <div className="flex items-center">
                        <div className="flex-1">
                            <Label className={classNames("block")} htmlFor="firstname">
                                Active
                            </Label>
                            <small className="text-muted">Will deactivate company user and disable logging in.</small>
                        </div>
                        <div className="">
                            <SwitchControl
                                name="active"
                                defaultValue={!!person?.active as unknown as string}
                                control={control}
                            />
                        </div>
                    </div>

                    <div>
                        <Label className={classNames("block mb-2",)} required >
                            User Type
                        </Label>
                        <HeadlessUIRadioGroupControl
                            name="admin"
                            defaultValue={person?.admin ? 'admin' : person?.site_admin ? 'site_admin' : 'staff'}
                            options={USERTYPES}
                            control={control} />
                    </div>
                </div>


            </div>

        </form>
    )

}

export default PersonForm;