import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios from 'axios';
import { PropsCompany } from '../Pages/Company/types';
import LoadingDots from '../components/LoadingDots';
import { useUser } from './userProvider';


interface companyContext {
  company: PropsCompany;
  setCompany: React.Dispatch<React.SetStateAction<PropsCompany>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyContext = createContext<companyContext>({} as companyContext);


export const CompanyProvider = ({ children }: { children: any }) => {
  const { token, user } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<PropsCompany>({} as PropsCompany);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (token && user) {
      setLoading(true);
      axios.get(`${urls.remoteURL}get-company`)
        .then(({ data }) => {
          setCompany({ ...data });
        })
        .catch((e) => {
          setCompany({} as PropsCompany);
          throw { code: 404, message: 'Error getting company?' }
        })
        .finally(() => {
          setLoading(false)
        });
    }
  }, [refresh, user]);

  return (
    <CompanyContext.Provider value={{ company, setCompany, setRefresh }}>
      {loading ? <LoadingDots /> : children}
    </CompanyContext.Provider>
  );
}

export const useCompany = () => useContext(CompanyContext);