import { useState, createContext, useContext, useEffect } from 'react'
import { tokenID, urls } from "../common";
import axios, { AxiosError } from 'axios';
import LoadingDots from '../components/LoadingDots';
import { PropsPeople } from '../Pages/People/types';


interface userContext {
  user: PropsPeople | null;
  setUser: React.Dispatch<React.SetStateAction<PropsPeople | null>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>
}

const UserContext = createContext<userContext>({} as userContext);

const setInterceptor = (onFail: () => void) => {
  return axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        onFail();
      }
      return Promise.reject(error);
    }
  );
};


export function UserProvider({ children }: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<PropsPeople | null>(null);
  const [token, setToken] = useState<string>(window.localStorage.getItem(tokenID) || '');
  const context = { user, setUser, token, setToken };

  axios.defaults.headers.common.platform = `client`;

  useEffect(() => {
    const interceptor = setInterceptor(() => {
      setToken('');
    });
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [setToken]);

  useEffect(() => {
    if (token) {
      window.localStorage.setItem(tokenID, token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios.get(`${urls.remoteURL}me`).then(({ data }) => {
        if (data) {
          axios.defaults.headers.common.companyID = data.company_id;
          setUser(data);
        } else {
          window.localStorage.removeItem(tokenID);
          axios.defaults.headers.common.Authorization = false;
          setUser(null);
        }
      }).catch((err: AxiosError): void => {
        window.localStorage.removeItem(tokenID);
        axios.defaults.headers.common.Authorization = false;
        axios.defaults.headers.common.companyID = null;
        setUser(null);
      }).finally(() => {
        setLoading(false);
      });
    } else {
      window.localStorage.removeItem(tokenID);
      axios.defaults.headers.common.Authorization = false;
      axios.defaults.headers.common.companyID = null;
      setUser(null);
      setLoading(false);
    }
  }, [token]);

  return (
    <UserContext.Provider value={context}>
      {loading ? <LoadingDots /> : children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}