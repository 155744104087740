import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios from 'axios';
import LoadingDots from '../components/LoadingDots';
import { useGlobal } from './globals';
import { PropsCompliance } from '../Pages/Compliance/types';

interface complianceContext {
  compliance: PropsCompliance;
  setCompliance: React.Dispatch<React.SetStateAction<PropsCompliance>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const ComplianceContext = createContext<complianceContext>({} as complianceContext);


export const ComplianceProvider = ({ id, children }: { id: string, children: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [compliance, setCompliance] = useState<PropsCompliance>({} as PropsCompliance);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { setGlobal } = useGlobal();

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}get-item/${id}`)
        .then(({ data }) => {
          setGlobal(prev => ({ ...prev, compliance: data }));
          setCompliance({ ...data, id });
        })
        .catch((e) => {
          setCompliance({} as PropsCompliance);
        })
        .finally(() => {
          setLoading(false)
        });
    } else {
      setLoading(false);
      setCompliance({} as PropsCompliance);
    }
  }, [id, refresh]);

  return (
    <ComplianceContext.Provider value={{ compliance, setCompliance, setRefresh }}>
      {loading ? <LoadingDots /> : children}
    </ComplianceContext.Provider>
  );
}

export const useCompliance = () => useContext(ComplianceContext);