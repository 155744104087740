import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";

import { ITEMTYPES, myToast, urls } from "../../common";
import { PropsCompliance } from './types';
import { faBiohazard, faBoxArchive, faSave, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../components/form/Label';
import classNames from 'classnames';
import Input from '../../components/form/Input';
import TextArea from '../../components/form/Textarea';
import { useCompliance } from '../../context/ComplianceProvider';
import CategoryDropdownSelectControl from '../Categories/DropdownList';
import { useCompany } from '../../context/companyProvider';
import LocationDropdownSelectControl from '../Locations/DropdownList';
import SwitchControl, { Switch } from '../../components/form/Switch';
import PeopleDropdownSelectControl from '../People/DropdownList';
import Button from '../../components/Button';

function ComplianceForm({ MenuElement }: { MenuElement?: JSX.Element }) {
    const { company } = useCompany();
    const { compliance, setCompliance } = useCompliance();

    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
        });


    const { register, handleSubmit, formState: { errors }, control, setValue, watch } = useForm<PropsCompliance>({
        resolver: yupResolver(schema),
    });


    useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            console.log(value, name, type)
        )
        return () => subscription.unsubscribe();
    }, [watch])

    const submit = (data: PropsCompliance) => {

        axios.put(`${urls.remoteURL}item/${compliance?.id}`, { ...data, options: compliance?.options, compliant: compliance.compliant, archived: compliance?.archived, }).then(res => {
            setCompliance((old) => ({
                ...old, compliant: compliance.compliant,
                ...data, options: compliance?.options,
                archived: compliance?.archived,
            }));
        })
            .then(() => {
                myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 });
            })
            .catch((error) => {
                myToast({ title: 'Error', message: error.response.data.message || 'Error saving compliance item?', colour: 'red', icon: faBiohazard, timing: 3 });
            })
    }

    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className=" my-3 sm:mt-0 sm:ml-4">
                    <Button
                        color="green"
                        type="submit"
                        className="px-6 rounded-full"
                    >
                        Save <FontAwesomeIcon icon={faSave} fixedWidth className="ml-2" />
                    </Button>
                </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4 mb-4 ">
                <div className="card p-5 space-y-4">
                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.name })} required htmlFor="name">
                            Name
                        </Label>
                        <Input
                            {...register('name')}
                            placeholder="Location"
                            defaultValue={compliance?.name}
                            className={classNames({ 'invalid': errors.name })} />
                    </div>

                    <div>
                        <Label>Compliant</Label>
                        <Label
                            key={'compliant'} htmlFor={'compliant'}
                            className="flex rounded-lg border border-gray-300 bg-white p-4 cursor-pointer"
                        >
                            <div className="min-w-0 flex-1 text-sm leading-6"
                                onClick={() => {
                                    setCompliance(prev => ({
                                        ...prev, compliant: !prev?.compliant
                                    }))
                                }}>
                                <p id={`compliant-description`} className="text-gray-500">Fills requirments set by compliance</p>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <Switch
                                    as="div"
                                    id={'compliant'}
                                    setValue={() => {
                                        setCompliance(prev => ({
                                            ...prev, compliant: !prev?.compliant
                                        }))
                                    }}
                                    value={'compliant'}
                                    defaultChecked={!!compliance?.compliant}
                                />
                            </div>
                        </Label>
                    </div>


                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="description">
                            Description
                        </Label>
                        <TextArea
                            {...register('description')}
                            placeholder="Category Description"
                            defaultValue={compliance?.description}
                            rows={5}
                        />
                    </div>

                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="accountmanager">
                            Category
                        </Label>
                        <CategoryDropdownSelectControl
                            name={"category_id"}
                            defaultValue={compliance?.category_id}
                            control={control}
                        />
                    </div>

                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="accountmanager">
                            Location
                        </Label>
                        <LocationDropdownSelectControl
                            name={"site_id"}
                            defaultValue={compliance?.site_id}
                            control={control}
                            nested={true}
                        />
                    </div>

                    <Label
                        key={'archived'} htmlFor={'archived'}
                        className="flex rounded-lg border border-gray-300 bg-white p-4 cursor-pointer"
                    >
                        <div className="min-w-0 flex-1 text-sm leading-6"
                            onClick={() => {
                                setCompliance(prev => ({
                                    ...prev,
                                    archived: !compliance?.archived
                                }))
                            }}>
                            <div className="font-medium text-gray-900">
                                <FontAwesomeIcon icon={faBoxArchive} fixedWidth className="mr-2" />
                                Archived
                            </div>
                            <p id={`archived-description`} className="text-gray-500">Removes compliance item from all reporting and charts.</p>
                        </div>
                        <div className="ml-3 flex h-6 items-center">
                            <Switch
                                as="div"
                                id={'archived'}
                                setValue={() => {
                                    setCompliance(prev => ({
                                        ...prev, archived: !compliance?.archived
                                    }))
                                }}
                                value={'archived'}
                                defaultChecked={!!compliance?.archived}
                            />
                        </div>
                    </Label>

                </div>

                <div className="card p-5 space-y-4">
                    <div>
                        <Label className={classNames("block mb-2")} >
                            Options
                        </Label>
                        <small className="text-gray-400">Choose what additional options to add, please note removing options could have unexpected results</small>
                        <div className="grid grid-cols-2 gap-2 mt-1 ">
                            {ITEMTYPES.filter(x => !x.permanent).map((option, index) => (
                                <Label
                                    key={option.id} htmlFor={option.id}
                                    className="flex rounded-lg border border-gray-300 bg-white p-4 cursor-pointer"
                                >
                                    <div className="min-w-0 flex-1 text-sm leading-6"
                                        onClick={() => {
                                            setCompliance(prev => ({
                                                ...prev, options:
                                                    prev?.options?.includes(option.id) ? prev.options.filter(x => x !== option.id) :
                                                        [...(prev?.options || []), option.id]
                                            }))
                                        }}>
                                        <div className="font-medium text-gray-900">
                                            <FontAwesomeIcon icon={option.icon} fixedWidth className="mr-2" />
                                            {option.label}
                                        </div>
                                        <p id={`${option.id}-description`} className="text-gray-500">{option.description}</p>
                                    </div>
                                    <div className="ml-3 flex h-6 items-center">
                                        <Switch
                                            as="div"
                                            id={option.id}
                                            setValue={() => {
                                                setCompliance(prev => ({
                                                    ...prev, options:
                                                        prev?.options?.includes(option.id) ? prev.options.filter(x => x !== option.id) :
                                                            [...(prev?.options || []), option.id]
                                                }))
                                            }}
                                            value={option.id}
                                            defaultChecked={!!compliance?.options?.includes(option.id)}
                                        />
                                    </div>
                                </Label>
                            ))}
                        </div>
                    </div>

                </div>


                <div className="card p-5 space-y-4">
                    <div className="flex">
                        <div className="flex-1">
                            <Label className={classNames("block")} >
                                Notifications
                            </Label>
                            <small className="text-gray-400">Activate email notifications to user.</small>
                        </div>

                        <SwitchControl
                            name='notifications'
                            defaultValue={!!compliance?.notifications as unknown as string}
                            control={control}
                        />
                    </div>

                    <div className="">
                        <Label className={classNames("block")} >
                            Notifications Email
                        </Label>

                        <PeopleDropdownSelectControl
                            name='notification_id'
                            defaultValue={compliance?.notification_id}
                            control={control}
                        />
                    </div>

                    <div className="">
                        <Label className={classNames("block")} >
                            Custom Message
                        </Label>
                        <TextArea
                            {...register('custom_message')}
                            defaultValue={compliance?.custom_message}
                            rows={5}
                        />

                    </div>

                </div>
            </div>


        </form>
    )

}

export default ComplianceForm;