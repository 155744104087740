import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";

import { myToast, urls } from "../../common";
import { PropsCategory } from './types';
import { faBiohazard, faSave, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../components/form/Label';
import classNames from 'classnames';
import Input from '../../components/form/Input';
import TextArea from '../../components/form/Textarea';
import Button from '../../components/Button';
import { useCategory } from '../../context/CategoryProvider';

function CategoryForm({ MenuElement }: { MenuElement?: JSX.Element }) {

    const { category, setCategory } = useCategory();

    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
        });


    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<PropsCategory>({
        resolver: yupResolver(schema),
    });


    const submit = (data: PropsCategory) => {

        axios.put(`${urls.remoteURL}category/${category?.id}`, data).then(res => {
            setCategory((old) => ({
                ...old,
                ...data,
            }));
        })
            .then(() => {
                myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 });
            })
            .catch((error) => {
                myToast({ title: 'Error', message: error.response.data.message || 'Error saving category?', colour: 'red', icon: faBiohazard, timing: 3 });
            })
    }

    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className=" my-3 sm:mt-0 sm:ml-4">
                    <Button
                        color="green"
                        type="submit"
                        className="rounded-full px-6"
                    >
                        Save <FontAwesomeIcon icon={faSave} fixedWidth className="ml-2" />
                    </Button>
                </div>
            </div>

            <div className="">
                <div className="card p-5 space-y-4">
                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.name })} required htmlFor="name">
                            Name
                        </Label>
                        <Input
                            {...register('name')}
                            placeholder="Location"
                            defaultValue={category?.name}
                            className={classNames({ 'invalid': errors.name })} />
                    </div>

                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="description">
                            Description
                        </Label>
                        <TextArea
                            {...register('description')}
                            placeholder="Category Description"
                            defaultValue={category?.description}
                            rows={5}
                        />
                    </div>


                </div>

            </div>

        </form>
    )

}

export default CategoryForm;