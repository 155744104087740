import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios from 'axios';
import LoadingDots from '../components/LoadingDots';
import { useGlobal } from './globals';
import { PropsPeople } from '../Pages/People/types';


interface personContext {
  person: PropsPeople;
  setPerson: React.Dispatch<React.SetStateAction<PropsPeople>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonContext = createContext<personContext>({} as personContext);


export const PersonProvider = ({ id, children }: { id: string, children: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [person, setPerson] = useState<PropsPeople>({} as PropsPeople);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { setGlobal } = useGlobal();

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}get-user/${id}`)
        .then(({ data }) => {
          setGlobal(prev => ({ ...prev, person: data }));
          setPerson({ ...data, id });
        })
        .catch((e) => {
          setPerson({} as PropsPeople);
        })
        .finally(() => {
          setLoading(false)
        });
    } else {
      setLoading(false);
      setPerson({} as PropsPeople);
    }
  }, [id, refresh]);

  return (
    <PersonContext.Provider value={{ person, setPerson, setRefresh }}>
      {loading ? <LoadingDots /> : children}
    </PersonContext.Provider>
  );
}

export const usePerson = () => useContext(PersonContext);