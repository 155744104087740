import React, { useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import { handleAddCategory, handleBulkDeleteCategories, handleDeleteCategory } from "./common";
import { useCompany } from "../../context/companyProvider";


const CategoriesList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();

    const fetchURL = `${urls.remoteURL}get-categories`;

    const { isLoading, isError, data, refetch } = useQuery(`company-categories`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company categories?' };
    }


    type TableProps = {
        id: string,
        name: string,
        list_order: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => <>
                {info.getValue()}
                {/* <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span> */}
            </>,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),
        ...company.edit_categories ? [
            columnHelper.accessor('id', {
                header: 'Actions',
                footer: 'Actions',
                cell: info => actionCell(info),
                enableSorting: false,
                enableGlobalFilter: false,
                meta: {
                    className: 'w-28 text-center'
                }
            }),
        ] : []
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                {!!company.edit_categories &&
                    <>
                        <Tooltip content="Edit Category">
                            <Link to={`${info.getValue()}/edit`} className="btn btn-green"><span className="sr-only">Edit</span><FontAwesomeIcon icon={faEdit} fixedWidth /></Link>
                        </Tooltip>

                        <Tooltip content="Delete Category">
                            <Button type="button" color="red"
                                onClick={() => handleDeleteCategory(info.getValue(), refetch)}
                            ><span className="sr-only">Delete Category</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                        </Tooltip>
                    </>
                }
            </div>
        )
    }

    const [search, setSearch] = useState<string>();


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <div className="flex gap-x-2">
                        {/* Add New */}
                        {!!company.edit_categories &&
                            <Button type="button" onClick={() => { handleAddCategory(undefined, refetch) }} className="btn btn-green uppercase rounded-full px-6">
                                Add New
                            </Button>
                        }
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                search, selectable: !!company.edit_categories, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteCategories(selectedRows as string[], () => { setSelectedRows([]); refetch(); })
                                    }} >DELETE</Button>,

                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default CategoriesList;