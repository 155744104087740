import { FC, InputHTMLAttributes, TextareaHTMLAttributes, forwardRef } from "react";
import classNames from "classnames";

interface PropsTextArea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  invalid?: boolean,
  className?: string,
}


const TextArea: FC<PropsTextArea> = forwardRef<HTMLTextAreaElement, PropsTextArea>(({
  id,
  name,
  className = '',
  placeholder,
  value,
  ...props
}, ref) => {

  return (
    <textarea
      id={id}
      ref={ref}
      name={name}
      placeholder={placeholder}
      value={value}
      className={classNames(
        "form-control",
        className
      )}
      {...props}
    />
  );
}
);


export default TextArea;
