import React, { useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChildren, faPencil, faSearch, faTrashCan, faUser, faUserTieHair, faUsers } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import { usePerson } from "../../context/PeopleProvider";
import { handleBulkLocationRemoveUser, handleLocationRemoveUser, handlePersonAddLocation } from "../Locations/common";
import { useCompany } from "../../context/companyProvider";


const PeopleLocationsList = ({ MenuElement }: { MenuElement?: JSX.Element } = {}) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { person } = usePerson();
    const { company } = useCompany();

    const fetchURL = `${urls.remoteURL}user/` + person?.id + `/sites`;

    const { isLoading, isError, data, refetch } = useQuery(`company-people-locations-${person?.id}`, () => axios.get(fetchURL));


    if (isError) {
        throw { code: 500, message: 'Error getting locations?' };
    }

    type TableProps = {
        id: string,
        name: string,
        list_order: number,
        children: number,
        admins: number,
        admin: number,
        members: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('children', {
            cell: info => info.getValue(),
            header: 'Children',
            footer: 'Children',
            meta: {
                className: 'hidden md:table-cell'
            },
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('admins', {
            cell: info => info.getValue(),
            header: 'Administrators',
            footer: 'Administrators',
            meta: {
                className: 'hidden md:table-cell'
            },
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('members', {
            cell: info => info.getValue(),
            header: 'People',
            footer: 'People',
            meta: {
                className: 'hidden md:table-cell'
            },
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('admin', {
            cell: info => <Tooltip content={info.getValue() ? 'Admin User' : 'Staff'}>
                <FontAwesomeIcon icon={info.getValue() ? faUserTieHair : faUser} />
            </Tooltip>,
            header: 'Type',
            footer: 'Type',
            meta: {
                className: 'hidden md:table-cell'
            },
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];



    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                {!!company.edit_sites &&
                    <Tooltip content={'Edit Location'}>
                        <Link
                            className="btn btn-success"
                            to={`/locations/${info.getValue()}/edit`}
                        ><span className="sr-only">Edit Location</span><FontAwesomeIcon icon={faPencil} fixedWidth /></Link>
                    </Tooltip>
                }

                <Tooltip content={'View Sub Location'}>
                    <Link
                        className="btn btn-orange"
                        to={`/locations/${info.getValue()}/children`}
                    ><span className="sr-only">View Sub Location</span><FontAwesomeIcon icon={faChildren} fixedWidth /></Link>
                </Tooltip>

                <Tooltip content={'View Managers'}>
                    <Link
                        className="btn btn-pink"
                        to={`/locations/${info.getValue()}/managers`}
                    ><span className="sr-only">View Managers</span><FontAwesomeIcon icon={faUserTieHair} fixedWidth /></Link>
                </Tooltip>

                <Tooltip content={'View People'}>
                    <Link
                        className="btn btn-violet"
                        to={`/locations/${info.getValue()}/people`}
                    ><span className="sr-only">View People</span><FontAwesomeIcon icon={faUsers} fixedWidth /></Link>
                </Tooltip>

                {!!company.edit_people &&
                    <Tooltip content="Delete Location">
                        <Button
                            type="button"
                            color="red"
                            onClick={() => { handleLocationRemoveUser(person?.id as string, info.getValue(), refetch) }}
                        ><span className="sr-only">Delete Person</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                    </Tooltip>
                }
            </div>
        )
    }

    const [search, setSearch] = useState<string>();


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4 cursor-pointer"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>


                    <div className="flex gap-x-2">
                        {/* Add New */}
                        {!!company.edit_people &&
                            <Button color="green" className=" uppercase rounded-full px-6"
                                onClick={() => { handlePersonAddLocation(company, person, refetch) }}
                            >
                                Add New
                            </Button>
                        }
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                search, selectable: !!company.edit_people, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => <Button color="red"
                                    onClick={() => {
                                        handleBulkLocationRemoveUser(person?.id as string, selectedRows as string[], () => { setSelectedRows([]); refetch(); })
                                    }} >Remove</Button>,
                                hiddenColumns: ['fullname']
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default PeopleLocationsList;