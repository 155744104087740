import React from 'react';
import Error from '../Errors';
import {
    Routes,
    Route,
    useParams,
    Outlet,
} from 'react-router-dom';
import { PersonProvider, usePerson } from '../../context/PeopleProvider';
import PersonForm from './Form';
// import PeopleSitesPage from './SitesList';
import RequireAuthPage from '../../common/RequireAuthPage';
import CompanyBreadcrumbs from '../Company/Breadcrumbs';
import PersonView from './View';
import PeopleLocationsList from './LocationsLists';

function PeoplePage() {
    let { personID } = useParams();

    return (
        <>
            <PersonProvider id={personID as string}>
                <Routes>
                    <Route errorElement={<Error fullscreen={false} />} element={<CheckPerson />}>
                        <Route index element={<PersonView MenuElement={<CompanyBreadcrumbs />} />} />
                        <Route path={'edit'} element={<RequireAuthPage permissions='edit_people' redirect='..'><PersonForm MenuElement={<CompanyBreadcrumbs />} /></RequireAuthPage>} />
                        <Route path={'locations'} element={<RequireAuthPage permissions='view_sites' redirect='..'><PeopleLocationsList MenuElement={<CompanyBreadcrumbs />} /></RequireAuthPage>} />

                        <Route path={"*"} element={<Error status={404} title={'Page not found'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />

                    </Route>
                </Routes>
            </PersonProvider>
        </>
    );
}

export default PeoplePage;

const CheckPerson = ({ children }: { children?: JSX.Element }) => {
    const { person } = usePerson();

    if (!person.id) {
        return <Error status={404} title='Not found?' message='Person not found, please try again.' hideButton={false} parent='/people' />
    }

    return children || <Outlet />;
}